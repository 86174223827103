import { makeAutoObservable, action } from 'mobx'
import { createContext, useContext } from 'react'
import { UserStore } from './userStore'
import { NavStore } from './navStore'
import { LeaderboardStore } from './leaderboardStore'
import { PlaylistStore } from './playlistStore'
import { CalculatorStore } from './calculatorStore'
import { AcademyStore } from './academyStore'
import { AcademyAdminStore } from './academyAdminStore'
import { BenchmarkStore } from './benchmarkStore'
import { I18nStore } from './i18nStore'


const getLocalState = key => {
  let sidebarState = localStorage.getItem('sidebar')
  if (sidebarState === false || sidebarState === true) {
    return sidebarState
  }
  return false
}

class RootStore {
  ModalContent = null
  modalData = null

  hoverPopupTarget = false
  hoverPopup = false

  popup = null
  item = null
  element = null
  popupInteract = false
  PopupContent = null
  popupData = null
  anchor = null

  sidebarClosed = getLocalState('sidebar')

  constructor() {
    makeAutoObservable(this)
    this.user = new UserStore(this)
    this.nav = new NavStore(this)
    this.leaderboard = new LeaderboardStore(this)
    this.playlist = new PlaylistStore(this)
    this.calculator = new CalculatorStore(this)
    this.academy = new AcademyStore(this)
    this.academyAdmin = new AcademyAdminStore(this)
    this.i18n = new I18nStore(this)
    this.benchmark = new BenchmarkStore(this)
  }

  setHoverPopupTarget(hovering, options) {
    this.hoverPopupTarget = hovering
    if (hovering) {
      this.showPopup = true
      if (options) {
        const { popup, item, element } = options
        this.popup = popup
        this.item = item
        this.element = element
        this.popupInteract = options.popupInteract ? options.popupInteract : false
        this.openPopup(popup, item, element)
      }
    } else {
      if (!this.popupInteract) {
        this.closePopup()
      } else if (!this.hoverPopup) {
        this.closePopup()
      }
    }
  }

  setHoverPopup(hovering) {
    if (this.popupInteract) {
      this.hoverPopup = hovering
      if (hovering) {
        this.openPopup(this.popup, this.item, this.element)
      } else {
        if (!this.hoverPopupTarget) {
          this.closePopup()
        }
      }
    }
  }

  openPopup(content, data, anchor, delay = 300, options) {
    if (options?.popupInteract) this.popupInteract = true
    if (this.popupTimer) clearTimeout(this.popupTimer)
    this.popupTimer = setTimeout(action(() => {
      this.PopupContent = content
      this.popupData = data
      this.anchor = anchor
      window.addEventListener('resize', this.closePopup)
      window.addEventListener('focus', this.closePopup)
      window.addEventListener('blur', this.closePopup)
      document.addEventListener('click', this.handlePopupClick, true)
    }), delay)
  }

  handlePopupClick = e => {
    if (this.element && !this.element.contains(e.target)) {
      this.closePopup()
		}
  }

  closePopup(delay = 200) {
    if (this.popupTimer) clearTimeout(this.popupTimer)
    this.popupTimer = setTimeout(action(() => {
      this.PopupContent = null
      this.popupData = null
      this.element = null
      this.popup = null
      this.item = null
      window.removeEventListener('resize', this.closePopup)
      window.removeEventListener('focus', this.closePopup)
      window.removeEventListener('blur', this.closePopup)
      document.removeEventListener('click', this.handlePopupClick, true)
    }), delay)
  }

  openModal(content, data, anchor) {
    this.ModalContent = content
    this.modalData = data
    this.anchor = anchor
  }

  closeModal = () => {
    this.ModalContent = null
    this.modalData = null
    this.anchor = null
  }

  toggleSidebar = () => {
    this.sidebarClosed = !this.sidebarClosed
    localStorage.setItem('sidebar', this.sidebarClosed)
  }

  closeSidebar = () => {
    this.sidebarClosed = true
    localStorage.setItem('sidebar', true)
  }
}

const rootInstance = new RootStore()

const RootContext = createContext()

export const RootProvider = ({ children }) => {
  return (
    <RootContext.Provider value={rootInstance}>
      {children}
    </RootContext.Provider>
  )
}

export const useRoot = () => {
  return useContext(RootContext)
}

export { RootStore }