
import styled from 'styled-components/macro'

const TwitterIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const TwitterIcon = ({ size = 20, style, ...props }) => {
  return (
    <TwitterIconContainer style={style} {...props}>
      <svg fill="currentColor" width={`${size}px`} height={`${size}px`} viewBox="0 0 31 31">
        <path d="M27.3462 0.1875H3.28369C1.47217 0.1875 0.00244141 1.65723 0.00244141 3.46875V27.5312C0.00244141 29.3428 1.47217 30.8125 3.28369 30.8125H27.3462C29.1577 30.8125 30.6274 29.3428 30.6274 27.5312V3.46875C30.6274 1.65723 29.1577 0.1875 27.3462 0.1875ZM24.0034 11.043C24.0171 11.2344 24.0171 11.4326 24.0171 11.624C24.0171 17.5508 19.5054 24.3799 11.2612 24.3799C8.71826 24.3799 6.35986 23.6416 4.37744 22.3701C4.73975 22.4111 5.08838 22.4248 5.45752 22.4248C7.55615 22.4248 9.48389 21.7139 11.022 20.5107C9.05322 20.4697 7.39893 19.1777 6.83154 17.4004C7.52197 17.5029 8.14404 17.5029 8.85498 17.3184C6.8042 16.9014 5.26611 15.0967 5.26611 12.916V12.8613C5.86084 13.1963 6.55811 13.4014 7.28955 13.4287C6.67461 13.0197 6.1705 12.4648 5.8222 11.8135C5.4739 11.1622 5.29225 10.4348 5.29346 9.69629C5.29346 8.86231 5.51221 8.09668 5.90186 7.43359C8.10986 10.1543 11.4253 11.9316 15.144 12.123C14.5083 9.08106 16.7847 6.61328 19.519 6.61328C20.811 6.61328 21.9731 7.15332 22.7935 8.02832C23.8052 7.83691 24.7759 7.46094 25.6372 6.94824C25.3022 7.9873 24.5981 8.8623 23.6685 9.41602C24.5708 9.32031 25.4458 9.06738 26.2524 8.71875C25.644 9.61426 24.8784 10.4072 24.0034 11.043Z" />
      </svg>
    </TwitterIconContainer>
  )
}
