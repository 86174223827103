import { makeAutoObservable } from 'mobx';
import { setAuthToken } from '../utils'

export class PlaylistStore {
  playlistsLoading = false

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  setPlaylistsLoading(loading) {
    this.playlistsLoading = loading
  }
}