import React, { useRef, useState } from 'react'

const LOADING = 0
const LOADED = 1

const loadThrottleDelay = 500 // milliseconds to wait between requests

export const usePaginatedRequest = (fetchFunc, pageSize = 10, isPagedRequest = true, debug = false) => {
  // pages that have been loaded
  const pageLookup = useRef([])
  const activeRequest = useRef()
  const lastLoadRef = useRef()
  const loadRetryRef = useRef()
  const queryParams = useRef({})
  const mostRecentRequestedPageRef = useRef()
  const itemsRef = useRef({
    list: [],
    total: 0
  })

	const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0)
  const queryIdRef = useRef(0)

  const items = itemsRef.current

  const getItems = () => itemsRef.current

  const isItemLoaded = index => {
    if (items.list[index]) {
      return true
    }

    return false
  }

  const onItemsRendered = (pageStart, pageStop) => {
    if (pageStart !== pageStop) {
      const pagesToRender = []
      for (let i = pageStart; i <= pageStop; i++) {
        pagesToRender.push(i)
      }
      mostRecentRequestedPageRef.current = pagesToRender
    } else {
      mostRecentRequestedPageRef.current = [pageStart]
    }
  }

	const fetchMore = (startIndex, stopIndex) => {
    if (startIndex === undefined) {
      startIndex = 0
      stopIndex = pageSize - 1
    }

    let nextPage

    if (!mostRecentRequestedPageRef.current) {
      nextPage = Math.floor(startIndex / pageSize)
      // console.log('no requested pages, setting to %s', nextPage)
    } else {
      // console.log('requested pages %O', mostRecentRequestedPageRef.current)
      for (let i = 0; i < mostRecentRequestedPageRef.current.length; i++) {
        const recentPage = mostRecentRequestedPageRef.current[i]
        if (!pageLookup.current.includes(recentPage)) {
          nextPage = recentPage
        }
      }
    }

    // don't load until we're done loading previous page
    if (activeRequest.current) {
      // console.log(`request in progress`)
      return
    }

    if (nextPage === undefined) {
      return
    }


    if (lastLoadRef.current) {
      if (performance.now() - lastLoadRef.current < loadThrottleDelay) {
        // less than threshold, ignore
        if (!loadRetryRef.current) {
          loadRetryRef.current = setTimeout(() => {
            loadRetryRef.current = null
            if (activeRequest.current) return
            fetchMore()
          }, 100) // retry every 100ms
        }
        return
      } else {
        // rest last load and continue
        lastLoadRef.current = performance.now()
      }
    } else {
      lastLoadRef.current = performance.now()
    }

    console.log(`fetch`)

    activeRequest.current = true
    return new Promise((resolve, reject) => {
      const queryId = queryIdRef.current
      // if (hasMore) {
        let fetchParams
        if (isPagedRequest) {
          fetchParams = [nextPage, pageSize]
        } else {
          fetchParams = []
        }
        if (queryParams.current && queryParams.current.length > 0) {
          fetchParams = fetchParams.concat(queryParams.current)
        }
        fetchFunc.apply(null, fetchParams)
        .then(response => {
          activeRequest.current = false

          if (queryId !== queryIdRef.current) {
            // cancel this response handling because we changed request parameters
            return
          }

          if (debug) console.log('paginatedResponse %O', response)

          // add page to loaded list
          pageLookup.current.push(nextPage)

          // remove page from recent pages to load
          const recentPages = mostRecentRequestedPageRef.current
          if (recentPages) {
            for (let i = 0; i < recentPages.length; i++) {
              if (recentPages[i] === nextPage) {
                recentPages.splice(i, 1)
                break;
              }
            }
          }

          const endLoading = () => {
            resolve(response)
            setHasMore(false)
          }

          // console.log(`response %O`, response)
          const { data } = response

          if (Array.isArray(response)) {
            // response that directly returns an array
            // will not request further pages
            items.list = response
            items.total = response.length
            endLoading()
          } else if (Array.isArray(response.data)) {
            // responses without a nested data & total property
            // will not request further pages
            items.list = data
            items.total = data.length
            endLoading()
          } else {
            if (typeof response.data === 'string') {
              if (debug) console.log(`IRREGULAR API RESPONSE %O, ${response.data}`, response)
              endLoading()
              return
            }

            const newList = items.list
            const writeStart = Math.floor(nextPage * pageSize)
            const newData = data.data.slice(0, pageSize)

            if (debug) console.log(`newData %O, writing from %s`, newData, writeStart)

            for (let i = 0; i < newData.length; i++) {
              newList[writeStart + i] = newData[i]
            }
            items.list = newList
            items.total = data.total
            if (debug) console.log(`items set %O`, items)

            resolve(response)
            if (items.list.length === items.total) {
              setPage(prev => prev + 1)
              setHasMore(false)
            } else {
              setPage(prev => prev + 1)
              setHasMore(true)
            }

            if (mostRecentRequestedPageRef.current) {
              for (let i = 0; i < mostRecentRequestedPageRef.current.length; i++) {
                const tempPage = mostRecentRequestedPageRef.current[i]
                if (!pageLookup.current.includes(tempPage)) {
                  fetchMore(tempPage * pageSize, pageSize)
                  return
                }
              }
            }

            if (newList.length >= data.total) {
              // console.log(`loaded page has no more`)
              setHasMore(false)
            }
          }
        }).catch(e => {
          console.log('paginatedRequest error %O', e)
          reject(e)
          // resolve()
          setHasMore(false)
          activeRequest.current = false
        })
    })
	};

  const newQuery = (...rest) => {
    queryParams.current = rest
    pageLookup.current = []
    activeRequest.current = false
    items.list = []
    items.total = 0
    queryIdRef.current += 1
    mostRecentRequestedPageRef.current = undefined
    setHasMore(true)
    setPage(0)
    return fetchMore()
  }

  return {
    isItemLoaded: isItemLoaded,
    fetchMore: fetchMore,
    newQuery: newQuery,
    loading: (activeRequest.current === undefined || (activeRequest.current && items.total === 0)) ? true : false,
    data: itemsRef.current,
    items: itemsRef.current.list, // deprecated, pull off data prop
    total: itemsRef.current.total, // deprecated, pull off data prop
    pageSize: pageSize,
    queryId: queryIdRef.current,
    itemsRenderedCallback: onItemsRendered
  }

}
