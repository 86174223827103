import axios from 'axios';
import request from '../../utils/request'

import { WEBAPP_BACKEND_URL, AUTH_URL, isMocked, logoutOnExpiredToken } from '../../constants';

const mockUserProfile = {
  tag: 'success',
  data: {
    username: 'Scott',
    image: '',
    profileViews: 12,
    targetsKilled: 50,
    fov: 100,
    cm360: 100,
    rawInput: true,
    dpi: 1600,
    edpi: 1700,
    followersCount: 2,
    followingCount: 15,
    mouse: 'my mouse',
    mousepad: 'my mousepad',
    monitor: 'my monitor',
    keyboard: 'keyboard',
    headset: 'headset',
    favouriteGames: [
      {
        id: 1,
        name: 'Rust',
        image: ''
      }
    ],
    // was not being pulled from backend
    joinedAt: '2022-06-06T19:10:12+00:00',
  }
}

export class User {
	username = null;
	email = null;
	token = null;
	refresh_token = null;
	exp = null;
	emailVerified = false;
	role = null;
	steam_id = null;
	steam_personal_name = null;
	steam_profileurl = null;
	steam_avatar = null;

	static async getVipUserScores(page, max) {
		try {
      const params = { page, max }
      return await axios.get(`${WEBAPP_BACKEND_URL}/leaderboard/scores/vip/recent`, { params });
		} catch (error) {
			return error;
		}
	}

	static async getMyFeeds() {
		try {
			return await axios.get(`${WEBAPP_BACKEND_URL}/user/feed`);
		} catch (error) {
      if (error)
			return error;
		}
	}

	static async getProfileInfo() {
		return await axios.get(`${WEBAPP_BACKEND_URL}/user/profile`);
	}

	static async updateUsername(username) {
		try {
			return await axios.put(`${WEBAPP_BACKEND_URL}/user/username`, { username });
		} catch (error) {
			return error;
		}
	}

	static async updateUserInfo(data) {
    try {
      const formData = new FormData();

      if (data.image) formData.append('image', data.image);
			formData.append('storagePath', 'profile');
      formData.append('country', data.country)
			formData.append('twitter', data.twitter);
			formData.append('twitch', data.twitch);
      formData.append('tiktok', data.tiktok);
			formData.append('youtube', data.youtube);
			formData.append('mouse', data.mouse);
			formData.append('mousePad', data.mousePad);
			formData.append('keyboard', data.keyboard);
			formData.append('monitor', data.monitor);
			formData.append('headset', data.headset);
			formData.append('fov', data.fov);
			formData.append('cm360', data.cm360);
			formData.append('dpi', data.dpi);
			formData.append('sensitivity', data.sensitivity);
      formData.append('rawInput', data.rawInput);
      formData.append('hideDiscord', data.hideDiscord);

			let i = 0;
			for (const item of data.games) {
				if (item.gameId == 0) continue;
				formData.append('games[' + i + ']', item.gameId);
				i++;
			}

      if (data.scenarios && data.scenarios.length > 0) {
        i = 0;
        for (const item of data.scenarios) {
          if (item.leaderboardId == 0) continue;
          formData.append('scenarios[' + i + ']', item.leaderboardId);
          i++;
        }
      }

      formData.append('steam', data.steam);
			return await axios.post(`${WEBAPP_BACKEND_URL}/user/save`, formData);
		} catch (error) {
			console.log(error);
			return error;
		}
	}

	static async linkDiscord(code) {
		try {
			return await axios.post(`${WEBAPP_BACKEND_URL}/user/link-discord`, { code });
		} catch (error) {
			return error;
		}
	}

	static async getRecentActivity(username) {
		try {
			const params = { username }


			return await axios.get(`${WEBAPP_BACKEND_URL}/user/activity/recent`, { params });
		} catch (error) {
			return error;
		}
	}

	static async getTopTenScenarios(page, max, username) {
		try {
			const params = { username, page, max }
			return await axios.get(`${WEBAPP_BACKEND_URL}/user/scenario/top`, { params });
		} catch (error) {
			return error;
		}
	}

	static async getTotalPlayedScenarios(page, max, username) {
		try {
			const params = { username, page, max }
      params.sort_param = ['count']
			return await axios.get(`${WEBAPP_BACKEND_URL}/user/scenario/total-play`, { params });
		} catch (err) {
			return err;
		}
	}

	static async addVideo(index, id, platform) {
		try {
			const params = { index, id, platform }

			return await axios.post(`${WEBAPP_BACKEND_URL}/user/profile/video`, params);
		} catch (err) {
			return err;
		}
	}

	static async deleteVideo(index) {
		try {
			const params = { index }

			return await axios.delete(`${WEBAPP_BACKEND_URL}/user/profile/video`, { data: { index } });
		} catch (err) {
			return err;
		}
	}

	static async getBenchmarksProgress(page, max, username) {
		try {
			const params = { username, page, max }

			return await axios.get(`${WEBAPP_BACKEND_URL}/benchmarks/player-progress-rank`, { params });
		} catch (err) {
			return err;
		}
	}

	static async getMetaScenariosAllUsers(page, max, leaderboardId, username) {
		try {
			const params = { leaderboardId, page, max }
      if (username && username !== 'null') params.usernameSearch = username
			return await axios.get(`${WEBAPP_BACKEND_URL}/leaderboard/scores/global`, { params });
		} catch (err) {
			return err;
		}
	}

	static async getMetaScenariosProVip(page, max, leaderboardId, username) {
		try {
			const params = { leaderboardId, page, max }
      if (username && username !== 'null') params.usernameSearch = username
      return await axios.get(`${WEBAPP_BACKEND_URL}/leaderboard/scores/vip`, { params });
		} catch (err) {
			return err;
		}
	}

	static async getMetaScenariosFriends(page, max, leaderboardId, username) {
		try {
			const params = { leaderboardId, page, max }
      if (username && username !== 'null') params.usernameSearch = username
			return await axios.get(`${WEBAPP_BACKEND_URL}/leaderboard/scores/friends`, { params });
		} catch (err) {
			return err;
		}
	}

	static async getMetaScenariosMyPosition(page, max, leaderboardId, username) {
		try {
			const params = { leaderboardId, page, max }
      if (username && username !== 'null') params.usernameSearch = username
			return await axios.get(`${WEBAPP_BACKEND_URL}/leaderboard/scores/adjacent`, { params });
		} catch (err) {
			return err;
		}
	}

	static async getUserAllFavoritesScenarios(username) {
		try {
			const params = { username }
			return await axios.get(`${WEBAPP_BACKEND_URL}/user/favorite/scenarios`, { params });
		} catch (err) {
			return err;
		}
	}

  static async getFavoriteGames(username) {
    try {
      const params = { username }
      return await axios.get(`${WEBAPP_BACKEND_URL}/user/favorite/games`, { params });
    } catch (err) {
      return err;
    }
  }

	static async getProfileByUsername(username) {
		try {
			const params = { username }
			return await axios.get(`${WEBAPP_BACKEND_URL}/user/profile/by-username`, { params });
		} catch (err) {
			return err;
		}
	}

	static async getAllCompletedScenariosBySteamId(steamId) {
		try {
			return await axios.get(`${WEBAPP_BACKEND_URL}/user/completed-scenarios/list?steam_id=${steamId}`);
		} catch (err) {
			return err;
		}
	}

	static async followUser(playerId, follow) {
		try {
			if (follow) {
				return await axios.delete(`${WEBAPP_BACKEND_URL}/user/unfollow`, { data: { playerId } });
			} else {
        const data = { playerId };
				return await axios.post(`${WEBAPP_BACKEND_URL}/user/follow`, data);
			}
		} catch (err) {
			return err;
		}
	}

	static async setVideos(videos) {
		try {
			const data = { videos: videos.toString() };
			return await axios.post(`${WEBAPP_BACKEND_URL}/user/save`, data);
		} catch (err) {
			return err;
		}
	}

	static async checkFollowUser(playerId) {
		try {
			const params = { playerId };
			return await axios.get(`${WEBAPP_BACKEND_URL}/user/check-follow`, { params });
		} catch (err) {
			return err;
		}
	}

	static async getLastScoresForScenario(username, leaderboardId) {
		try {
			const params = { username, leaderboardId };
			return await axios.get(`${WEBAPP_BACKEND_URL}/user/scenario/last-scores/by-id`, { params });
		} catch (err) {
			return err;
		}
	}

	static async getLastScoresForScenarioByName(scenarioName, username) {
		try {
			const params = { username, scenarioName };
			return await axios.get(`${WEBAPP_BACKEND_URL}/user/scenario/last-scores/by-name`, { params });
		} catch (err) {
			return err;
		}
	}

	static async getTotalScenariosPlayed(username) {
		try {
			const params = { username };
			return await axios.get(`${WEBAPP_BACKEND_URL}/user/scenarios/all-played`, { params });
		} catch (err) {
			return err;
		}
	}

	static async getCreatorScenarios(page, max, username) {
		try {
			const params = { username, page, max };
      return axios.get(`${WEBAPP_BACKEND_URL}/scenario/creator`, { params })
      // return axios.get(`${WEBAPP_BACKEND_URL}/scenario/creator`, { params }).then(result => {
      //   let totalPlays = 0
      //   if (result.data) {
      //     result.data.forEach(item => {
      //       totalPlays += item.plays
      //     })
      //   }
      //   return {
      //     status: result.status,
      //     list: result.data,
      //     totalPlays: totalPlays
      //   }
      // }).catch(e => {

      //   return {
      //     status: 200,
      //     list: [],
      //     totalPlays: 0
      //   }
      // })

			// return await
		} catch (err) {
			return err;
		}
	}

  static async verifyToken() {
    return await axios.get(`${AUTH_URL}/webapp/verify-token`)
  }

  static async searchUsers(username) {
    // return await request.get(`user/search?username=${encodeURIComponent(username)}`)
    return await axios.get(`${WEBAPP_BACKEND_URL}/user/search?username=${encodeURIComponent(username)}`)
  }

  static async verifyEmail(token) {
    return await axios.get(`${AUTH_URL}/webapp/email-verified?emailVerifiedToken=${encodeURIComponent(token)}`)
  }

	static async getCreatorScenarioFavoritesCount(username) {
		try {
			const params = { username };
			return await axios.get(`${WEBAPP_BACKEND_URL}/user/favorite/scenarios`, { params });
		} catch (err) {
			return err;
		}
	}

	static async getCreatorPlaylists(page, max, username) {
		try {
      const params = { username, page, max };
      return axios.get(`${WEBAPP_BACKEND_URL}/user/playlist/creator`, { params })
			// return axios.get(`${WEBAPP_BACKEND_URL}/user/playlist/creator`, { params }).then(result => {
      //   let totalSubscribers = 0
      //   if (result.data) {
      //     result.data.forEach(item => {
      //       totalSubscribers += item.subscribers
      //     })
      //   }

      //   return {
      //     status: result.status,
      //     list: result.data,
      //     totalSubscribers: totalSubscribers
      //   }
      // })
		} catch (err) {
			return err;
		}
	}

	static async getCreatorPlaylistSubscribersCount(username) {
		try {
			const params = { username };
			return await axios.get(`${WEBAPP_BACKEND_URL}/user/playlist/creator/subscribed`, { params });
		} catch (err) {
			return err;
		}
	}
}
