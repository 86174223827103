import { makeAutoObservable } from "mobx";

export class I18nStore {
  locale = 'en';
  strings = require(`../i18n/${this.locale.toLowerCase()}.json`);
  localeOptions = [
    {
      name: 'English',
      value: 'en',
      flag: 'GB'
    },
    {
      name: '中文',
      value: 'cn',
      flag: 'CN'
    },
    {
      name: '日本語',
      value: 'jp',
      flag: 'JP'
    },
    {
      name: 'Español',
      value: 'es',
      flag: 'ES'
    },
    {
      name: 'PT-Brasileiro',
      value: 'pt-br',
      flag: 'BR'
    },
    {
      name: 'PT-Português',
      value: 'pt-pt',
      flag: 'PT'
    },
    {
      name: '한국어',
      value: 'kr',
      flag: 'KR'
    },
    {
      name: 'Русский',
      value: 'ru',
      flag: 'RU'
    }
  ].sort((a, b) => a.name.localeCompare(b.name));

  constructor(rootStore) {
    makeAutoObservable(this);
    // check localStorage
    let _locale = localStorage.getItem('locale');
    // if (_locale) {
    //   // get browser locale (last portion of the string)
    //   _locale = navigator?.language?.split('-')?.slice(-1)?.[0]?.toLowerCase();
    // }
    this.changeLocale(_locale);
    this.rootStore = rootStore;
  }

  changeLocale = (locale) => {
    if (!locale) return;
    // check the entire locale string first (e.g. pt-BR)
    if (this.localeOptions.map(i => i.value).includes(locale)) {
      localStorage.setItem('locale', locale);
      this.locale = locale;
      this.strings = require(`../i18n/${locale}.json`);
    }
    // check the first part of the locale string (e.g. pt)
    else if (this.localeOptions.map(i => i.value.split('-')?.[0])?.includes(locale)) {
      localStorage.setItem('locale', locale);
      this.locale = locale;
      this.strings = require(`../i18n/${locale}.json`);
    }
  }

  getString = (key) => {
    if (!key) {
      console.log('Missing localization key')
      return '!MISSING LOCALIZTION KEY!';
    }
    // keys can be deep, so split by .
    const keys = key.split('.');
    let value = this.strings;
    for (let i = 0; i < keys.length; i++) {
      value = value[keys[i]];
    }
    if (!value) {
      console.log('Missing localization STRING for key `' + key + '`')
      return '!MISSING LOCALIZTION STRING!';
    }
    return value;
  }
}

export const i18nStore = new I18nStore();