export const getAvatarImage = userProfile => {
  let image = ''
  let imageType = ''

  // disable webapp profile for now
  /*
  if (userProfile?.webapp?.profileImage) {
    image = userProfile.webapp.profileImage
    imageType = 'kovaaks'
  } else if (userProfile?.steamAccountAvatar) {
    image = userProfile.steamAccountAvatar
    imageType = 'steam'
  }
  */
  if (userProfile?.steamAccountAvatar) {
    image = userProfile.steamAccountAvatar
    imageType = 'steam'
  }

  return {
    avatarImage: image,
    avatarType: imageType
  }
}
