import React, { Fragment } from 'react'

export const DropdownList = props => {
  const { selectedItem, displayList, ItemComponent, highlightedIndex, getItemProps, maxHeight } = props
  return (
    <ul style={{ overflow: 'auto', maxHeight, maxWidth: 600 }}>
      <div key="spacer-1" className="list-spacer"></div>
      {displayList.map((item, index) => {
        if (item.hidden || (selectedItem && item.value === selectedItem.value)) return null
        const itemClasses = `${props.align ? `align-${props.align}` : ''} ${(item.value !== null) ? 'results' : 'no-results'} ${highlightedIndex === index ? 'selected' : ''}`
        if (ItemComponent) return (
          <ItemComponent
            item={item}
            key={item.name}
            className={itemClasses}
            {...getItemProps({ key: item.value, index, item })}
          />
        )
        return (
          <Fragment key={item.value}>
            {(!item.hidden && (!selectedItem || item.value !== selectedItem.value)) ?
            <li
              className={itemClasses}
              {...getItemProps({ key: item.value, index, item })}
            >
              {item.name}
            </li> : null}
          </Fragment>
        )
      })}
      <div key="spacer-2" className="list-spacer"></div>
    </ul>
  )
}
