import React, { useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { CloseIcon } from '../icons/CloseIcon'
import Login from '../login';
import Signup from '../signup';
import './styles.scss';
import { UserProvider } from '../../core/provider/user';
import { useUser } from '../../core'
import { useSearchParams, useNavigate } from 'react-router-dom'

export function LoginModal({ onClose = () => {} }) {
  const { showLoginModal, setShowModal } = useUser()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  function handleClose() {
    const keys = searchParams.keys()
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i]
      searchParams.delete(key)
    }

    if (window.location.pathname.indexOf('steam-connected')) {
      navigate('/kovaaks/main')
    }

    setShowModal(false)
    onClose();
  }

  function handleLoggedIn(isSuccess) {
    onClose();
    if (isSuccess) {
      NotificationManager.success('Logged In', '', 500, () => {});
    } else {
      NotificationManager.error('Login Failed', '', 500, () => {});
    }
    setTimeout(() => {

      location.reload();
    }, 800);
  }

  function handleSignedup(isSuccess) {
    onClose();
    if (isSuccess) {
      NotificationManager.success('Signup Success', '', 500, () => {});
    } else {
      NotificationManager.error('Signup Failed', '', 500, () => {});
    }
    navigate('/kovaaks/main')
  }

  return (
    <div className="login-modal">
      <div className="login-modal-content">
        <span className="login-modal-content-close" onClick={handleClose}>
          <CloseIcon size={14} />
        </span>
          {showLoginModal && showLoginModal.login && (
            <Login
              onLoggedIn={(isSuccess) => handleLoggedIn(isSuccess)}
              onClose={onClose}
            />
          )}
          {showLoginModal && showLoginModal.register && (
            <Signup onSignedUp={(isSuccess) => handleSignedup(isSuccess)} onClose={onClose}/>
          )}
      </div>
    </div>
  );
  }
