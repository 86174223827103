import React, { useEffect, useState, useRef, forwardRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro'
import { setAuthToken } from '../../utils';
import { NotificationManager } from 'react-notifications';
import { WEBAPP_BACKEND_URL, KOVAAKS_PUBLIC_BUCKET_DOMAIN } from '../../constants';
import axios from 'axios';
import { Dropdown } from '../Dropdown';
import { AUTH_URL, AUTH2_DOMAIN } from '../../constants';
import { useNavigate, useSearchParams } from 'react-router-dom'
import SteamSignInImage from '../../assets/images/sits_landing.png'
import { useUser } from '../../core'
import { useDebounce, useValidation } from '../../utils/useDebounce'
import { Loader } from '../Loader'
import { CountryItem } from '../list-items/CountryItem'
import { countryOptions } from '../../utils/country'
import { monthOptions, dayOptions, yearOptions } from '../../utils/date-helper'
import './styles.scss';
import { WarningIcon } from '../icons/WarningIcon';

const openTermsOfService = () => {
  window.open('/kovaaks/terms-of-service', '_blank')
}

const openPrivacyPolicy = () => {
  window.open('/kovaaks/privacy-policy', '_blank')
}

const validateExistingSignup = (validationObj, info) => {
  const { messages, warnings } = validationObj
  if (!info.username || info.username && info.username.length < 2) {
    messages.username = true
  }
  if (!info.hasAcceptedTermOfUsePolicy) {
    messages.termsOfUse = true
  }
  const usernameHasAt = info.username.includes('@')
  const usernameHasPeriod = info.username.includes('\.')

  if (usernameHasAt && usernameHasPeriod) {
    warnings.usernameIsEmail = true
  }

  return validationObj
}

const validateSignup = (info) => {
  let messages = {}
  let warnings = {}
  const { connectSteamParams } = info

  validateExistingSignup({ messages, warnings }, info)

  if (info.hasMetaAccount) {
    // do only partial validation for existing Meta accounts
    return Promise.resolve({ messages, warnings })
  }

  // full validation
  if (connectSteamParams.username && connectSteamParams.playerId && connectSteamParams.steamId && connectSteamParams.email && connectSteamParams.email !== 'none') {
    messages.usernameExists = true
    return Promise.resolve({ messages, warnings })
  }

  if (info.email && info.confirmEmail && info.email !== info.confirmEmail) {
    messages.emailMismatch = true
  }

  // require password length 12 for new accounts only
  // if ((!connectSteamParams.email && connectSteamParams.email !== 'none') && info.password && info.password.length < 12) {
  //   messages.passwordLength = true
  // }

  if (info.email !== '' && !info?.email.includes('@')) {
    messages.notAnEmail = true
  }

  // console.log(`validating %O, %O, %O`, info.birthday, info.birthMonth, info.birthYear)
  if (info.submit) {
    if (info.birthday && info.birthMonth && info.birthYear) {
      const birthDate = new Date(`${info.birthMonth} ${info.birthday} ${info.birthYear}`)
      const todayDate = new Date()
      const timeDiff = todayDate.getTime() - birthDate.getTime()
      const years = timeDiff / (1000 * 3600 * 24 * 365.25)
      if (years < 13) messages.minimumAge = true
    } else {
      messages.birthday = true
    }
  }
  return Promise.resolve({ messages, warnings })
}

export default function Signup({
	onHandleLogging = () => {},
	onSignedUp = () => {},
  onClose
}) {
  const navigate = useNavigate()
  const { loadUserInfo, verifyEmail, setShowModal } = useUser()

  const requestRef = useRef(false)
  const [loading, setLoading] = useState()
  const [submit, setSubmit] = useState(false) // submit button has been pressed (shows validation errors if not shown already)

  const [searchParams] = useSearchParams()

  let tempEmail = searchParams.get('email')
  if (tempEmail === 'none') tempEmail = ''

  const connectSteamParams = {
    email: tempEmail || '',
    steamId: searchParams.get('steamId') || '',
    playerId: searchParams.get('playerId') || '',
    username: searchParams.get('username') || '',
    profileAuthToken: decodeURIComponent(searchParams.get('profileAuthToken')) || ''
  }

  const [needsVerifyEmail, setNeedsVerifyEmail] = useState(false)
  const [verifyEmailToken, setVerifyEmailToken] = useState()
  const [focusedField, setFocusedField] = useState('')

  // validate immediately on focus change
  const focusField = field => {
    setFocusedField(field)
    validateSignup(getSignupInfo()).then(validationObj => {
      if (validationObj?.messages) setValidation(validationObj.messages) // these are errors, rename?
      if (validationObj?.warnings) setWarnings(validationObj.warnings)
    })
  }

	const [email, setEmail] = useState(connectSteamParams.email);
	const [confirmEmail, setConfirmEmail] = useState(connectSteamParams.email);
	const [username, setUsername] = useState(connectSteamParams.username);
  const [usernamePristine, setUsernamePristine] = useState(true)

	const [password, setPassword] = useState('');
	const [birthdate, setBirthdate] = useState('');
  const [country, setCountry] = useState('')
	const [hasSubscribed, setSubscribed] = useState(false);
	const [hasAcceptedTermOfUsePolicy, setPolicy] = useState(false);

  const [validation, setValidation] = useState({})
  const [warnings, setWarnings] = useState({})
	const [birthday, setBirthday] = useState(null);
	const [birthMonth, setBirthMonth] = useState(null);
	const [birthYear, setBirthYear] = useState(null);

  const [backendMessage, setBackendMessage] = useState({ status: null, message: '' })

  const debouncedValidate = useValidation(validateSignup, 500)

  const getSignupInfo = () => ({
    password,
    username,
    birthday,
    birthMonth,
    birthYear,
    email,
    confirmEmail,
    hasAcceptedTermOfUsePolicy,
    connectSteamParams,
    profileAuthToken: connectSteamParams.profileAuthToken,
    usernamePristine,
    submit,
    isSteamConnected: connectSteamParams.steamId && connectSteamParams.profileAuthToken,
    hasMetaAccount: connectSteamParams.playerId && connectSteamParams.email
  })

  const gotoLogin = e => {
    e.preventDefault()
    e.stopPropagation()
    setShowModal({ login: true })
  }

  // TODO: replace debounce with that from search throttle function
  useEffect(() => {
    setBackendMessage({ status: null, message: '' })

    debouncedValidate.validate(getSignupInfo()).then(validationObj => {
      // will return null if it gets debounced
      console.log('validation %O', validationObj)
      if (validationObj?.messages) setValidation(validationObj.messages) // these are errors, rename?
      if (validationObj?.warnings) setWarnings(validationObj.warnings)
    })
  }, [submit, focusedField, password, username, birthday, birthMonth, birthYear, email, confirmEmail, hasAcceptedTermOfUsePolicy, usernamePristine])

  const canSubmit = Object.keys(validation).length === 0

	async function handleSignup(e) {
    setSubmit(true)
    e.preventDefault()
    e.stopPropagation()
    if (requestRef.current) return
    requestRef.current = true
    setLoading(true)

    const signupInfo = getSignupInfo()
    signupInfo.submit = true

    const validationInfo = await validateSignup(signupInfo)

    const errors = Object.keys(validationInfo.messages).length
    // console.log('signupInfo %O, validationInfo %O, errors %s', signupInfo, validationInfo, errors)
    if (errors > 0) {
      requestRef.current = false
      setLoading(false)
      return
    }

    if (needsVerifyEmail) {
      requestRef.current = true
      setLoading(true)
      try {
        const response = await verifyEmail(verifyEmailToken)
        const { data } = response
        const { jwt, exp, refresh_token } = data
        const token = `Bearer ${jwt}`;
        setAuthToken(token)
        localStorage.setItem('jwtToken', token);
        localStorage.setItem('Username', username	);
        loadUserInfo().then(() => {
          onSignedUp(true)
        })
        requestRef.current = false
        setLoading(false)
      } catch (e) {
        requestRef.current = false
        setLoading(false)
        const { response } = error
        // console.log(`error %O`, error)
        const { status } = response
        if (status === 412) {
          setBackendMessage({ status, message: 'Your email address still needs to be verified.' })
        } else {
          setBackendMessage({ status, message: 'Uknown error verifying email, please try again or contact support.' })
        }
      }
      return
    }

		if (signupInfo.isSteamConnected) {
			try {

				const response = await axios.post(
					`${AUTH_URL}/webapp/registration`,
					{
						confirmEmail,
						username,
            playerId: connectSteamParams.playerId,
            steamId: connectSteamParams.steamId,
            country,
						hasSubscribed,
            profileAuthToken: connectSteamParams.profileAuthToken,
						hasAcceptedTermOfUsePolicy
					},
					{
						auth: {
							username: email,
              password
						},
						headers: {
							...axios.defaults.headers,
							'Access-Control-Allow-Origin': '*'
						}
					}
				);

        const { status, data } = response

        if (status == 200) {
          if (data.emailVerifiedToken) {
            const { emailVerifiedToken } = data
            setNeedsVerifyEmail(true)
            setVerifyEmailToken(emailVerifiedToken)
          } else {
            const { jwt, exp, refresh_token } = data
            const token = `Bearer ${jwt}`;
            setAuthToken(token)
            localStorage.setItem('jwtToken', token);
            localStorage.setItem('Username', username	);
            loadUserInfo().then(() => {
              onSignedUp(true)
            })
          }
        }
        requestRef.current = false
        setLoading(false)
			} catch (error) {
        const { response } = error
        const { status } = response
        if (status === 408) {
          setBackendMessage({ status, message: 'Email already exists.' })
        } else if (status === 409 || status === 406) {
          setBackendMessage({ status, message: 'Username already exists.' })
        } else if (status === 403) {
          setBackendMessage({ status, message: 'Invalid request.' })
        } else if (status === 405) {
          setBackendMessage({ status, message: 'Invalid password.' })
        } else if (status === 429) {
          setBackendMessage({ status, message: 'You have made too many failed login attempts. Please try again later or reset your password.' })
        } else {
          setBackendMessage({ status, message: 'Uknown error, please try again or contact support.' })
        }
        requestRef.current = false
        setLoading(false)
			}
		} else {

		}
	}

  if (requestRef.current) return <Loader />

  const showRegister = connectSteamParams.steamId && !needsVerifyEmail

  return (
    <form className="signup" onSubmit={handleSignup}>
      <div className="signup-container">
        <div className="signup-title">Link Steam Account</div>
        {!connectSteamParams.steamId ? <div className="form-info neutral">Please sign into Steam before registering.</div> : null}
        <div className="signup-steam-login">
          {!connectSteamParams.steamId ? <a className="text-orange link" href={`${WEBAPP_BACKEND_URL}/steam/auth/user/`}>
            <img src={SteamSignInImage} />
          </a> : null}
        </div>
        {needsVerifyEmail ? (
          <div>
            <div className="signup-title signup-header">Success!</div>
            <div className="form-info success">Please check your email at {email} for a verification link.</div>
          </div>
        ) : null}
        <div className="signup-form">
          {showRegister ?
          <div className="form-field">
            <div className="form-label hd-label">Steam ID</div>
            <div className="form-input">
              <input
                className="hd-input disabled"
                disabled
                type="text"
                title="Steam ID"
                placeholder="Steam Id"
                value={connectSteamParams.steamId}
                required
              />
            </div>
          </div> : null }
          {showRegister ?(
            <>
            <div className="form-field">
              <div className="form-label hd-label">Email</div>
              <div className="form-input">
                <input
                  className={`hd-input ${connectSteamParams.email ? 'disabled' : ''}`}
                  onChange={(e) => setEmail(e.target.value)}
                  onFocus={() => focusField('email')}
                  type="text"
                  title="Email"
                  value={email}
                  autoComplete="email"
                  required
                />
              </div>
            </div>
            <div className="form-field">
              <div className="form-label hd-label">Confirm Email</div>
              <div className="form-input">
                <input
                  className={`hd-input ${connectSteamParams.email ? 'disabled' : ''}`}
                  onChange={(e) => setConfirmEmail(e.target.value)}
                  onFocus={() => focusField('confirm-email')}
                  type="text"
                  title="Confrm Email"
                  value={confirmEmail}
                  autoComplete="email"
                  title="Confirm email"
                  required
                />
              </div>
            </div>
            {((email !== '' && focusedField !== 'email') || submit) && validation?.notAnEmail ? <div className="form-info">
              Invalid email address.
            </div> : null}
            {((email !== '' && focusedField !== 'email') || submit) && validation?.emailMismatch ? <div className="form-info">
              Your emails do no match.
            </div> : null}
            <div className="form-field">
              <div className="form-label hd-label">Username</div>
              <div className="form-input">
                <input
                  className={`hd-input ${validation.usernameExists ? 'disabled' : ''}`}
                  onChange={(e) => setUsername(e.target.value)}
                  onFocus={() => focusField('username')}
                  onBlur={() => {
                    setUsernamePristine(false)
                  }}
                  value={username}
                  type="text"
                  autoComplete="username"
                  title="Username"
                  disabled={validation.usernameExists}
                  required
                />
              </div>
            </div>
            {((username !== '' && focusedField !== 'username') || submit) && !warnings?.usernameIsEmail && validation?.username ?
            <div className="form-info">
              You must provide a username that is more than 1 character.
            </div> : null}
            {warnings?.usernameIsEmail ?
            <div className="form-info neutral">
              <WarningIcon style={{ paddingRight: 8 }} />
              Your username looks like it might be an email address. Usernames are public.
            </div> : null}
            {validation?.usernameExists ? <div className="form-info">
              This Steam account is already linked to a username.
            </div> : null}
            {!validation?.usernameExists ?
            <div className="form-field">
              <div className="form-label hd-label">Password</div>
              <div className="form-input">
                <input
                  className="hd-input"
                  onChange={(e) => setPassword(e.target.value)}
                  onFocus={() => focusField('password')}
                  type="password"
                  title='Password'
                  autoComplete={connectSteamParams.email ? 'current-password' : 'new-password'}
                  required
                />
              </div>
            </div> : null}
            {(!validation?.usernameExists && connectSteamParams.email && connectSteamParams.email !== 'none') ? <div className="form-info neutral">Use your existing KovaaK's Trainer password.</div> : null}
            {(connectSteamParams.email && connectSteamParams.email !== 'none' && (backendMessage.status === 405 || !validation?.usernameExists)) ?
            <a className="form-link" href={AUTH2_DOMAIN} target="_blank">Forgot password?</a>
             : null}
            {/* {validation?.passwordLength ? <div className="signup-info">
              Your password must be at least 12 characters.
            </div> : null} */}
            {(!validation?.usernameExists) ? (<>
              <label className="signup-form-label-section-label">
                Birth Date
              </label>
              <div className="signup-form-label-section">
                <div className="signup-form-input-section-birthdate">
                  <div style={{ display: 'flex', flex: '1', paddingRight: '8px' }}>
                  <Dropdown
                    placeholder="Month"
                    options={monthOptions}
                    value={birthMonth}
                    onFocus={() => focusField('birthMonth')}
                    onBlur={() => focusField('')}
                    onChange={e => { setBirthMonth(e.value) } }
                  />
                  </div>
                  <div style={{ display: 'flex', flex: '1', paddingRight: '8px' }}>
                  <Dropdown
                    placeholder="Day"
                    options={dayOptions}
                    value={birthday}
                    onFocus={() => focusField('birthDay')}
                    onBlur={() => focusField('')}
                    onChange={e => { setBirthday(e.value) }}
                  />
                  </div>
                  <div style={{ display: 'flex', flex: '1' }}>
                  <Dropdown
                    placeholder="Year"
                    options={yearOptions}
                    value={birthYear}
                    onFocus={() => focusField('birthYear')}
                    onBlur={() => focusField('')}
                    onChange={e => { setBirthYear(e.value) }}
                  />
                  </div>
                </div>
              </div>
            </>) : null }
            {validation?.minimumAge ? <div className="form-info">
              You must be at least 13 years of age.
            </div> : null}
            {(
              submit ||
              (focusedField !== 'birthMonth' && focusedField !== 'birthDay' && focusedField !== 'birthYear')
            ) &&
            validation?.birthday ?
            <div className="form-info">
              You must enter a birth date.
            </div> : null}
            {!validation?.usernameExists ?
              <>
                <label className="signup-form-label-section-label">
                  Country
                </label>
                <Dropdown
                  placeholder="Country"
                  options={countryOptions}
                  variant="search"
                  value={country}
                  virtualize
                  ItemComponent={CountryItem}
                  onChange={e => {
                    setCountry(e.value)
                  }}
                />
              </>
              : null
            }
          </>
          ) : null }
        </div>
        {(showRegister && !validation?.usernameExists) ? (
          <div className="chkbox">
            <div className='signup-form-checkbox'>
              <div>I would like to receive email updates</div>
              <input
                type="checkbox"
                className="checkbox-round"
                checked={hasSubscribed}
                onChange={() => {
                  setSubscribed(!hasSubscribed)
                }}
              />
            </div>
            <div className='signup-form-checkbox'>
              <div style={{ display: 'flex' }}>I agree to the&nbsp;<div className="text-orange flow link" onMouseDown={() => openPrivacyPolicy()}>Privacy Policy</div>&nbsp;&&nbsp;<div className="text-orange link flow" onMouseDown={() => openTermsOfService()}>Terms of Service</div>.</div>
              <input
                type="checkbox"
                className="checkbox-round"
                checked={hasAcceptedTermOfUsePolicy}
                onChange={() => {
                  setPolicy(!hasAcceptedTermOfUsePolicy)
                }}
              ></input>
            </div>
            {submit && validation?.termsOfUse ? <div className="form-info">
              Please accept the Privacy Policy & Terms of Service.
            </div> : null}
            {backendMessage.status ? <div className="signup-info" style={{ justifyContent: 'center', width: '100%' }}>{backendMessage.message}</div> : null}
				  </div>
        ) : null}
				{(!validation?.usernameExists && showRegister || needsVerifyEmail) ? <div className="signup-btn-group mt-48">
					<button
            type="submit"
						className={`signup-btn-group-option hd-btn-dialog hd-btn-accept always-enabled ${!canSubmit ? 'disabled' : ''}`}
					>
						{needsVerifyEmail ? 'SIGN IN' : 'SIGN UP'}
					</button>
				</div> : null}
        {validation?.usernameExists ? <button onClick={gotoLogin} className="signup-btn-group-option hd-btn-dialog hd-btn-accept">LOG IN</button> : null}
			</div>
		</form>
	);
}
