import { WEBAPP_BACKEND_URL } from "../constants"

const headers = {
  'Content-Type': 'application/json',
}

export const setRequestAuthToken = token => {
  headers.Authorization = token
}

const objectToQueryParams = obj => {
  let params = []
  for (const key in obj) {
    params.push(`${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
  }
  return '?' + params.join('&')
}

const doRequest = async (method, url, body, headerOverrides) => {
  // return
  let response
  try {
    response = await fetch(`${WEBAPP_BACKEND_URL}/${url}`, {
      method: method,
      body: body ? JSON.stringify(body) : null,
      headers: { ...headers, ...headerOverrides }
    })
    let data = await response.json()
    return { data: data, status: 200 }
  } catch (e) {
    return e
  }
}


const requestMethods = {
  get: async (url, params, headerOverrides) => {
    return await doRequest('GET', `${url}${objectToQueryParams(params)}`, null, headerOverrides)
  },
  post: async (url, body, headerOverrides) => {
    return await doRequest('POST', url, body, headerOverrides)
  },
  put: async (url, body, headerOverrides) => {
    return await doRequest('PUT', url, body, headerOverrides)
  }
}

export default requestMethods