import React, { useState, useRef, useEffect, useCallback } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { useSelect } from 'downshift'
import styled from 'styled-components/macro'
import { DropdownListContainer, DropdownOverlay } from './dropdown/dropdownStyles'
import { useResponsive } from '../hooks/useResponsive'
import { useAnchor } from '../hooks/useAnchor'
import { useSearch } from '../hooks/useSearch'
import { CloseIcon } from './icons/CloseIcon'
import { SearchIcon } from './icons/SearchIcon'
import { DropdownButton } from './dropdown/DropdownButton'
import { DropdownContainer } from './dropdown/dropdownStyles'
import { Loader } from './Loader'

const SearchContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  position: relative;

  .search-label {
    padding: 0px 8px;
  }

  .hd-input {
    width: 100%;
    font-size: 16px;
    color: #9d99a5;
    background: #1b1b1e;

    &.placeholder {
      font-weight: 300;
      font-size: 15px;
    }
  }

  .toggle-container {
    position: absolute;
    right: 0px;
    cursor: pointer;
    height: 100%;
    display: flex;
    flex: 1;
    align-items: center;
    padding-right: 6px;

    .search-icon {
      color: #625f68;

      &:hover:not(.focused) {
        filter: brightness(1.2);
      }
    }
  }

  &.screen-xs {
    .search-icon {
      transform: scale(0.8);
    }
  }
`

const SearchDropdown = props => {
  const { value, items, onChange, anchorPos, breakpoint, ItemComponent } = props
  const {
    isOpen, selectedItem, getLabelProps,
    getToggleButtonProps, getMenuProps,
    highlightedIndex, getItemProps, selectedValues
  } = useSelect({
    items: items,
    isOpen: true,
    onSelectedItemChange: (item) => {
      onChange(item.selectedItem)
    }
  })

  const handleClickOverlay = e => {
    e.preventDefault();
    e.stopPropagation()
  }

  let dropdownElement = document.getElementById('dropdown-container')

  return (
    ReactDOM.createPortal((
      <>
        <div style={{ display: 'none' }} {...getToggleButtonProps()} />
        <DropdownOverlay style={{ opacity: 1, pointerEvents: 'auto' }} />
        <DropdownListContainer
          {...getMenuProps()}
          className={`screen-${breakpoint}`}
          style={{
            minWidth: (anchorPos.right - anchorPos.left),
            left: breakpoint !== 'xs' ? anchorPos.x : 8,
            right: breakpoint !== 'xs' ? null : 8,
            top: anchorPos.bottom
          }}
          onMouseDown={handleClickOverlay}
        >
          <div className="list-overlay"></div>
          <ul>
            <div key="spacer-1" className="list-spacer"></div>
            {items.map((item, index) => {
              if (ItemComponent && !item.hidden) return (
                <ItemComponent
                  item={item}
                  key={item.name}
                  className={`left-align ${item.value ? 'results' : 'no-results'} ${highlightedIndex === index ? 'selected' : ''}`} {...getItemProps({ key: item.value, index, item })}
                />
              )
              return (
                <>
                  {!item.hidden ?
                  <li
                    key={item.name}
                    data-key={item.value}
                    className={`left-align ${item.value ? 'results' : 'no-results'} ${highlightedIndex === index ? 'selected' : ''}`} {...getItemProps({ key: item.value, index, item })}
                  >
                    {item.name}
                  </li> : null}
                </>
              )
            })}
            <div key="spacer-2" className="list-spacer"></div>
          </ul>
        </DropdownListContainer>
      </>
    ), dropdownElement)
  )
}

const Search = (props) => {
  const { value, onChange, onCommit, placeholder, query, persistent, dropdown, style, ItemComponent, mapResults, disabled = false } = props
  const { selectedItem, onClear } = props
  const [internalValue, setInternalValue] = useState('')
  const [focused, setFocused] = useState(persistent ? true : false)
  const [loading, setLoading] = useState(false)
  const inputRef = useRef()
  const [anchor, anchorPos, element] = useAnchor()
  const [results, setResults] = useState([])

  const breakpoint = useResponsive()

  const searchHandler = useSearch(query, props.delay, setLoading)

  const clearQuery = e => {
    if (onChange) {
      onChange({ value: '' })
    } else {
      setInternalValue('')
    }
    searchHandler.search(null)
  }

  const handleResults = result => {
    let list

    if (result?.data?.data && result.data.data.length > 0) {
      if (mapResults) {
        list = result.data.data.map(mapResults)
      } else {
        list = result.data.data
      }

      setResults(list)
      // setResults(list.map(item => {
      //   return {
      //     value: item.username,
      //     name: item.username,
      //     image: item.steamAccountAvatar
      //   }
      // }))
    } else if (result?.data && result.data.length > 0) {
      list = result.data
      setResults(list.map(item => {
        return {
          value: item.username,
          name: item.username,
          image: item.steamAccountAvatar
        }
      }))
    } else {
      setResults([{
        name: 'No results',
        value: null
      }])
    }
  }

  const handleInput = e => {
    const newValue = e.target.value

    if (newValue.length < 3) {
      setResults([])
      if (props.close) {
        searchHandler.search(null).then(handleResults)
      } else {
        searchHandler.search(null)
      }
    } else {
      searchHandler.search(newValue).then(handleResults)
    }

    if (onChange) {
      onChange({ value: newValue })
    } else {
      setInternalValue(newValue)
    }
  }

  useEffect(() => {
    if (!persistent && element) element.focus()
  }, [])

  // autofocus after first render on mount
  useEffect(() => {
    if (props.open !== null && props.open !== undefined) {
      // in controlled mode we wait until open to autofocus
      if (props.open === true && props.autofocus && element) element.focus()
    } else {
      if (props.autofocus && element) element.focus()
    }

  }, [element, props.open])

  const handleFocus = e => {
    setFocused(true)
  }

  const handleBlur = e => {
    if (!persistent) setFocused(false)
    setResults([])
    setInternalValue('')
    if (props.onBlur) props.onBlur()
  }

  const handleKey = e => {
    if (e.keyCode === 13) {
      if (results[0]?.value) {
        onCommit({ value: results[0].value })
      }
      if (!persistent) setFocused(false)
      if (element) element.blur()
      setResults([])
    } else if (e.keyCode === 27) {
      if (!persistent) setFocused(false)
      if (element) element.blur()
      setInternalValue('')
      setResults([])
    }
  }

  const displayValue = onChange ? value : internalValue

  return (
    <SearchContainer className={breakpoint === 'xs' ? 'screen-xs' : ''} style={style}>
      {focused ? (
        <>
          {selectedItem && !disabled ?
          (<DropdownContainer>
            <div className="dropdown-container">
              <div className="button-container">
                <div className="selected-name">
                  <div className="hd-label-truncated">
                    {selectedItem.name}
                  </div>
                </div>
                <div className="dropdown-icon" style={{ marginRight: 8 }} onClick={onClear}>
                  <CloseIcon size={14} />
                </div>
              </div>
              {/* <DropdownButton selectedItem={selectedItem} /> */}
            </div>
            </DropdownContainer>
          ) :
          <input
            ref={anchor}
            onBlur={handleBlur}
            placeholder={placeholder}
            onKeyUp={handleKey}
            className={`hd-input no-margin ${displayValue === '' ? 'placeholder' : ''}`}
            value={displayValue}
            onChange={handleInput}
            disabled={disabled}
          />
          }
        </>
      ) : null}
      {(dropdown && focused && anchorPos && results.length > 0) ?
      <SearchDropdown
        breakpoint={breakpoint}
        anchorPos={anchorPos}
        items={results}
        value={displayValue}
        ItemComponent={ItemComponent}
        onChange={e => {
          console.log(`searchdropdown onchange %O`, e)
          if (!persistent) setFocused(false)
          if (element) element.blur()
          if (e) onCommit(e)
          setResults([])
          setInternalValue('')
        }}
      /> : null}
      <div className="toggle-container" style={{ cursor: disabled ? 'default' : 'pointer' }}>
        {(props.close && displayValue) ?
        <CloseIcon
          style={{
            width: '16px',
            height: '16px',
            color: '#625f68'
          }}
          onClick={() => {
            clearQuery()
            if (props.close) searchHandler.search(null).then(handleResults)
            if (element) element.focus()
          }}
        /> : null}
        {(!displayValue && !selectedItem) ?
        <div
          className={`search-icon ${focused ? 'focused' : ''}`}
          onMouseDown={handleFocus}
        >
          <SearchIcon size={18} />
        </div> : null}
      </div>
    </SearchContainer>
  )
}

Search.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  persistent: PropTypes.bool,
  ItemComponent: PropTypes.elementType,
  onChange: PropTypes.func,
  onCommit: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  query: PropTypes.func,
  dropdown: PropTypes.bool,
  close: PropTypes.bool, // rename to clear? controls if new query is performed when cleared (clear button is show as well) if not set it automatically clears on blur
  open: PropTypes.bool,   // externally control
  autofocus: PropTypes.bool,
  delay: PropTypes.number
}

Search.defaultProps = {
  dropdown: false,
  close: false,
  delay: 500,
  autofocus: false,
  persistent: false
}

export { Search }