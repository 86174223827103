import { makeAutoObservable } from 'mobx';
import { WEBAPP_BACKEND_URL } from '../constants';
import axios from 'axios';

export class BenchmarkStore {
  benchmarkListLoaded = false
  selectedBenchmarkLoaded = false
  benchmarkList = []
  selectedBenchmark = null

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  setBenchmarksListLoading(loading) {
    this.benchmarkListLoaded = loading
  }

  setSelectedBenchmarkLoading(loading) {
    this.selectedBenchmarkLoaded = loading
  }

  async getBenchmarksList(username) {
    this.setBenchmarksListLoading(true)
    try {
      // yes, this is in earnest. Why is the data passed back this way? I don't know.
      const result = await (await (await axios.get(`${WEBAPP_BACKEND_URL}/benchmarks/player-progress-rank?username=${username}&max=100`)).data).data
      this.setBenchmarksListLoading(false)
      this.benchmarkList = result.filter(benchmark => benchmark?.type?.toLowerCase() === 'benchmark')
        .map(benchmark => ({
          name: benchmark.benchmarkName,
          id: benchmark.benchmarkId,
          icon: benchmark.benchmarkIcon,
          author: benchmark.benchmarkAuthor
        }));
      return this.benchmarkList
    } catch (e) {
      console.error(e)
      this.setBenchmarksListLoading(false)
    }
  }

  async getBenchmark(id, steamId) {
    this.setSelectedBenchmarkLoading(true)
    try {
      const params = {
        benchmarkId: id,
        steamId,
        page: 0,
        max: 100
      }
      this.selectedBenchmark = await (await axios.get(`${WEBAPP_BACKEND_URL}/benchmarks/player-progress-rank-benchmark`, { params })).data
      this.setSelectedBenchmarkLoading(false)
      return this.selectedBenchmark
    } catch (e) {
      console.error(e)
      this.setSelectedBenchmarkLoading(false)
    }
  }
}

export const benchmarkStore = new BenchmarkStore();