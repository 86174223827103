import React, { useState, useEffect } from 'react';
import './styles.scss';

import { Header, Sidebar } from '../../components';
import { UserProvider } from '../../core';

export default function KovaakLeagueScene() {
	const [header, setHeader] = useState(2);

	function handleHeader(selected) {
		setHeader(selected);
	}

	return (
		<>
			<div className="kovaaks-league">
        <Header
          onHandleHeader={(selected) => handleHeader(selected)}
        />
				<div className="kovaaks-league-container">
					<Sidebar />
					<div className="kovaaks-league-container-content">
						Welcome to KovaakLeague Page
					</div>
				</div>
			</div>
		</>
	);
}
