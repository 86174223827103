import React from 'react';
import { NotificationManager } from 'react-notifications';
import { ImagePicker } from '../file-picker';
import { useNews } from '../../core';
import { KOVAAKS_PUBLIC_BUCKET_DOMAIN } from '../../constants';
import icon_close from '../../assets/images/icon_close.svg';

import './styles.scss';

export function AdminNews() {
	const {
		newApiError,
		allNews,
		createNews,
		resetNews,
		getAllNews,
		deleteNewsById
	} = useNews();

	const [image, setImage] = React.useState(null);
	const [link, setLink] = React.useState('');

	const handleSubmit = (e) => {
		e.preventDefault();

		if (image == null || link.trim() == '') {
			NotificationManager.error(
				'Please fill out the missing fields.',
				'Error',
				1000,
				() => {}
			);
			return;
		}

		createNews({
			title: 'Test title',
			description: 'Test description',
			link,
			image
		});
	};

	React.useEffect(() => {
		if (newApiError != null) {
			NotificationManager.success(newApiError, '');
			setImage(null);
			setLink('');
			resetNews();
			getAllNews();
		}
	}, [newApiError]);

	React.useEffect(() => {
		getAllNews();
	}, []);

	const handleDeleteItem = (index) => {
		deleteNewsById(index);
	};

	return (
		<>
			<div className="admin-news">
				<p>News</p>
				<div className="admin-news-grid-container">
					{allNews != null &&
						allNews.map((item, index) => (
							<div
								key={index}
								className="admin-news-grid-container-item"
							>
								<img
									type="submit"
									src={icon_close}
									className="admin-news-grid-container-item-close-btn"
									onClick={() => handleDeleteItem(item.news_id) }
								/>
								<img
									className="admin-news-grid-container-item-img"
                  src={item.image}
									// src={`${KOVAAKS_PUBLIC_BUCKET_DOMAIN}/${item.image}`}
									alt=""
								/>
							</div>
						))}
				</div>

				<br />
				<div className="admin-news-form">
					<label>url1: &nbsp;&nbsp;</label>
					<input
						type="text"
						className="admin-news-form-input"
						value={link}
						onChange={(e) => setLink(e.target.value, 0)}
					/>
					<div className="admin-news-form-image-picker">
						<label>Image1:&nbsp;&nbsp;</label>
						<ImagePicker
							className="form-item"
							placeholder="No file"
							value={image}
							onSelect={(value) => setImage(value)}
						/>
					</div>

					<button
						className="admin-news-form-submit"
						onClick={handleSubmit}
					>
						Create
					</button>
				</div>
			</div>
		</>
	);
}
