export function applyThousandSeparator(val, thousandSeparator = ",") {
  const str = val.toString();
  const thousandsGroupRegex = /(\d)(?=(\d{3})+(?!\d))/g;
  let index = str.search(/[1-9]/);
  index = index === -1 ? str.length : index;
  return (
    str.substring(0, index) +
    str
      .substring(index, str.length)
      .replace(thousandsGroupRegex, "$1" + thousandSeparator)
  );
}

export const getNumberValue = (val) => {
  if (val == null) return '';
  let newVal = val.toString().replace(/[^0-9^\.^-]/g, "");
  return newVal;
};

export function splitDecimal(val, allowNegative = true) {
  let numStr = val.toString();
  const hasDecimal = numStr.search(/\./) >= 0 ? true : false;
  const negationMatch = numStr.match(/-/g);
  const hasNegation = negationMatch ? negationMatch.length % 2 == 1 : false;
  const addNegation = hasNegation && allowNegative;
  numStr = numStr.replace(/-/g, "");

  const parts = numStr.split(".");
  const beforeDecimal = (parts[0] === null || parts[0] === undefined || parts[0] === '') ? 0 : parts[0]  ;
  const afterDecimal = parts[1] || "";

  return {
    beforeDecimal,
    afterDecimal,
    hasDecimal,
    hasNegation,
    addNegation
  };
}

const limitToScale = (num, places, fixed) => {
  const preformat = parseFloat(`0.${num ? num : '0'}`);
  const mult = Math.pow(10, places)
  const rounded = Math.round(parseFloat(preformat) * mult) / mult;
  if (preformat === 0) return [0, 0];

  const parts = rounded.toString().split(".");
  if (parts.length === 1 && parts[0].toString() === '0') {
    return [0, 0];
  } else if (parts[0].toString() === '1') {
    return [1, fixed ? 0 : '']
  } else if (parts[1] === '0') {
    return [0, fixed ? 0 : '']
  } else {
    return [parseInt(parts[0], 10), parts[1]]
  }
}

export const roundToPlaces = (num, places, fixed) => {
   if (places === 0) return Math.round(num);
   const minimumValue = 1 / Math.pow(10, places)
   if (num < minimumValue && num > 0) return 0;
   const {
     beforeDecimal,
     afterDecimal,
     hasDecimal,
     addNegation
   } = splitDecimal(num, true);

   const decimalPart = limitToScale(afterDecimal, places, fixed);

   let rounded
   if (decimalPart[1] !== 0 && decimalPart[1] !== '') {
     rounded = `${parseInt(beforeDecimal, 10) + decimalPart[0]}.${decimalPart[1]}`
   } else {
     rounded = `${parseInt(beforeDecimal, 10) + decimalPart[0]}`
   }

   return addNegation ? `-${rounded}` : `${rounded}`;
 }

export const formatValue = (val, decimalScale, fixed = false, applySeparator = true) => {
  let {
    beforeDecimal,
    hasDecimal,
    afterDecimal,
    addNegation
  } = splitDecimal(getNumberValue(val));

  if (decimalScale === 0) hasDecimal = false;

  const roundedDecimal = limitToScale(afterDecimal, (decimalScale !== null) ? decimalScale : 2, fixed)

  if (roundedDecimal[1] === '') hasDecimal = false;
  // this overrides everything else
  if (fixed) hasDecimal = true;

  let out
  if (applySeparator) {
    out = `${addNegation ? "-" : ""}${applyThousandSeparator(
      parseInt(beforeDecimal, 10) + parseInt(roundedDecimal[0], 10)
    )}${hasDecimal ? "." : ""}${hasDecimal ? roundedDecimal[1] : ''}`;
  } else {
    out = `${addNegation ? "-" : ""}${
      parseInt(beforeDecimal, 10) + parseInt(roundedDecimal[0], 10)
    }${hasDecimal ? "." : ""}${hasDecimal ? roundedDecimal[1] : ''}`;
  }


  return out;
}


export const formatAccuracyDamage = accuracyDamage => {
  return `${formatValue(accuracyDamage / 1000, 2)}%`
}