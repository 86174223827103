import React, { useState, useRef, useEffect, useCallback } from 'react'

export const useAnchor = () => {
  const buttonRef = useRef()
  const [anchorPos, setAnchorPos] = useState(null)

  const handleResize = () => {
    if (buttonRef.current) {
      const bounds = buttonRef.current.getBoundingClientRect()
      if (!anchorPos || anchorPos.x !== bounds.x || anchorPos.y !== bounds.y || anchorPos.right !== bounds.right || anchorPos.bottom !== bounds.bottom) {
        setAnchorPos(bounds)
      }
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (buttonRef.current && anchorPos) {
      const bounds = buttonRef.current.getBoundingClientRect()
      if (anchorPos.x !== bounds.x || anchorPos.y !== bounds.y || anchorPos.right !== bounds.right || anchorPos.bottom !== bounds.bottom) {
        setAnchorPos(bounds)
      }
    }
  })

  const getButtonRef = useCallback(element => {
    if (!element) return
    const rect = element.getBoundingClientRect()
    setAnchorPos(prev => rect)
    buttonRef.current = element
  }, [])

  return [getButtonRef, anchorPos, buttonRef.current]
}
