
import styled from 'styled-components/macro'

const PlaylistIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ed4800;
`

export const PlaylistIcon = ({ size = 20, style, color }) => {
  return (
    <PlaylistIconContainer style={style}>
      <svg fill={color}  width={`${size}px`} height={`${size}px`} version="1.1" viewBox="0 0 700 580" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path d="m129.04 300.54c3.5 1.8633 7.2305 2.7969 10.965 2.7969 4.4336 0 9.1016-1.3984 12.832-3.9648l140-93.332c6.5352-4.2031 10.5-11.668 10.5-19.367s-3.9648-15.168-10.5-19.367l-140-93.332c-7-4.8984-16.332-5.3672-23.801-1.168-7.6992 3.9648-12.363 11.898-12.363 20.531v186.67c0 8.6328 4.6641 16.566 12.367 20.535z"/>
          <path d="m560 163.33h-210c-12.883 0-23.332 10.438-23.332 23.332 0 12.895 10.449 23.332 23.332 23.332h210c12.883 0 23.332-10.438 23.332-23.332 0-12.895-10.449-23.332-23.332-23.332z"/>
          <path d="m560 256.67h-256.67c-12.883 0-23.332 10.438-23.332 23.332s10.449 23.332 23.332 23.332h256.67c12.883 0 23.332-10.438 23.332-23.332s-10.449-23.332-23.332-23.332z"/>
          <path d="m303.33 116.67h256.67c12.883 0 23.332-10.438 23.332-23.332 0-12.895-10.449-23.332-23.332-23.332l-256.67-0.003906c-12.883 0-23.332 10.438-23.332 23.332 0 12.898 10.449 23.336 23.332 23.336z"/>
          <path d="m560 350h-420c-12.883 0-23.332 10.438-23.332 23.332s10.449 23.332 23.332 23.332h420c12.883 0 23.332-10.438 23.332-23.332s-10.449-23.332-23.332-23.332z"/>
          <path d="m560 443.33h-420c-12.883 0-23.332 10.438-23.332 23.332 0 12.895 10.449 23.332 23.332 23.332h420c12.883 0 23.332-10.438 23.332-23.332 0-12.895-10.449-23.332-23.332-23.332z"/>
        </g>
      </svg>
    </PlaylistIconContainer>
  )
}
