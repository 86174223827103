import styled from 'styled-components/macro'

const KovaaksPlusContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const KovaaksPlusIcon = ({ size = 20, style, ...rest }) => {
  return (
    <>
      <KovaaksPlusContainer style={style} {...rest}>
        <svg width={`${size}px`} height={`${size}px`} viewBox={`0 0 48 48`} fill="none">
          <path d="M30 4H18V18H4V30H18V44H30V30H44V18H30V4Z" fill="#ed4800" stroke="#ff7438" strokeWidth="3" strokeLinejoin="straight"/>
        </svg>
      </KovaaksPlusContainer>
    </>
  )
}
