import React from 'react';

import { News } from '../models';

const NewsContext = React.createContext({});

export function NewsProvider({ children }) {
	const [isNewsLoading, setLoading] = React.useState(false);
	const [newApiError, setApiError] = React.useState(null);
	const [allNews, setAllNews] = React.useState(null);
	const [todayNews, setTodayNews] = React.useState(null);

	async function createNews({ title, description, link, image }) {
		setLoading(true);
		const response = await News.createNews({
			title,
			description,
			link,
			image
		});
		setLoading(false);
		const { status, data } = response;
    if (status !== 200) setApiError(data.message)
    if (status === 200) {
      getDisplayNews()
    }
	}

	async function resetNews() {
		setApiError(null);
	}

	async function getAllNews() {
		setLoading(true);
		const response = await News.getAllNews();
		setLoading(false);
		const { status, data } = response;
		if (status == 200) {
			getDisplayNews()
		} else {
			setApiError(message);
		}
	}

	async function deleteNewsById(index) {
		setLoading(true);
		const response = await News.deleteNewsById(index);
		setLoading(false);
		const { status, data } = response;
    if (status !== 200) setApiError(data.message)
    if (status === 200) {
      getDisplayNews()
    }
	}

	async function getDisplayNews() {
		setLoading(true);
		const response = await News.getDisplayNews();
		setLoading(false);
		const { status, data } = response;
		if (status == 200) {
      setAllNews(data || [])
			setTodayNews(data || []);
		} else {
			setApiError(data.message);
		}
	}

	const memoedValue = React.useMemo(
		() => ({
			isNewsLoading,
			newApiError,
			todayNews,
			allNews,
			createNews,
			resetNews,
			getAllNews,
			deleteNewsById,
			getDisplayNews
		}),
		[isNewsLoading, newApiError, todayNews, allNews]
	);

	return (
		<NewsContext.Provider value={memoedValue}>
			{children}
		</NewsContext.Provider>
	);
}

export function useNews() {
	return React.useContext(NewsContext);
}
