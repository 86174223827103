import React, { useEffect } from 'react'
import styled from 'styled-components/macro'

export const InfoBubbleContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #322c3b87;
  border: 1px solid #504d547d;
  border-radius: 8px;
  padding: 16px;
  position: relative;
  box-shadow: 0px 4px 4px 0px #00000040;
  text-shadow: 0px 0px 4px #000000;
  backdrop-filter: blur(6px);
  white-space: pre-line;

  a {
    display: inline;
    color: #ed4800;
  }

`
