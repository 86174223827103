import React, { useState } from 'react';

import { Leaderboard } from '../models';
import { usePaginatedRequest } from '../../utils';

const LeaderboardContext = React.createContext({});

export function LeaderboardProvider({ children }) {
  const globalLeaderboard = usePaginatedRequest(Leaderboard.getGlobalLeaderboard, 20);

	async function searchAccountNames(username, group = null) {
		try {
			return { data: await Leaderboard.searchAccountNames(username, group) }
		} catch (err) {
			return err;
		}
	}

	const memoedValue = React.useMemo(
		() => ({
			globalLeaderboard,
			searchAccountNames
		}),
		[
			globalLeaderboard,
			searchAccountNames
		]
	);

	return (
		<LeaderboardContext.Provider value={memoedValue}>
			{children}
		</LeaderboardContext.Provider>
	);
}

export function useLeaderboard() {
	return React.useContext(LeaderboardContext);
}
