import { makeAutoObservable, runInAction } from 'mobx';
import axios from 'axios';
import { WEBAPP_BACKEND_URL } from '../constants';

export class AcademyStore {
  courseId = null
  courseName = null
  description = null
  courses = []
  lessons = []
  loaded = false
  selectedVideoId = null

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  setVideos(data) {
    this.list = data
    this.loaded = true
  }

  setSelectedVideo(data) {
    this.selectedVideoId = data
  }

  handleError(err) {
    console.log(err)
  }

  getCourses = async () => {
    this.rootStore.closeSidebar()
    this.loaded = false
    return axios.get(`${WEBAPP_BACKEND_URL}/academy/courses`)
    .then(result => {
      runInAction(() => {
        // console.log(`courses %O`, result)
        this.courses = result.data.data
        this.loaded = true
      })
    })
  }

  getCourse(id) {
    this.lessons = []
    return axios.get(`${WEBAPP_BACKEND_URL}/academy/course?courseId=${id}`)
    .then(result => {
      runInAction(() => {
        this.loaded = true
        Object.assign(this, result.data)
      })
      return result.data
    })
  }

  async completeLesson(courseId, lessonId) {
    return axios.post(`${WEBAPP_BACKEND_URL}/academy/lesson/complete`, {
      courseId,
      lessonId
    })
    .then(result => {
      runInAction(() => {
        this.loaded = true
      })

      return result.data
    })
  }
}




/*

const authClient = () => {
  const auth = getAuthorization()
  console.log(`auth %s`, auth)
  return fetch(`https://api.vimeo.com/oauth/authorize/client`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Basic ${auth}`,
      Accept: 'application/vnd.vimeo.*+json;version=3.4'
    },
    body: JSON.stringify({
      "grant_type": "client_credentials",
      "scope": "public"
    })
  })
  .catch(error => {
    console.log(`${JSON.stringify(error, null, 2)}`)
  })
  .then(result => {
    console.log(`${JSON.stringify(result, null, 2)}`)
  })
}
*/
