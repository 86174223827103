import axios from 'axios';
// import { Storage } from '../../utils';
import { WEBAPP_BACKEND_URL } from '../../constants';

export class Playlist {
	static async createStaffPickPlaylist({ playlistId, playlistName, description, image }) {
		const formData = new FormData();
		formData.append('playlistId', playlistId);
		formData.append('name', playlistName);
		formData.append('blurb', description);
		formData.append('image', image);
		formData.append('storagePath', 'playlist');

		return await axios.post(`${WEBAPP_BACKEND_URL}/staff-picks/playlist`, formData);
	}

	static async getAllPlaylists(page, max, search, subscribed) {
    const params = { page, max }
    if (search) params.search = search.toLowerCase()
    if (subscribed) params.subscribed = subscribed
		try {
			return await axios.get(`${WEBAPP_BACKEND_URL}/playlist/playlists`, { params });
		} catch (error) {
			return error;
		}
	}

  static async getTrendingPlaylists() {
    const params = {}
    try {
      return await axios.get(`${WEBAPP_BACKEND_URL}/playlist/trending`, { params });
    } catch (err) {
      return err
    }
  }


	static async getPopularPlaylists(page, max, playlistName, duration) {
		try {
      const params = { page, max };
      // playlistName not currently supported, included for more regular interface
      if (duration) params.duration = duration
			return await axios.get(`${WEBAPP_BACKEND_URL}/playlist/popular`, { params });
		} catch (error) {
			return error;
		}
	}

	static async getSubscribedPlaylists() {
		try {
			const token = Storage.accessToken;
			return await axios.get(`${WEBAPP_BACKEND_URL}/user/playlists/subscribed`,
				{
					headers: {
						...axios.defaults.headers,
						Authorization: `Bearer ${token}`,
						'Access-Control-Allow-Origin': '*'
					}
				}
			);
		} catch (error) {
			return error;
		}
	}

	static async deleteStaffPickPlaylistById(id) {
		try {
			return await axios.delete(`${WEBAPP_BACKEND_URL}/staff-picks/playlist`, { id });
		} catch (error) {
			return error;
		}
	}

	static async sortStaffPicksPlaylists(ids = []) {
		try {
			const data = {
				sort: [ids[0], ids[1], ids[2], ids[3], ids[4], ids[5], ids[6]]
			};

			return await axios.post(`${WEBAPP_BACKEND_URL}/staff-picks/playlist/sort`, data);
		} catch (error) {
			return error;
		}
	}

	static async searchPlaylistsWithWord(searchText) {
		try {
			const data = { word: searchText };
			// TODO: Not a route
			return await axios.post(`${WEBAPP_BACKEND_URL}/playlist/fetch`, data);
		} catch (error) {
			return error;
		}
	}

	static async getMetaPlaylists(page=0,max=10) {
		try {
			const params = { page, max };
			// return await axios.get(`${WEBAPP_BACKEND_URL}/playlist/all`, { params });
		} catch (error) {
			return error;
		}
	}

	static async getCreatorScenarioDetails(playlistId) {
		try {
			const params = { playlistId };
			return await axios.get(`${WEBAPP_BACKEND_URL}/playlist`, { params });
		} catch (error) {
			return error;
		}
	}
}
