
import styled from 'styled-components/macro'

const YoutubeIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const YoutubeIcon = ({ size = 20, style, ...props }) => {
  return (
    <YoutubeIconContainer style={style} {...props}>
      <svg fill="currentColor" width={`${size}px`} height={`${size}px`} viewBox="0 8 35 10">
        <path d="M17.6116 0.373047H17.8063C19.6044 0.379609 28.7153 0.445235 31.1719 1.10586C31.9145 1.30748 32.5912 1.70047 33.1344 2.24556C33.6776 2.79066 34.0682 3.46876 34.2672 4.21211C34.4881 5.04336 34.6434 6.14367 34.7484 7.27898L34.7703 7.50648L34.8184 8.07523L34.8359 8.30273C34.9781 10.3021 34.9956 12.1746 34.9978 12.5837V12.7477C34.9956 13.1721 34.9759 15.1715 34.8184 17.254L34.8009 17.4837L34.7812 17.7112C34.6719 18.9624 34.51 20.2049 34.2672 21.1193C34.0688 21.8629 33.6784 22.5414 33.1351 23.0866C32.5919 23.6317 31.9148 24.0245 31.1719 24.2255C28.6344 24.908 18.9897 24.9562 17.6531 24.9584H17.3425C16.6666 24.9584 13.8709 24.9452 10.9397 24.8446L10.5678 24.8315L10.3775 24.8227L10.0034 24.8074L9.62937 24.7921C7.20125 24.6849 4.88906 24.5121 3.82375 24.2234C3.08107 24.0225 2.4042 23.6301 1.86096 23.0853C1.31771 22.5405 0.927165 21.8625 0.728438 21.1193C0.485625 20.2071 0.32375 18.9624 0.214375 17.7112L0.196875 17.4815L0.179375 17.254C0.0714166 15.7717 0.0115935 14.2864 0 12.8002L0 12.5312C0.004375 12.0609 0.021875 10.4355 0.14 8.6418L0.155313 8.41648L0.161875 8.30273L0.179375 8.07523L0.2275 7.50648L0.249375 7.27898C0.354375 6.14367 0.509687 5.04117 0.730625 4.21211C0.929017 3.46846 1.31942 2.79004 1.86269 2.24485C2.40595 1.69966 3.083 1.30687 3.82594 1.10586C4.89125 0.821485 7.20344 0.646484 9.63156 0.537109L10.0034 0.521797L10.3797 0.508672L10.5678 0.50211L10.9419 0.486797C13.0237 0.419804 15.1064 0.382613 17.1894 0.375234H17.6116V0.373047ZM14 7.39492V17.9343L23.0934 12.6668L14 7.39492Z" />
      </svg>
    </YoutubeIconContainer>
  )
}
