
import styled from 'styled-components/macro'

const SearchIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .icon-hitarea {
    width: 100%;
    height: 100%;
    position: absolute;
  }

`
export const SearchIcon = (props) => {
  const { size = 20, style, onMouseDown, color, onClick, ...rest } = props
  return (
    <SearchIconContainer style={style} {...rest}>
      <div onMouseDown={onMouseDown} onClick={onClick} className="icon-hitarea"></div>
      <svg fill={color ? color : `currentColor`} width={`${size}px`} height={`${size}px`} version="1.1" viewBox="0 0 1200 1200">
        <path d="m1169.7 1109.6-60.141 60.141c-16.738 16.738-43.871 16.738-60.609 0l-313.15-313.15c-75.652 52.145-167.36 82.672-266.18 82.672-259.36 0-469.61-210.25-469.61-469.61 0-259.36 210.25-469.61 469.61-469.61 259.36 0 469.61 210.25 469.61 469.61 0 98.828-30.527 190.53-82.672 266.18l313.15 313.15c16.738 16.738 16.738 43.871 0 60.609zm-700.09-341.11c165.04 0 298.84-133.8 298.84-298.84 0-165.05-133.8-298.84-298.84-298.84-165.05 0-298.84 133.8-298.84 298.84 0 165.04 133.8 298.84 298.84 298.84z"/>
      </svg>
    </SearchIconContainer>
  )
}
