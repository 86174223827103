
import styled from 'styled-components/macro'

const UserIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const UserIcon = ({ size = 20, style, ...props }) => {
  return (
    <UserIconContainer style={style} {...props}>
      <svg fill="currentColor" width={`${size}px`} height={`${size}px`} viewBox="0 0 1200 1200">
        <path d="m600 112.5c-268.75 0-487.5 218.75-487.5 487.5s218.75 487.5 487.5 487.5 487.5-218.75 487.5-487.5-218.75-487.5-487.5-487.5zm-235 856.25c3.75-130 111.25-242.5 235-242.5s231.25 112.5 235 242.5c-67.5 43.75-148.75 68.75-235 68.75s-167.5-25-235-68.75zm125-430c0-60 48.75-110 110-110s110 48.75 110 110-48.75 110-110 110-110-50-110-110zm392.5 395c-16.25-121.25-105-221.25-217.5-250 55-25 93.75-81.25 93.75-145 0-87.5-71.25-160-160-160s-160 71.25-160 160c0 65 38.75 120 93.75 145-112.5 28.75-200 128.75-217.5 250-92.5-80-152.5-200-152.5-333.75 0-241.25 196.25-437.5 437.5-437.5s437.5 196.25 437.5 437.5c0 133.75-60 253.75-155 333.75z" />
      </svg>
    </UserIconContainer>
  )
}
