import axios from 'axios';
import { BACKEND_URL, WEBAPP_BACKEND_URL } from '../../constants';
export class Statistics {
	static async getCurrentUserCount() {
		try {
			return await axios.get(`${BACKEND_URL}/webapp/concurrent-users`, { withCredentials: false });
		} catch (error) {
			console.log(error);
			return error;
		}
	}

	static async getCustomScenarioCount() {
		try {
			return await axios.get(`${BACKEND_URL}/webapp/custom-scenarios-count`, { withCredentials: false });
		} catch (error) {
			console.log(error);
			return error;
		}
	}

	static async getMonthlyPlayers() {
		try {
			return await axios.get(`${WEBAPP_BACKEND_URL}/user/monthly-players`, { withCredentials: false });
		} catch (error) {
			console.log(error);
			return error;
		}
	}
}
