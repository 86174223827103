import axios from 'axios';
import { WEBAPP_BACKEND_URL } from '../../constants';
import countryList from 'country-list';

export class Leaderboard {
	static async getGlobalLeaderboard(page, max, filterType, filterValue, group) {
		try {
			const params = { page, max, filterType, filterValue, group };
			const res = await axios.get(`${WEBAPP_BACKEND_URL}/leaderboard/global/scores`, { params });
			return res;
		} catch (err) {
			return err;
		}
	}

	static async searchAccountNames(username, group = null) {
		try {
			const params = { username };
			if (group?.includes('REGION-')) {
				const region = group.replace('REGION-','');
				params.region = region;
			} else if (group) {
				params.country = group
			}
			return await axios.get(`${WEBAPP_BACKEND_URL}/leaderboard/global/search/account-names`, { params });
		} catch (err) {
			return err;
		}
	}
}
