import React, { useRef, useState } from 'react'

export const useDebounce = (func, ms) => {
  const lastPullRef = useRef({ })
  const changeRef = useRef(null)

  return (key, args) => new Promise((resolve, reject) => {
    const now = performance.now()
    if (lastPullRef.current[key] === undefined) {
      lastPullRef.current[key] = -ms
    }
    changeRef.current = true
    const delta = now - lastPullRef.current[key]
    if (delta >= ms) {
      changeRef.current = false
      lastPullRef.current[key] = now
      resolve(func(key, args))
      return
    } else {
      setTimeout(() => {
        if (changeRef.current) {
          changeRef.current = false
          lastPullRef.current[key] = now
          resolve(func(key, args))
        }
      }, ms - delta + 10);
    }
    // resolve()
  })
}


export const useValidation = (func, delay) => {
  const valueRef = useRef('')
  const timeoutRef = useRef()
  const promiseRef = useRef()

  const doValidate = () => {
    func(valueRef.current).then(result => {
      promiseRef.current(result)
    })
  }

  const returnReponse = () => {
    let promise = new Promise((resolve, reject) => {
      promiseRef.current = resolve
    })
    return promise
  }

  const waitToQuery = () => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current)
    timeoutRef.current = setTimeout(() => {
      validate(valueRef.current, false)
    }, delay)
  }

  const validate = (value, trigger = true) => {
    if (!trigger) {
      doValidate()
      return
    }

    valueRef.current = value
    waitToQuery()
    let response = returnReponse()
    return response
  }

  return { validate }
}

