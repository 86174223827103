import countryList from 'country-list'

countryList.overwrite([{
  code: 'GB',
  name: 'United Kingdom'
}])

countryList.overwrite([
  {
    code: 'GB-SCT',
    name: 'Scotland'
  },
  {
    code: 'GB-WLS',
    name: 'Wales'
  },
  {
    code: 'GB-ENG',
    name: 'England'
  },
  {
    code: 'GB-NIR',
    name: 'Northern Ireland'
  },
  {
    code: 'XK',
    name: 'Kosovo'
  }
])




const removedCountries = new Set()
removedCountries.add('ax') // aland islands
removedCountries.add('yu') // yugoslavia

// removedCountries.add('ao')


const countryOptions = Object.entries(countryList.getCodeList())
.filter(i => removedCountries.has(i[0]) ? false : true)
.map(i => ({
  value: i[0],
  name: i[1]
})).sort((a,b) => {
  return a.name.localeCompare(b.name)
})

// console.log('countryOptions %O', countryOptions)

export { countryOptions }