import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro'
// import LoadingAnimation from '../assets/images/loading.svg'

const LoaderContainer = styled.div`
  margin: 32px;
  height: ${props => props.height ? props.height : '100%'};
  width: ${props => props.width ? props.width : 'unset'};
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  transition: 200ms linear opacity;

  svg {
    width: ${props => props.width ? props.width : '100px'};
    height: ${props => props.height ? props.height : '100px'};
  }
`

export const Loader = props => {
  return (
    <LoaderContainer {...props}>
      <svg width="38" height="38" viewBox="-2 -2 42 42">
        <defs>
          <linearGradient x1="8.042%" y1="0%" x2="65.682%" y2="23.865%" id="a">
            <stop stopColor="#ed4800" stopOpacity="0" offset="0%"/>
            <stop stopColor="#ed4800" stopOpacity=".631" offset="63.146%"/>
            <stop stopColor="#ed4800" offset="100%"/>
          </linearGradient>
        </defs>
        <g fill="none" fillRule="evenodd">
          <g transform="translate(1 1)">
            <path d="M36 18c0-9.94-8.06-18-18-18" id="Oval-2" stroke="url(#a)" strokeWidth="4">
              <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 18 18"
                to="360 18 18"
                dur="1s"
                repeatCount="indefinite" />
            </path>
            <circle fill="#ed4800" cx="36" cy="18" r="2">
              <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 18 18"
                to="360 18 18"
                dur="1s"
                repeatCount="indefinite" />
            </circle>
          </g>
        </g>
      </svg>

      {/* <img src={LoadingAnimation} /> */}
    </LoaderContainer>
  )
}
