import React, { useEffect, useRef, useCallback, useState, Suspense } from 'react';
import { Observer } from 'mobx-react-lite'
import styled from 'styled-components/macro'
import {
	BrowserRouter as Router,
	Route,
	Routes,
  Outlet,
	Navigate,
  useLocation,
  useNavigate
} from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { Search } from './components/Search'
import './assets/styles/App.scss';



// import HomeScene from './scenes/home';
// import CoachDashboardScene from './scenes/coach-dashboard';

import KovaakLeagueScene from './scenes/kovaak-league';
import ResourcesScene from './scenes/resources';
import KovaaksScene from './scenes/kovaaks';
import AdminScene from './scenes/admin';
import { Analytics } from './utils/analytics'
import { Modal } from './components/Modal'
import { useResponsive } from './hooks/useResponsive'
import { setAuthToken } from './utils';
import { UserItem } from './components/list-items/UserItem'

import { useUser } from './core';
import { useRoot } from './core/rootStore'
import { RequireAuth } from './utils/useAuth';
import { Header } from './components';
import { Loader } from './components/Loader'

// Check for token to keep user logged in
if (localStorage.jwtToken) {
	// Set auth token header auth
	const token = localStorage.jwtToken;
	setAuthToken(token);
}

const NavBackdrop = styled.div`
  position: absolute;
  pointer-events: none;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: linear-gradient(179deg,#1e1a226b 0%,#27242b7a 100%);
  backdrop-filter: blur(1px);
  box-shadow: 0px 0px 16px 0px inset #00000040;
  opacity: 0;
  transition: 200ms linear opacity, 200ms linear filter;

  &.active {
    opacity: 1;
    filter: blur(1px) brightness(0.8);
    pointer-events: auto;
  }
`

const PlayerSearchContainer = styled.div`
  position: relative;
  z-index: 100;
  pointer-events: none;

  &.search-tray {
    display: flex;
    padding: 8px;
    margin: 0px 8px;
    position: absolute;
    top: 8px;
    width: calc(100% - 16px);
    height: 48px;
    border-radius: 8px;
    background: #2f2934;
    box-shadow: 0px 0px 8px 0px #00000080;
    z-index: 1200;
    transition: 200ms linear transform, 200ms linear opacity;
    transform: scale(1.2);
    opacity: 0;

    &.active {
      pointer-events: auto;
      opacity: 1;
      top: 8px;
      transform: scale(1);
    }
  }
`

const AppRoutes = () => {
  const { verifyToken, logoutUser } = useUser()

  const location = useLocation()

  // route change
  useEffect(() => {
    if (localStorage.jwtToken) {
      verifyToken().then(result => {
        if (!result?.data?.success) {
          logoutUser()
        }
      })
    }

    Analytics.pageView()
  }, [location])

  return (
    <TransitionGroup className="route-transition-wrapper">
      {/* <CSSTransition
        timeout={200}
        classNames='fade'
        key={location.key}
      > */}
        <Routes>
          <Route
            exact
            path="/register"
            element={<Navigate replace to="/kovaaks/steam-connected" />}
          />
          <Route
            exact
            path="/kovaaks/"
            element={<Navigate replace to="/kovaaks/main" />}
          />
          <Route
            exact
            path="/kovaaks/*"
            element={<KovaaksScene />}
          />
          <Route
            exact
            path="/league"
            element={
              <RequireAuth>
                <KovaakLeagueScene />
              </RequireAuth>
            }
          />
          {/* <Route
            exact
            path="/teamview/main"
            element={
              <RequireAuth>
                <CoachDashboardScene />
              </RequireAuth>
            }
          /> */}
          {/* <Route
            exact
            path="/teamview/:page/:playerId"
            element={
              <RequireAuth>
                <CoachDashboardScene />
              </RequireAuth>
            }
          /> */}
          {/* <Route
            exact
            path="/teamview/:page/"
            element={
              <RequireAuth>
                <CoachDashboardScene />
              </RequireAuth>
            }
          /> */}
          <Route
            exact
            path="/resources"
            element={
              <RequireAuth>
                <ResourcesScene />
              </RequireAuth>
            }
          />
          <Route exact path="/admin/*" element={<AdminScene />} />
          <Route
            path="/admin/kovaaks/*"
            element={
              <RequireAuth>
                <AdminScene />
              </RequireAuth>
            }
          />
          <Route
            path="*"
            exact
            element={<Navigate replace to="/kovaaks" />}
          />
        </Routes>
      {/* </CSSTransition> */}
    </TransitionGroup>
  )
}

const openTermsOfService = () => {
  window.open('/kovaaks/terms-of-service', '_blank')
}

const openPrivacyPolicy = () => {
  window.open('/kovaaks/privacy-policy', '_blank')
}

export function App() {
  const breakpoint = useResponsive()
  const { nav } = useRoot()
  const { User } = useUser()
  const navigate = useNavigate()

	return (
    <div
      className={`screen-${breakpoint}`}
      style={{ position: 'relative', height: '100dvh', flexDirection: 'column', display: 'flex', overflow: 'hidden' }}
    >
      <Header />
      <Modal />
      <div className={`kovaaks screen-${breakpoint}`}>
        <Observer>
          {() => <NavBackdrop onClick={() => nav.setPlayerSearch(false)} className={`nav-overlay ${nav.showSearch ? 'active' : ''}`}></NavBackdrop>}
        </Observer>
        <Observer>
          {() => (breakpoint === 'xs') ?
          <PlayerSearchContainer className={`search-tray ${nav.showSearch ? 'active' : ''}`}>
            <Search
              query={User.searchUsers}
              close={false}
              autofocus
              dropdown
              persistent
              ItemComponent={UserItem}
              open={nav.showSearch}
              onBlur={() => {
                // nav.setPlayerSearch(false)
              }}
              placeholder="Search players..."
              onCommit={e => {
                navigate('/kovaaks/profile?username=' + encodeURIComponent(e.value))
              }}
            />
          </PlayerSearchContainer> : null}
        </Observer>
        <div className="kovaaks-container">
          <AppRoutes />
        </div>
        <div className="footer">
          <div className="item subtle-link" onClick={openPrivacyPolicy}>Privacy Policy</div>
          <div className="item subtle-link" onClick={openTermsOfService}>Terms of Service</div>
        </div>
      </div>
      <NotificationContainer />
    </div>
  );
}

