import styled from 'styled-components/macro'

const WarningIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const WarningIcon = ({ size = 20, style, color, ...props }) => {
  return (
    <WarningIconContainer style={style} {...props}>
      <svg fill={color ? color : `currentColor`} width={`${size}px`} height={`${size}px`} version="1.1" viewBox="0 0 700 550" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="m608.21 457.59-235.73-380.5c-6.9609-11.102-21.523-14.402-32.523-7.3789-2.9492 1.8789-5.4453 4.4023-7.3125 7.3789l-235.73 380.5c-6.9023 11.137-3.5508 25.809 7.4883 32.773 3.8984 2.457 8.4258 3.7109 13.02 3.6094h471.45c13.016-0.36719 23.27-11.305 22.91-24.434-0.11719-4.2305-1.3516-8.3594-3.5781-11.949zm-255.05-29.547c-15.98 0-28.938-13.07-28.938-29.195 0-16.121 12.957-29.195 28.938-29.195 15.984 0 28.938 13.074 28.938 29.195 0 16.129-12.957 29.195-28.938 29.195zm0-91.484c-15 0-27.164-12.266-27.164-27.402v-82.207c0-15.137 12.164-27.406 27.164-27.406s27.164 12.27 27.164 27.406v82.207c0 15.137-12.164 27.402-27.164 27.402z"/>
      </g>
      </svg>
    </WarningIconContainer>
  )
}


