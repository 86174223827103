import React, { forwardRef } from 'react'
import { VariableSizeList as List } from 'react-window'
import { searchOptions, sortDisplayList, getDisplayList, innerScrollElement, outerScrollElement } from './dropdownParts'

export const VirtualizedDropdownList = forwardRef((props, ref) => {
  const { selectedItem, displayList, ItemComponent, highlightedIndex,
    getItemProps, variant, searchValue, align, itemHeight, maxHeight } = props

  return (
    <List
      width="100%"
      key={variant === 'search' ? searchValue : displayList.length}
      height={Math.min(maxHeight, displayList.reduce((accum, curr) => accum + (!curr.hidden && (!selectedItem || curr.value !== selectedItem.value) ? 1 : 0), 0) * itemHeight)}
      innerElementType={innerScrollElement}
      outerElementType={outerScrollElement}
      itemCount={displayList.reduce((accum, curr) => accum + (!curr.hidden && (!selectedItem || curr.value !== selectedItem.value) ? 1 : 0), 0)}
      overscanCount={3}
      itemSize={index => {
        const item = displayList[index]
        if (item.hidden || (selectedItem && item.value === selectedItem.value)) {
          return 0
        } else {
          return itemHeight
        }
      }}
      ref={ref}
    >
      {({ index, style }) => {
        const item = displayList[index]
        if (item.hidden || (selectedItem && item.value === selectedItem.value)) return null
        const itemClasses = `${align ? `align-${align}` : ''} ${(item.value !== null) ? 'results' : 'no-results'} ${highlightedIndex === index ? 'selected' : ''}`
        return (
          <ItemComponent
            item={item}
            key={item.name}
            index={index}
            style={style}
            className={itemClasses}
            {...getItemProps({ key: item.value, index, item })}
          />
        )
      }}
    </List>
  )
})
