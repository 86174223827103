import React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import './styles.scss';

const AdminLinkContainer = styled.div``;

const AdminLink = (props) => {
  const { link, label } = props;
  return (
    <li className="admin-submenu-content-menu-item">
      <Link className="admin-submenu-content-menu-item-child" to={link}>
        <span className="admin-submenu-content-menu-item-child-inactive-text">
          {label}
        </span>
      </Link>
    </li>
  );
};

export function AdminSubMenu() {
  return (
    <>
      <div className="admin-submenu">
        <ul className="admin-submenu-content">
          <span className="admin-submenu-content-menu">
            Admin Panel
            <ul>
              <li className="admin-submenu-content-menu-item">
                Homepage
                <ul>
                  <li>
                    <Link
                      className="admin-submenu-content-menu-item-child"
                      to="/admin/kovaaks/news"
                    >
                      <span className="admin-submenu-content-menu-item-child-inactive-text">
                        Intro Section
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="admin-submenu-content-menu-item-child"
                      to="/admin/kovaaks/featured-scenario"
                    >
                      <span
                        className='admin-submenu-content-menu-item-child-inactive-text'
                      >
                        Featured Scenario
                      </span>
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="admin-submenu-content-menu-item">
                Playlist Page
                <ul>
                  <li>
                    <Link
                      className="admin-submenu-content-menu-item-child"
                      to="/admin/kovaaks/seven-staff"
                    >
                      <span
                        className={'admin-submenu-content-menu-item-child-inactive-text'}
                      >
                        7 Staff Picks
                      </span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="admin-submenu-content-menu-item">
                Scenario Page
                <ul>
                  <li>
                    <Link
                      className="admin-submenu-content-menu-item-child"
                      to="/admin/kovaaks/staff-scenario"
                    >
                      <span
                        className={'admin-submenu-content-menu-item-child-inactive-text'}
                      >
                        7 Staff Picks
                      </span>
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="admin-submenu-content-menu-item">
                <Link
                  className="admin-submenu-content-menu-item-child"
                  to="/admin/kovaaks/playlist-browser"
                >
                  <span
                    className={'admin-submenu-content-menu-item-child-inactive-text'}
                  >
                    Playlist Browser
                  </span>
                </Link>
              </li>
            </ul>
          </span>
          <span className="admin-submenu-content-menu">
            Academy
            <ul>
              <AdminLink
                label="Courses"
                link="/admin/kovaaks/academy"
              />
            </ul>
          </span>

          <span className="admin-submenu-content-menu">
            Video
            <ul>
              <li className="admin-submenu-content-menu-item">
                <Link
                  className="admin-submenu-content-menu-item-child"
                  to="/admin/kovaaks/video-main"
                >
                  <span
                    className={'admin-submenu-content-menu-item-child-inactive-text'}
                  >
                    Main
                  </span>
                </Link>
              </li>

              <li className="admin-submenu-content-menu-item">
                <Link
                  className="admin-submenu-content-menu-item-child"
                  to="/admin/kovaaks/video-guide"
                >
                  <span
                    className={'admin-submenu-content-menu-item-child-inactive-text'}
                  >
                    Guides
                  </span>
                </Link>
              </li>
              <li className="admin-submenu-content-menu-item">
                <Link
                  className="admin-submenu-content-menu-item-child"
                  to="/admin/kovaaks/video-aimtype"
                >
                  <span
                    className={'admin-submenu-content-menu-item-child-inactive-text'}
                  >
                    Aimtype
                  </span>
                </Link>
              </li>
              <li className="admin-submenu-content-menu-item">
                <Link
                  className="admin-submenu-content-menu-item-child"
                  to="/admin/kovaaks/video-game"
                >
                  <span
                    className={'admin-submenu-content-menu-item-child-inactive-text'}
                  >
                    Game
                  </span>
                </Link>
              </li>
            </ul>
          </span>
        </ul>
      </div>
    </>
  );
  }
