import React from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom';
import { App } from './App';
import { RootProvider } from './core/rootStore'
import {
	// AimTypeProvider,
	GameProvider,
	NewsProvider,
	ScenarioProvider,
	StatisticsProvider,
  UserProvider,
  PlaylistProvider,
  LeaderboardProvider
} from './core';
import { ErrorBoundary } from './components/ErrorBoundary';

const rootNode = document.getElementById('root')

export const AppWrapper = () => (
  <ErrorBoundary>
    <RootProvider>
      <Router>
        <UserProvider>
          <ScenarioProvider>
            <PlaylistProvider>
              <GameProvider>
                <NewsProvider>
                  <StatisticsProvider>
                    <LeaderboardProvider>
                      <App />
                    </LeaderboardProvider>
                  </StatisticsProvider>
                </NewsProvider>
              </GameProvider>
            </PlaylistProvider>
          </ScenarioProvider>
        </UserProvider>
      </Router>
    </RootProvider>
  </ErrorBoundary>
)

// enable this intead of createRoot & root.render below for SSR
// hydrateRoot(rootNode, <AppWrapper />)

const root = createRoot(rootNode)
root.render(
  <AppWrapper />
)

// ReactDOM.render(<AppWrapper />, rootNode)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
