import React, { useState, useEffect, useRef } from 'react';
import { setRequestAuthToken } from '../../utils/request';
import axios from 'axios';
import { WEBAPP_BACKEND_URL, AUTH_URL, AUTH2_DOMAIN } from '../../constants';
import { setAuthToken } from '../../utils';
import './styles.scss';
import { useUser } from '../../core'
import { Loader } from '../Loader'

// TODO: only go to home when closing signup

export default function Login(props) {
  const { onHandleLogging = () => {}, onLoggedIn = () => {} } = props

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginMessage, setLoginMessage] = useState('');
  const [loading, setLoading] = useState(false)

  const { setShowModal } = useUser()

  useEffect(() => {
    if (loginMessage) setLoginMessage('')
  }, [email, password])

  async function handleLogin(e) {
    e.preventDefault();
    let redirect
    if (email != '' && password != '') {
      setLoading(true)
      try {
        const response = await axios.post(
          `${AUTH_URL}/webapp/login`, {},
          { auth: { username: email, password } }
        );
        setLoading(false)

        if (response.status === 200) {
          const { auth, profile, redirect } = response.data;
          const { jwt } = auth;
          const { playerId, webapp: { username } } = profile;
          const token = `Bearer ${jwt}`;
          localStorage.setItem('jwtToken', token);
          localStorage.setItem('Username', username	);
          setAuthToken(token);
          setRequestAuthToken(token)
          if (redirect && playerId) {
            window.location.href = `${WEBAPP_BACKEND_URL}/steam/auth/user/${playerId}/${jwt}`;
          } else {
            onLoggedIn(true);
          }
        } else {
          setLoginMessage('Invalid email or password.')
          return;
        }
      } catch (error) {
        console.log(`caught error %O`, error)
        if (error.response.status === 412) {
          setLoginMessage(`You need to register first to create a KovaaK's Profile.`)
        } else if (error.response.status === 405) {
          setLoginMessage(`Invalid username or password.`)
        } else if (error.response.status === 409) {
          setLoginMessage(`Invalid username or password.`) // yes, this is the same as the message above on purpose
        } else if (error.response.status === 429) {
          setLoginMessage(`You have made too many failed login attempts. Please try again later or reset your password.`)
        } else {
          setLoginMessage('Unknown error, please try again or contact support.')
        }
        setLoading(false)
      }
    }
  }

	function handleSignup() {
    setShowModal({ register: true })
	}

  if (loading) return <Loader />

  return (
    <form className="login" onSubmit={handleLogin}>
      <div className="login-container">
        <div className="login-title">Log In</div>
        <div className="login-form">
          <div className="login-form-label-section">
            <label
              className="login-form-label-section-label"
              htmlFor="uname"
            >
              <b>Email or Username</b>
            </label>
            <label
              className="login-form-label-section-label"
              htmlFor="psw"
            >
              <b>Password</b>
            </label>
          </div>
          <div className="login-form-input-section">
            <input
              className="login-form-input-section-input"
              onChange={(e) => setEmail(e.target.value)}
              type="text"
              placeholder=""
              required
            />
            <input
              className="login-form-input-section-input"
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              placeholder=""
              required
            />
          </div>
        </div>
        {loginMessage ?
        <div className="form-info">{loginMessage}</div> : null}
          <div className="login-btn-group">
            <a
              style={{ fontSize: 14, alignItems: 'center', display: 'flex' }}
              className="subtle-link"
              href={AUTH2_DOMAIN}
              target="_blank"
            >
              Forgot password?
            </a>
            <div className="login-btn-group-option">
              <a onMouseDown={handleSignup}>REGISTER</a>
            </div>
            <div className="login-btn-group-button">
              <button type="submit">
                LOG IN
              </button>
            </div>
          </div>
        </div>
    </form>
  );
}
