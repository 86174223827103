import { makeAutoObservable } from 'mobx';
import { setAuthToken } from '../utils'

export class NavStore {
  showSearch = false

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  togglePlayerSearch() {

    this.showSearch = !this.showSearch
    console.log('toggleSearch -> %s', this.showSearch)
  }

  setPlayerSearch(value) {
    console.log('setPlayerSearch %s', value)
    this.showSearch = value
  }
}