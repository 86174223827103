import React from 'react';
import { Game } from '../models';

const GameContext = React.createContext({});

export function GameProvider({ children }) {
	const [isGameLoading, setLoading] = React.useState(false);
	const [gameApiError, setApiError] = React.useState(null);
	const [allGames, setAllGames] = React.useState(null);
	const [gamesForVideoGuide, setGamesVideoGuide] = React.useState([]);
	const [allVideoGuidesGames, setAllVideoGuidesGames] = React.useState([]);

  const handleResponse = response => {
    console.log('response %O', response)
		const { status, data } = response;
    getAllVideoGuidesGames()
  }

	async function createGame(game) {
    console.log('createGame %O', game)
		setLoading(true);
		const response = await Game.createGame(game);
    handleResponse(response)
	}

	async function createVideoGuidesGame(game) {
		setLoading(true);
		const response = await Game.createVideoGuidesGame(game);
		const { status, data } = response;
    handleResponse(response)
	}

	async function gameReset() {
		setApiError(null);
	}

	async function getAllGames() {
		setLoading(true);
		const response = await Game.getAllGames();
		setLoading(false);
		const { status, data } = response;
		if (status == 200) {
			setAllGames(data || []);
		}
	}

	async function getAllVideoGuidesGames() {
		setLoading(true);
		const response = await Game.getAllVideoGuidesGames();
		setLoading(false);
		const { status, data } = response;
		if (status == 200) {
			setAllVideoGuidesGames(data);
		}
	}

	async function deleteGameById(id) {
		setLoading(true);
		const response = await Game.deleteGameById(id);
    handleResponse(response)
	}

	async function deleteVideoGuidesGameById(id) {
		setLoading(true);
		const response = await Game.deleteVideoGuidesGameById(id);
    handleResponse(response)
	}

	async function getGamesForVideoGuide(id) {
		setLoading(true);
		const response = await Game.getGamesForVideoGuide(id);
		setLoading(false);
		const { status, data } = response;
		if (status == 200) {
			setGamesVideoGuide(data || null);
		}
	}

	const memoedValue = React.useMemo(
		() => ({
			isGameLoading,
			gameApiError,
			allGames,
			gamesForVideoGuide,
			allVideoGuidesGames,
			createGame,
			getAllGames,
			getGamesForVideoGuide,
			deleteGameById,
			gameReset,
			getAllVideoGuidesGames,
			createVideoGuidesGame,
			deleteVideoGuidesGameById
		}),
		[
			isGameLoading,
			allGames,
			gameApiError,
			gamesForVideoGuide,
			allVideoGuidesGames
		]
	);

	return (
		<GameContext.Provider value={memoedValue}>
			{children}
		</GameContext.Provider>
	);
}

export function useGame() {
	return React.useContext(GameContext);
}
