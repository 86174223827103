import styled from 'styled-components/macro'

const CalculatorIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ed4800;
`

export const CalculatorIcon = ({ size = 20, style, color }) => {
  return (
    <CalculatorIconContainer style={style}>
      <svg fill={color}  width={`${size}px`} height={`${size}px`} version="1.1" viewBox="100 0 500 550" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="m455 70h-210c-24.125 0-43.75 19.625-43.75 43.75v332.5c0 24.125 19.625 43.75 43.75 43.75h210c24.125 0 43.75-19.625 43.75-43.75v-332.5c0-24.125-19.625-43.75-43.75-43.75zm-162.27 360.73c0 9.668-7.832 17.5-17.5 17.5h-12.73c-9.668 0-17.5-7.832-17.5-17.5v-12.73c0-9.668 7.832-17.5 17.5-17.5h12.73c9.668 0 17.5 7.832 17.5 17.5zm0-78.75c0 9.668-7.832 17.5-17.5 17.5h-12.73c-9.668 0-17.5-7.832-17.5-17.5v-12.73c0-9.668 7.832-17.5 17.5-17.5h12.73c9.668 0 17.5 7.832 17.5 17.5zm0-78.75c0 9.668-7.832 17.5-17.5 17.5h-12.73c-9.668 0-17.5-7.832-17.5-17.5v-12.73c0-9.668 7.832-17.5 17.5-17.5h12.73c9.668 0 17.5 7.832 17.5 17.5zm81.141 157.5c0 9.668-7.832 17.5-17.5 17.5h-12.73c-9.668 0-17.5-7.832-17.5-17.5v-12.73c0-9.668 7.832-17.5 17.5-17.5h12.73c9.668 0 17.5 7.832 17.5 17.5zm0-78.75c0 9.668-7.832 17.5-17.5 17.5h-12.73c-9.668 0-17.5-7.832-17.5-17.5v-12.73c0-9.668 7.832-17.5 17.5-17.5h12.73c9.668 0 17.5 7.832 17.5 17.5zm0-78.75c0 9.668-7.832 17.5-17.5 17.5h-12.73c-9.668 0-17.5-7.832-17.5-17.5v-12.73c0-9.668 7.832-17.5 17.5-17.5h12.73c9.668 0 17.5 7.832 17.5 17.5zm81.141 157.5c0 9.668-7.832 17.5-17.5 17.5h-12.73c-9.668 0-17.5-7.832-17.5-17.5v-91.48c0-9.668 7.832-17.5 17.5-17.5h12.73c9.668 0 17.5 7.832 17.5 17.5zm0-157.5c0 9.668-7.832 17.5-17.5 17.5h-12.73c-9.668 0-17.5-7.832-17.5-17.5v-12.73c0-9.668 7.832-17.5 17.5-17.5h12.73c9.668 0 17.5 7.832 17.5 17.5zm0-91.551c0 10.805-8.7578 19.566-19.566 19.566h-170.88c-10.805 0-19.566-8.7578-19.566-19.566v-48.371c0-10.805 8.7578-19.566 19.566-19.566h170.87c10.805 0 19.566 8.7578 19.566 19.566v48.371z"/>
      </g>
      </svg>
    </CalculatorIconContainer>
  )
}
