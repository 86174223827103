import React from 'react';

import { Statistics } from '../models';

const StatisticsContext = React.createContext({});

export function StatisticsProvider({ children }) {
	const [isStatisticsLoading, setLoading] = React.useState(false);
	const [statisticsApiError, setApiError] = React.useState(null);
	const [currentUserCount, setCurrentUserCount] = React.useState(null);
	const [customScenarioCounts, setCustomScenarioCounts] =
		React.useState(null);
	const [monthlyPlayers, setMonthlyPlayers] = React.useState(null);

	async function getCurrentUserCount() {
		setLoading(true);
		const response = await Statistics.getCurrentUserCount();
		setLoading(false);
		const { status, data } = response;
		if (status == 200) {
			setCurrentUserCount(data?.concurrentUsers);
		}
	}

	async function getCustomScenarioCount() {
		setLoading(true);
		const response = await Statistics.getCustomScenarioCount();
		setLoading(false);
		const { status, data } = response;
		if (status == 200) {
			setCustomScenarioCounts(data?.customScenarioCount);
		}
	}

	async function getMonthlyPlayers() {
		const response = await Statistics.getMonthlyPlayers();
		const { status, data } = response;
		if (status == 200) {
			setMonthlyPlayers(data?.count);
		}
	}

	const memoedValue = React.useMemo(
		() => ({
			isStatisticsLoading,
			statisticsApiError,
			currentUserCount,
			customScenarioCounts,
			monthlyPlayers,
			getCurrentUserCount,
			getCustomScenarioCount,
			getMonthlyPlayers
		}),
		[
			isStatisticsLoading,
			statisticsApiError,
			currentUserCount,
			customScenarioCounts,
			monthlyPlayers
		]
	);

	return (
		<StatisticsContext.Provider value={memoedValue}>
			{children}
		</StatisticsContext.Provider>
	);
}

export function useStatistics() {
	return React.useContext(StatisticsContext);
}
