
import styled from 'styled-components/macro'

const DiscordIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const DiscordIcon = ({ size = 20, style, ...props }) => {
  return (
    <DiscordIconContainer style={style} {...props}>
      <svg fill="currentColor" width={`${size}px`} height={`${size}px`} viewBox="4 2 19 30">
        <path d="M10.6942 14.042C11.5692 14.042 12.2779 14.6982 12.2619 15.5003C12.2619 16.3024 11.5706 16.9587 10.6942 16.9587C9.83375 16.9587 9.125 16.3024 9.125 15.5003C9.125 14.6982 9.81771 14.042 10.6942 14.042ZM16.3058 14.042C17.1823 14.042 17.875 14.6982 17.875 15.5003C17.875 16.3024 17.1823 16.9587 16.3058 16.9587C15.4454 16.9587 14.7381 16.3024 14.7381 15.5003C14.7381 14.6982 15.4294 14.042 16.3058 14.042ZM23.5494 0.916992C25.2454 0.916992 26.625 2.32574 26.625 4.07137V31.542L23.4006 28.6326L21.585 26.9176L19.6644 25.0947L20.4606 27.9282H3.45063C1.75458 27.9282 0.375 26.5195 0.375 24.7739V4.07137C0.375 2.32574 1.75458 0.916992 3.45063 0.916992H23.5479H23.5494ZM17.7598 20.9151C21.0746 20.8087 22.3506 18.5876 22.3506 18.5876C22.3506 13.657 20.1894 9.6597 20.1894 9.6597C18.031 8.00741 15.9748 8.05262 15.9748 8.05262L15.7648 8.29762C18.3154 9.09387 19.4996 10.243 19.4996 10.243C18.107 9.45843 16.5722 8.95873 14.9846 8.77303C13.9775 8.65929 12.9603 8.66908 11.9556 8.8022C11.8652 8.8022 11.7894 8.81824 11.7004 8.83283C11.1754 8.87949 9.89937 9.07783 8.29521 9.79824C7.74104 10.0578 7.41 10.243 7.41 10.243C7.41 10.243 8.65542 9.03262 11.3548 8.23637L11.2046 8.05262C11.2046 8.05262 9.14979 8.00741 6.99 9.66116C6.99 9.66116 4.83021 13.657 4.83021 18.5876C4.83021 18.5876 6.09021 20.8072 9.405 20.9151C9.405 20.9151 9.95917 20.2268 10.4113 19.6449C8.50521 19.0616 7.78625 17.8366 7.78625 17.8366C7.78625 17.8366 7.935 17.9445 8.20479 18.0976C8.21938 18.1122 8.23396 18.1282 8.26458 18.1428C8.30979 18.1749 8.355 18.1895 8.40021 18.2201C8.775 18.4345 9.14979 18.6022 9.49396 18.7407C10.1094 18.9857 10.8444 19.2307 11.7004 19.3999C12.9828 19.6513 14.3012 19.6562 15.5854 19.4145C16.3334 19.281 17.0632 19.0602 17.7598 18.7568C18.2848 18.557 18.8696 18.2653 19.485 17.8526C19.485 17.8526 18.7354 19.1082 16.7696 19.6755C17.2202 20.2574 17.7613 20.9151 17.7613 20.9151H17.7598Z" />
      </svg>
    </DiscordIconContainer>
  )
}
