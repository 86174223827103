import { useRef } from 'react'

export const useOnMount = (callback) => {
  const mountRef = useRef()
  if (!mountRef.current) {
    mountRef.current = true
    callback()
  }
}

