import axios from 'axios';
import request from '../../utils/request'
import { WEBAPP_BACKEND_URL } from '../../constants';

export class Scenario {
	static async updateFeaturedScenario({ leaderboardId, description, image }) {
		try {
			// Adding this until there is a date picker on the UI
			const startDate = new Date();
			const endDate = new Date(startDate.getTime() + 7 * 24 * 60 * 60 * 1000) // One week from now

			const formData = new FormData();
			formData.append('leaderboardId', leaderboardId);
			formData.append('description', description);
			formData.append('image', image);
			formData.append('startDate', startDate);
			formData.append('endDate', endDate);

			return await axios.put(`${WEBAPP_BACKEND_URL}/scenario/featured`, formData);
		} catch (error) {
			return error;
		}
	}

	static async getFeaturedScenario() {
		try {
			return await axios.get(`${WEBAPP_BACKEND_URL}/scenario/featured`);
      // return await request.get('scenario/featured')
		} catch (err) {
			return err;
		}
	}

	static async createStaffPickScenario({
		scenarioId,
		name,
		description,
		image
	}) {
		try {
			const formData = new FormData();
			formData.append('name', name);
			formData.append('storagePath', 'image');
			formData.append('path', 'scenario');
			formData.append('blurb', description);
			formData.append('scenarioId', scenarioId);
			// formData.append('order', 99);
			console.log(formData);

			return await axios.post(`${WEBAPP_BACKEND_URL}/staff-picks/scenario`, formData);
		} catch (err) {
			return err;
		}
	}

	static async getRecentScenarios() {
		try {
			return await axios.get(`${WEBAPP_BACKEND_URL}/scenario/recent`);
		} catch (err) {
			return err;
		}
	}

	static async getOnTheRiseScenarios(page, max) {
		try {
			const params = { page, max }
			return await axios.get(`${WEBAPP_BACKEND_URL}/scenario/on-the-rise`, { params });
		} catch (err) {
			return err;
		}
	}

  static async getTrendingScenarios() {
    const params = {}
    try {
      return await axios.get(`${WEBAPP_BACKEND_URL}/scenario/trending`, { params });
      // return await request.get('scenario/trending')
    } catch (err) {
      return err
    }
  }

	static async getPopularScenarios(page, max = 10, scenarioName, duration) {
    // const params = { page, max };
    // if (scenarioName) params.scenarioNameSearch = scenarioName
    // if (duration) params.duration = duration
    // return await request.get('scenario/popular', params)

		try {
			const params = { page, max };
      if (scenarioName) params.scenarioNameSearch = scenarioName
      if (duration) params.duration = duration
			return await axios.get(`${WEBAPP_BACKEND_URL}/scenario/popular`, { params })

		} catch (err) {
			return err;
		}
	}

	static async getScenariosAsKeyValuePairs(all = true) {
		try {
			if (all) {
				return await axios.get(`${WEBAPP_BACKEND_URL}/leaderboard/list`);
			} else {
				return await axios.get(`${WEBAPP_BACKEND_URL}/leaderboard/list?limit=10`);
			}
		} catch (err) {
			return err;
		}
	}

	static async getAllLeaderboards(all = false) {
		try {
			if (all) {
				return await axios.get(`${WEBAPP_BACKEND_URL}/leaderboard/all`);
			} else {
				return await axios.get(`${WEBAPP_BACKEND_URL}/leaderboard/pagination`);
			}
		} catch (err) {
			return err;
		}
	}

	static async getAllStaffPickScenarios() {
		try {
			return await axios.get(`${WEBAPP_BACKEND_URL}/staff-picks/scenarios`);
		} catch (err) {
			return err;
		}
	}

	static async deleteAStaffPickScenarioById(scenarioId) {
		try {
			const data = { scenarioId };
			return await axios.delete(`${WEBAPP_BACKEND_URL}/staff-picks/scenario`, data);
		} catch (err) {
			return err;
		}
	}

	static async getMetaScenariosWithMax(max = 100) {
		try {
			const params = { max };
			return await axios.get(`${WEBAPP_BACKEND_URL}/scenario/all`, { params });
		} catch (err) {
			return err;
		}
	}

	static async getAllScenarios() {
		try {
			return await axios.get(`${WEBAPP_BACKEND_URL}/scenario/all`);
		} catch (err) {
			return err;
		}
	}

  static async getScenarioDetails(leaderboardId) {
    try {
      return await axios.get(`${WEBAPP_BACKEND_URL}/scenario/details?leaderboardId=${leaderboardId}`)
    } catch (err) {
      return err
    }
  }

	static async getAllMetaScenarios(page = 0, max = 20, scenarioName = null) {
		try {
			if (scenarioName == null) {
				const params = { page, max };
				// TODO: Not a route
				return await axios.get( `${WEBAPP_BACKEND_URL}/scenario/all`, { params });
			} else {
				// TODO: Not a route
				const params = { page, max, scenarioName };
				return await axios.get( `${WEBAPP_BACKEND_URL}/scenario/by-name`, { params } );
			}
		} catch (err) {
			return err;
		}
	}

	static async getCreatorScenarioDetails(scenarioName, userName) {
		try {
			const params = { scenarioName, userName };
			return await axios.get(`${WEBAPP_BACKEND_URL}/scenario/creator`, { params });
		} catch (err) {
			return err;
		}
	}
}
