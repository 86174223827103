import React from 'react'
import { DropdownDownIcon } from '../icons/DropdownDown'
import { DropdownUpIcon } from '../icons/DropdownUp'

const displaySelected = props => {
  const { placeholder, selectedItem, ItemComponent  } = props
  if (selectedItem) {
    if (ItemComponent) {
      return <ItemComponent item={selectedItem} />
    } else {
      return selectedItem.name
    }
  } else {
    return placeholder ? placeholder : ''
  }
}

export const DropdownButton = props => {
  const { anchor, selectedItem, placeholder, handleFocus,
    getToggleButtonProps, isOpen, hideHandle } = props

  const toggleButtonProps = getToggleButtonProps ? getToggleButtonProps() : {}

  return (
    <div className="button-container" ref={anchor}>
      <div className="selected-name" style={{ width: hideHandle && '100%', padding: hideHandle && '0' }}>
        <div className="hd-label-truncated centered" onClick={handleFocus}>
          {displaySelected(props)}
        </div>
      </div>
      <div
        className='dropdown-icon'
        style={{ display: hideHandle ? 'none' : 'block' }}
        {...toggleButtonProps}
      >
        {isOpen ? <><DropdownUpIcon size={32} /></> : <><DropdownDownIcon onClick={handleFocus} size={32} /></>}
      </div>
    </div>
  )
}