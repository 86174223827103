import styled from 'styled-components/macro'

const DropdownDownContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
// TODO: this is named wrong
export const DropdownDownIcon = ({ size = 20, style, color }) => {
  return (
    <DropdownDownContainer style={style}>
      <svg width={`${size}px`} height={`${size}px`} version="1.1" viewBox="100 120 1000 1000" fill={color ? color : 'currentColor'}>
        <path d="m367.16 576.26 197.49 197.49c19.527 19.527 51.184 19.527 70.711 0l197.49-197.49c31.5-31.5 9.1914-85.355-35.355-85.355h-394.98c-44.543 0-66.852 53.855-35.355 85.355z"/>
      </svg>
    </DropdownDownContainer>
  )
}
