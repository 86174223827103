// import ReactGA from 'react-ga'
const debugAnalytics = false
// const isAnalyticsActive = process.env.PUBLIC_URL !== '' || debugAnalytics

const domain = window.document.domain
const isAnalyticsActive = domain !== 'localhost' || debugAnalytics


import { GTAG } from '../constants/'


const Analytics = {
  init: () => {
    // if (isAnalyticsActive) {
    //   ReactGA.initialize(GTAG, {
    //     debug: debugAnalytics
    //   })
    // }
  },
  pageView: () => {
    // if (isAnalyticsActive) {
    //   ReactGA.pageview(window.location.pathname + window.location.search)
    // }
  }
}

export { Analytics }


// TODO: const { navigate } = useNavigate()
// navigate('/page')