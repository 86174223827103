import React from 'react'
import styled from 'styled-components/macro'
import { InfoBubbleContainer } from '../primitives/InfoBubbleContainer'
import { BrowserRouter as Router } from 'react-router-dom';

const ErrorBoundaryContainer = styled.div`
  border-radius: 16px;
  margin: 16px;
  display: flex;
  justify-content: center;
`

export class ErrorBoundary extends React.Component {
  constructor(props) {

    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {

    // to display this page:
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    // console.log(`error: ${JSON.stringify(error, null, 2)}`)
    // console.log(`errorInfo: ${JSON.stringify(errorInfo, null, 2)}`)
  }

  render() {
    if (this.state.hasError) {
      return (
        <Router>
          <div className='content-wrapper'>
            <div className='kovaaks-container-block'>
              <ErrorBoundaryContainer>
                <InfoBubbleContainer style={{ display: "flex", flexDirection: 'column', maxWidth: 600 }}>
                  <div>Oops... we ran into a problem. Please contact support if this continues. <a style={{ marginLeft: 16 }} href="/kovaaks/main">Home</a></div>

                </InfoBubbleContainer>
              </ErrorBoundaryContainer>
            </div>
          </div>
        </Router>
      )
    } else {
      return this.props.children
    }
  }
}