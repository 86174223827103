import React, { useRef } from 'react'

export const useSearch = (queryFunc, delay = 500, setLoading) => {
  const valueRef = useRef('')
  const timeoutRef = useRef()
  const promiseRef = useRef()

  const doSearch = () => {
    setLoading(true)
    queryFunc(valueRef.current).then(result => {
      setLoading(false)
      promiseRef.current(result)
    })
  }

  const returnReponse = () => {
    let promise = new Promise((resolve, reject) => {
      promiseRef.current = resolve
    })
    return promise
  }

  const waitToQuery = () => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current)
    timeoutRef.current = setTimeout(() => {
      search(valueRef.current, false)
    }, delay)
  }

  const search = (value, trigger = true) => {
    if (!trigger) {
      doSearch()
      return
    }

    valueRef.current = value
    waitToQuery()
    let response = returnReponse()
    return response
  }

  return {
    search
  }
}
