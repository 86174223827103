
import styled from 'styled-components/macro'

const HamburgerMenuIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const HamburgerMenuIcon = ({ size = 20, style, ...props }) => {
  return (
    <HamburgerMenuIconContainer style={style} {...props}>
      <svg fill="currentColor" width={`${size}px`} height={`${size}px`} viewBox="0 0 1200 1200">
        <g>
          <path d="m1200 599.61c0 20.855-8.2852 40.859-23.031 55.605-14.75 14.746-34.75 23.031-55.605 23.031h-1042.7c-23.887-4.8477-44.172-20.5-54.926-42.375-10.75-21.875-10.75-47.5 0-69.375 10.754-21.875 31.039-37.527 54.926-42.375h1045.9c19.773 0.77344 38.527 8.9766 52.52 22.969 13.992 13.992 22.195 32.746 22.973 52.52z"/>
          <path d="m1200 902.36c0 20.855-8.2852 40.855-23.031 55.605-14.75 14.746-34.75 23.031-55.605 23.031h-1042.7c-23.887-4.8516-44.172-20.504-54.926-42.379-10.75-21.875-10.75-47.496 0-69.371 10.754-21.875 31.039-37.527 54.926-42.379h1045.9c19.773 0.77734 38.527 8.9805 52.52 22.973 13.992 13.992 22.195 32.746 22.973 52.52z"/>
          <path d="m1200 300c0 20.855-8.2852 40.859-23.031 55.605-14.75 14.746-34.75 23.031-55.605 23.031h-1042.7c-28.094 0-54.055-14.988-68.102-39.316-14.047-24.332-14.047-54.309 0-78.641 14.047-24.328 40.008-39.316 68.102-39.316h1045.9c20.305 0.8125 39.508 9.4531 53.582 24.113 14.074 14.66 21.926 34.203 21.91 54.523z"/>
        </g>
      </svg>
    </HamburgerMenuIconContainer>
  )
}
