import React, { useEffect, Suspense } from 'react';
import { Route, Routes, Outlet, useNavigate } from 'react-router-dom';
import { lazyWithPreload } from 'react-lazy-with-preload'
import { Loader } from '../../components/Loader'
import {
	// AdminDayScenario,
	// AdminNews,
	// AdminPlaylistBrowser,
	// AdminSevenStaff,
	// AdminStaffScenario,
	// AdminVideoGuide,
	// AdminVideoGuidesAimtype,
	// AdminVideoMain,
	Header
} from '../../components';
import { AdminSubMenu } from '../../components/admin-submenu'
import { AdminNews } from '../../components/admin-news'
import { AdminDayScenario } from '../../components/admin-featured-scenario';
// import { Storage, isValidText } from '../../utils';

const AddCourse = lazyWithPreload(() => import('../../components/academy/admin/AddCourse'))
const CourseList = lazyWithPreload(() => import('../../components/academy/admin/CourseList'))

import 'react-notifications/lib/notifications.css';
import './styles.scss';

import {
	// AimTypeProvider,
	// GameProvider,
	// PlaylistProvider,
	ScenarioProvider,
	// UserProvider,
	useUser,
	// VideoProvider,
	NewsProvider
} from '../../core';


import { NotificationManager } from 'react-notifications';
// import { AdminVideoGuidesGame } from '../../components/admin-video-guides-game';

export default function AdminScene(props) {
	const navigate = useNavigate();
	const { currentUser, getProfileInfo, checkAuth } = useUser();

	useEffect(() => {
		if (currentUser != null) {
			if (!(currentUser?.webapp?.roles?.admin)) {
				navigate('/kovaaks');
				NotificationManager.error(
					'There is no permission.',
					'Error',
					2000,
					() => {}
				);
			}
		}
	}, [currentUser]);

	useEffect(() => {
		if (!checkAuth()) navigate('/kovaaks');
		getProfileInfo();

    CourseList.preload()
    AddCourse.preload()
	}, []);

	if (!currentUser) return <div>loading</div>;
	else
		return (
			<div className="admin-scene">
        <AdminSubMenu />
          <div className="admin-scene-container">
            <Suspense fallback={<div className="kovaaks-container-block"><Loader /></div>}>
              <Routes>
                <Route path="news" element={<NewsProvider><AdminNews /></NewsProvider>} />
                <Route path="featured-scenario" element={<ScenarioProvider><AdminDayScenario /></ScenarioProvider>} />
                <Route path="academy" element={<CourseList />} />
                <Route path="academy/add" element={<AddCourse />} />
                {/* <Route path="seven-staff" element={<AdminSevenStaff />} /> */}
                {/* <Route path="playlist-browser" element={<AdminPlaylistBrowser />} /> */}
                {/* <Route path="video-main" element={<AdminVideoMain />} /> */}
                {/* <Route path="video-guide" element={<AdminVideoGuide />} /> */}
                {/* <Route path="staff-scenario" element={<AdminStaffScenario />} /> */}
                {/* <Route path="video-aimtype" element={<AdminVideoGuidesAimtype />} /> */}
                {/* <Route path="video-game" element={<AdminVideoGuidesGame />} /> */}
              </Routes>
            </Suspense>
            <Outlet />
          </div>
			</div>
		);
}
