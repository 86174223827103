import { makeAutoObservable } from 'mobx';
import { setAuthToken } from '../utils'

export class UserStore {
  currentUser = null
  profileUser = null

  showModal = false

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  checkAuth() {
    if (localStorage.jwtToken) return true
    return false
  }

  logout() {
    localStorage.removeItem('jwtToken');
    localStorage.removeItem('Username');
    localStorage.removeItem('userInfo');
    this.currentUser = null
    this.showModal = { login: true }
    setAuthToken(null)
  }

  loadUserInfo() {

  }


}