import axios from 'axios';
import { WEBAPP_BACKEND_URL } from '../../constants';

export class News {
	static async createNews({ title, description, link, image }) {
		try {
			const formData = new FormData();
			formData.append('image', image);
			formData.append('storagePath', 'news');
			formData.append('title', title);
			formData.append('description', description);
			formData.append('link', link);

			return await axios.post(`${WEBAPP_BACKEND_URL}/news`, formData);
		} catch (error) {
			return error;
		}
	}

	static async getAllNews() {
		try {
			return await axios.get(`${WEBAPP_BACKEND_URL}/news/all`);
		} catch (error) {
			return error;
		}
	}

	static async getDisplayNews() {
		try {
			const params = { page: 0, max: 6}
			return await axios.get(`${WEBAPP_BACKEND_URL}/news/all`, { params });
		} catch (error) {
			return error;
		}
	}

	static async deleteNewsById(newsId) {
		try {
			const params = { newsId };
			return await axios.delete(`${WEBAPP_BACKEND_URL}/news`, { params });
		} catch (error) {
			return error;
		}
	}
}
