import { makeAutoObservable, toJS } from 'mobx';
import axios from 'axios'
import { NotificationManager } from 'react-notifications'
import { WEBAPP_BACKEND_URL } from '../constants'

let useTestAccount = false

let userId, bearerToken

if (useTestAccount) {
  userId = ``
  bearerToken = ``
} else {
  userId = ``
  bearerToken = ``
}

/*
  when to auto-fill?

  on fresh line, if field is number on blur then autofill
  if pasted into then autofill

  if there is a name/description we don't replace it
    * we could also ask for confirmation,
    or have a button to autofill
*/

export const tempIdSymbol = Symbol('id')
let tempId = 1


export class AcademyAdminStore {
  loading = true
  courses = []
  courseName = ''
  description = ''
  lessons = []
  published = false

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  setLoading = loading => {
    this.loading = loading
  }

  getCourse = id => {
    this.loading = true
    return axios.get(`${WEBAPP_BACKEND_URL}/academy/course?courseId=${id}`)
    .then(result => {
      Object.assign(this, result.data)
      this.loading = false
    })
  }

  removeCourse = async id => {
    return axios.delete(`${WEBAPP_BACKEND_URL}/academy/course?courseId=${id}`)
    .then(result => {
      this.loading = false
    })
  }

  removeLesson = index => {
    this.lessons.splice(index, 1)
  }

  removeVideo = (index) => {
    this.lessons[index].video.playerEmbedUrl = ''
    this.lessons[index].video.image = ''
    this.lessons[index].videoId = ''
  }

  setLessonName = (index, name) => {
    this.lessons[index].video.videoName = name
  }

  setLessonDescription = (index, description) => {
    this.lessons[index].video.description = description
  }

  getCourses = async (publishedOnly = false) => {
    this.loading = true
    return axios.get(`${WEBAPP_BACKEND_URL}/academy/courses?includeInactive=${publishedOnly ? 'false' : 'true'}`)
    .then(result => {
      this.courses = result.data.data
      this.loading = false
    })
  }

  saveCourse = async () => {
    const data = {
      courseName: this.courseName,
      description: this.description,
      lessons: toJS(this.lessons)
    }
    if (data.lessons.length === 0) {
      NotificationManager.error('You must add at least one lesson to save.')
      throw new Error()
    }

    // backend will error if scenarios list is empty, we only need one so ui only supports one right now
    for (let i = 0; i < data.lessons.length; i++) {
      if (!data.lessons[i].scenarios) data.lessons[i].scenarios = []
    }

    return axios.post(`${WEBAPP_BACKEND_URL}/academy/course`, data)
    .catch(e => {
      console.log(`error %O`, e)
      if (e?.response) {
        NotificationManager.error(e.response.data.error)
      } else {
        NotificationManager.error('There was a problem saving.')
      }
      throw new Error()
    })
  }

  editCourse = async (courseId) => {
    const data = {
      courseName: this.courseName,
      description: this.description,
      lessons: toJS(this.lessons)
    }

    if (data.lessons.length === 0) {
      NotificationManager.error('You must add at least one lesson to save.')
      throw new Error()
    }

    for (let i = 0; i < data.lessons.length; i++) {
      if (!data.lessons[i].scenarios) data.lessons[i].scenarios = []
    }

    return axios.put(`${WEBAPP_BACKEND_URL}/academy/course?courseId=${courseId}`, data)
    .catch(e => {
      NotificationManager.error('There was a problem saving.')
      throw new Error()
    })
  }

  setCourseActive = async (courseId, published) => {
    const data = {
      active: published
    }
    return axios.put(`${WEBAPP_BACKEND_URL}/academy/course/active-status?courseId=${courseId}`, data)
  }

  clearCourse = () => {
    this.courseName = ''
    this.description = ''
    this.lessons = []
  }


  addLesson = () => {
    this.lessons.push({
      [tempIdSymbol]: tempId++,
      video: {
        videoId: '',
        videoName: '',
        description: '',
        duration: '',
        playerEmbedUrl: '',
        image: ''
      },
      scenarios: null
    })
  }

  setCourseName = name => {
    this.courseName = name
  }

  setCourseDescription = description => {
    this.description = description
  }

  setVideoId = (index, id) => {
    this.lessons[index].videoId = id
  }

  setLessonInfo = (index, info) => {
    this.lessons[index].videoId = info.videoId
    this.lessons[index].video.videoId = info.videoId
    this.lessons[index].video.videoName = info.lessonName
    this.lessons[index].video.description = info.description ? info.description : 'No description'
    this.lessons[index].video.duration = info.duration
    this.lessons[index].video.playerEmbedUrl = info.playerEmbedUrl
    this.lessons[index].video.image = info.image
  }

  setScenarioInfo = (lessonIndex, scenarioIndex, info) => {
    this.lessons[lessonIndex].scenarios = []
    this.lessons[lessonIndex].scenarios[scenarioIndex] = {
      scenarioName: info.name,
      leaderboardId: info.value
    }
  }

  getVideoInfo = id => {
    return axios.get(`${WEBAPP_BACKEND_URL}/academy/video/vimeo?videoId=${id}`)

    return fetch(`https://api.vimeo.com/videos/${id}`, {
      method: 'GET',
      mode: 'cors',
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': bearerToken,
        'Accept': `	application/vnd.vimeo.*+json;version=3.4`
      }
    })
    .catch(this.handleError)
    .then(result => {
      return result.json().then(data => data)
    })
  }

}