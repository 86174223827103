import styled from 'styled-components/macro'

export const DropdownContainer = styled.div`
  display: flex;
  align-items: center;
  user-select: none;
  position: relative;
  flex: 1;
  min-width: 100px;
  color: #e0e0e0;
  background: #322e38;
  border: none;
  border-radius: 8px;
  box-shadow: 0px -2px 4px 0px inset #a5a5a511, 0px 0px 4px 0px inset #7574a110;
  transition: 200ms linear filter;
  cursor: pointer;
  height: 38px;
  overflow: hidden;

  &.disabled {
    cursor: not-allowed;
    color: #7d7e8f;
    filter: opacity(0.25);

    &.selected-item {
      color: #7d7e8f;
    }
  }

  &.narrow {
    .dropdown-icon {
      display: none;
    }
    min-width: 0px;
    
  }

  .selected-name {
    padding: 0px 0px 0px 8px;
    white-space: nowrap;
    overflow: hidden;
    width: 75%;
    font-weight: 500;
    color: #aeaab9;
  }

  &.open {
    filter: brightness(1.2);
  }

  .search-container {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;

    input, .input-button {
      border-radius: 8px;
      height: 42px;
      background: transparent;
      flex: 1;
      padding: 0px 8px 0px 12px;
      font-size: 14px;
      /* color: #e0e0e0; */
      color: #aeaab9;
      margin-right: 8px;
      overflow: hidden;
      display: flex;
      align-items: center;
    }
  }

  .dropdown-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    color: #bbbbbb;
  }

  .dropdown-icon {
    justify-content: center;
    align-items: center;
    display: flex;
    height: 100%;
  }

  .button-container {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    font-size: 15px;
  }

  input {
    display: flex;
    max-width: 100%;
    background: transparent;
    border: none;
  }

  &:hover:not(.search) {
    filter: brightness(1.2);
  }

  &.search {
    background: #322e38;
    padding-left: 0px;

    &.open {
      box-shadow: 0px -2px 3px 0px inset #00000012, 0px 0px 3px 0px inset #00000054;
    }

    &:hover {
      .search-container {
        filter: brightness(1.2);
      }
    }

    .dropdown-icon {
      transition: 200ms linear filter;
      padding: 4px;
      height: 36px;

      &:hover {
        filter: brightness(1.2);
      }
    }
  }
`

export const DropdownOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 999;
  background: transparent;
  pointer-events: none;
  opacity: 0;
  transition: 400ms linear opacity;
`

export const DropdownListContainer = styled.div`
  border-radius: 8px;
  overflow: hidden;
  background: #404040;
  position: absolute;
  z-index: 2000;
  width: auto;
  display: flex;
  flex-direction: column;
  box-shadow: 0px -2px 4px 0px inset #a5a5a511, 0px 0px 4px 0px inset #7574a110;
  filter: brightness(1.2);
  transition: 120ms linear height, 120ms linear opacity;

  ul {
    width: auto;
    min-width: 100%;
    border-radius: 8px;
    max-height: 300px;
    overflow: auto;
    margin: 0px;
    padding: 0px;

    &.virtualize {
      overflow: hidden;
      max-height: unset;
    }

    li {
      list-style: none;
      background: #28242b;
      padding: 8px 16px 8px 16px;
      transition: 120ms linear filter;
      display: flex;
      justify-content: flex-end;
      cursor: pointer;
      color: #aeaab9;
      font-size: 15px;
      position: relative;
      align-items: center;

      &.left-align {
        justify-content: flex-start;
      }

      &.align-left {
        justify-content: flex-start;
      }

      &.align-right {
        justify-content: flex-end;
      }

      &.no-results {
        cursor: default;
      }

      &.results:hover, .selected {
        filter: brightness(1.2);
      }
    }
  }

  .list-spacer {
    height: 8px;
    background: #28242b;
    margin: 0px;
    padding: 0px;
  }

  .list-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 1200;
    border-radius: 8px;
    box-shadow: 0px 0px 2px 0px inset #8181816b;
  }
`