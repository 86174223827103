import { makeAutoObservable } from 'mobx';
import { setAuthToken } from '../utils'

export class LeaderboardStore {
  scenariosLoading = false
  scoresLoading = false

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  setScenariosLoading(loading) {
    this.scenariosLoading = loading
  }

  setScoresLoading(loading) {
    this.scoresLoading = loading
  }

}