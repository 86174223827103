const domain = window.document.domain


let overrideEnvironment = null

const getEnvironmentFromDomain = domain => {
  if (domain === 'localhost') {
    if (overrideEnvironment) return overrideEnvironment
    return process.env.REACT_APP_NODE_CONFIG_ENV || 'dev'
  } else if (domain === 'dev.kovaaks.com' || domain === 'www.dev.kovaaks.com') {
    return 'dev'
  } else if (domain === 'stage.kovaaks.com' || domain === 'www.stage.kovaaks.com') {
    return 'stage'
  } else if (domain === 'kovaaks.com' || domain === 'www.kovaaks.com') {
    return 'prod'
  }
  return 'dev'
}

const NODE_CONFIG_ENV = getEnvironmentFromDomain(domain)

const config = {
  local: {
    backend_domain: 'http://0.0.0.0:4000',
    auth_domain: 'http://0.0.0.0:4007',
    webapp_backend_domain: 'http://0.0.0.0:4010',
    kovaaks_public_bucket_name: 'kovaaks_public_dev'
  },
  dev: {
    backend_domain: 'https://dev.kovaaks.com',
    auth_domain: 'https://dev.kovaaks.com',
    webapp_backend_domain: 'https://dev.kovaaks.com',
    auth2_domain: 'https://auth.dev.kovaaks.com',
    kovaaks_public_bucket_name: 'kovaaks_public_dev',
    services_domain: 'https://dev-themeta.com',
    gtag: 'G-75269PDLBX'
  },
  stage: {
    backend_domain: 'https://stage.kovaaks.com',
    auth_domain: 'https://stage.kovaaks.com',
    auth2_domain: 'https://auth.stage.kovaaks.com',
    webapp_backend_domain: 'https://stage.kovaaks.com',
    services_domain: 'https://stage-themeta.com',
    kovaaks_public_bucket_name: 'kovaaks_public_stage'
  },
  prod: {
    backend_domain: 'https://kovaaks.com',
    auth_domain: 'https://kovaaks.com',
    auth2_domain: 'https://auth.kovaaks.com',
    webapp_backend_domain: 'https://kovaaks.com',
    services_domain: 'https://game-themeta.com',
    kovaaks_public_bucket_name: 'kovaaks_public',
    gtag: ''
  },
}

const AUTH_DOMAIN = config[NODE_CONFIG_ENV].auth_domain;
export const AUTH2_DOMAIN = config[NODE_CONFIG_ENV].auth2_domain;
const BACKEND_DOMAIN = config[NODE_CONFIG_ENV].backend_domain;
const WEBAPP_BACKEND_DOMAIN = config[NODE_CONFIG_ENV].webapp_backend_domain;
const SERVICES_DOMAIN = config[NODE_CONFIG_ENV].services_domain;
const KOVAAKS_PUBLIC_BUCKET_NAME = config[NODE_CONFIG_ENV].kovaaks_public_bucket_name;

export const GTAG = config[NODE_CONFIG_ENV].gtag

export const AUTH_URL = `${AUTH_DOMAIN}/auth`;
export const BACKEND_URL = `${BACKEND_DOMAIN}/backend`;
export const WEBAPP_BACKEND_URL = `${WEBAPP_BACKEND_DOMAIN}/webapp-backend`;
export const SERVICES_URL = `${SERVICES_DOMAIN}`;
export const KOVAAKS_PUBLIC_BUCKET_DOMAIN = `https://storage.googleapis.com/${KOVAAKS_PUBLIC_BUCKET_NAME}`

export const isMocked = false
// temporarily disabled because of auth issues
export const logoutOnExpiredToken = true

