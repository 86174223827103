export const lazyFontLoader = (function () {
	let headEl = null

	function initialize() {
		headEl = document.getElementsByTagName('head')[0];

		const preconnectEl = document.createElement('link')
		preconnectEl.rel = 'preconnect'
		preconnectEl.href = 'https://fonts.googleapis.com'
		headEl.appendChild(preconnectEl)
	}

	function loadFont(url) {
		initialize()

		// the 'fetch' equivalent has caching issues
		const xhr = new XMLHttpRequest();
		xhr.open('GET', url, true);
		// xhr.setRequestHeader('Access-Control-Allow-Origin', '*')
		xhr.onreadystatechange = () => {
			if (xhr.readyState == 4 && xhr.status == 200) {
				let css = xhr.responseText;
				css = css.replace(/}/g, 'font-display: swap; }');

				const style = document.createElement('style');
				const el = document.createTextNode(css)
				style.appendChild(el);
				headEl.appendChild(style);
			}
		};
		xhr.send();
	}

	return { loadFont }
})()