import React, { useState, useRef, useEffect, forwardRef } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { useSelect } from 'downshift'
import Fuse from 'fuse.js'
import { useResponsive } from '../hooks/useResponsive'
import { useAnchor } from '../hooks/useAnchor'
import { SearchDropdownButton } from './dropdown/SearchDropdownButton'
import { DropdownButton } from './dropdown/DropdownButton'
import { DropdownList } from './dropdown/DropdownList'
import { VirtualizedDropdownList } from './dropdown/VirtualizedDropdownList'
import { DropdownContainer, DropdownListContainer, DropdownOverlay } from './dropdown/dropdownStyles'
import { searchOptions, getDisplayList } from './dropdown/dropdownParts'

/*
const deferModule = name => {
  let moduleLoaded
  let promise = new Promise((resolve, reject) => {
    moduleLoaded = resolve
  })

  import(name).then(res => {
    moduleLoaded(res.default)
  })
  return promise
}

let Fuse
*/

const stateReducer = (state, action) => {
  switch(action.type) {
    case useSelect.stateChangeTypes.ItemMouseMove:
      return state
      break;
    case useSelect.stateChangeTypes.MenuMouseLeave:
      return {
        ...state,
        isOpen: false
      }
    case useSelect.stateChangeTypes.ItemClick:
      return {
        ...state,
        selectedItem: action.changes.selectedItem,
        isOpen: false
      }
    case useSelect.stateChangeTypes.ToggleButtonBlur:
      return state
    case useSelect.stateChangeTypes.ToggleButtonClick:
      return {
        ...state,
        isOpen: !state.isOpen
      }
    case useSelect.stateChangeTypes.FunctionToggleMenu:
      return {
        ...state,
        isOpen: !state.isOpen
      }
      break;
    default:
      return action
  }
}

export const Dropdown = forwardRef((props, ref) => {
  const { options, onChange, variant, placeholder, style,
    disabled, value, virtualize, hideHandle, narrow = false } = props

  if (!options) {
    console.log(`missing options %O`, props)
    return null
  }

  // if (variant === 'search' && !Fuse) {
  //   import('fuse.js').then(res => Fuse = res.default)
  // }

  const fuseRef = useRef()
  const inputRef = useRef()
  const [searchValue, setSearchValue] = useState('')
  const [searchResults, setSearchResults] = useState(null)
  const [highlightedIndex, setHighlightedIndex] = useState(0)

  const breakpoint = useResponsive()

  useEffect(() => {
    fuseRef.current = new Fuse(options, searchOptions)
  }, [options])

  const [anchor, anchorPos] = useAnchor()
  let dropdownElement = document.getElementById('dropdown-container')

  const handleFocus = () => {
    if (props.onFocus) props.onFocus()
  }

  const handleBlur = () => {
    setHighlightedIndex(0)
    if (props.onBlur) props.onBlur()
  }

  useEffect(() => {
    if (inputRef.current) inputRef.current.focus()
  })

  // TODO: debounce changes
  const displayList = getDisplayList(searchValue, searchResults, variant, options)

  const {
    isOpen, getLabelProps,
    getToggleButtonProps, getMenuProps,
    getItemProps, selectedValues, toggleMenu,
    selectItem, selectedItem
  } = useSelect({
    items: displayList,
    highlightedIndex,
    stateReducer,
    // initialSelectedItem: options.find(i => i.value === value),
    initialSelectedItem: options ? options.find(i => i.value === value) : [],
    onSelectedItemChange: (item) => {
      onChange(item.selectedItem)
      setSearchValue('')
      if (props.onBlur) props.onBlur()
    }
  })

  useEffect(() => {
    if (!selectedItem) return
    // console.log(`selectedItem ${JSON.stringify(selectedItem, null, 2)}`)
    try {
      if (value !== selectedItem.value) {
        const newItem = options.find(i => i.value === value)
        selectItem(newItem)
      }
    } catch (e) {
      // console.log(`selectedItem value %O, item %O`, value, selectedItem)
      throw new Error('stopped')
    }
  }, [value])


  const handleOpen = e => {
    if (e.target && e.target.id.indexOf('item') !== -1) {
      return
    }
    toggleMenu()
  }

  // stops dropdown closing when scrollbar is interacted
  const handleClickOverlay = e => {
    e.preventDefault();
    e.stopPropagation()
  }

  const handleSearchInput = e => {
    if (fuseRef.current) {
      const results = fuseRef.current.search(e.target.value)
      .map(i => {
        return {
          ...i.item,
          score: i.score
        }
      })
      setSearchValue(e.target.value)
      setSearchResults(results)
    }
  }

  return (
    <DropdownContainer
      className={`${isOpen ? 'open' : ''} ${disabled ? 'disabled' : ''} ${variant === 'search' ? 'search' : ''} ${hideHandle || narrow ? 'narrow' : ''}`}
      style={style}
      onMouseDown={handleOpen}
    >
      <div className="dropdown-container">
        {variant === 'search' ? (
          <SearchDropdownButton
            anchor={anchor}
            handleFocus={handleFocus}
            selectedItem={selectedItem}
            placeholder={placeholder}
            highlightedIndex={highlightedIndex}
            setSelectedItem={selectItem}
            setHighlightedIndex={setHighlightedIndex}
            setSearchValue={setSearchValue}
            searchValue={searchValue}
            isOpen={isOpen}
            displayList={displayList}
            handleSearchInput={handleSearchInput}
            handleBlur={handleBlur}
            inputRef={inputRef}
            getToggleButtonProps={getToggleButtonProps}
            onChange={onChange}
            {...props}
          />
        ) : (
          <DropdownButton
            anchor={anchor}
            selectedItem={selectedItem}
            placeholder={placeholder}
            handleFocus={handleFocus}
            getToggleButtonProps={getToggleButtonProps}
            isOpen={isOpen}
            {...props}
          />
        )}
      </div>
      {!isOpen ? (
        <>
          <div {...getMenuProps()}></div>
          <DropdownOverlay style={{ pointerEvents: 'none', }} />
        </>
      ) : null}
      {(isOpen && anchorPos) ? ReactDOM.createPortal((
        <>
          <DropdownOverlay style={{ opacity: 1, pointerEvents: 'auto' }} />
          <DropdownListContainer
            {...getMenuProps()}
            className={`screen-${breakpoint}`}
            style={{
              minWidth: (anchorPos.right - anchorPos.left),
              left: breakpoint !== 'xs' ? anchorPos.x : 8,
              right: breakpoint !== 'xs' ? null : 8,
              top: anchorPos.bottom + ((variant === 'search') ? -2 : 4)
            }}
            onMouseDown={handleClickOverlay}
          >
            <div className="list-overlay"></div>
            {!virtualize ?
              <DropdownList
                selectedItem={selectedItem}
                highlightedIndex={highlightedIndex}
                displayList={displayList}
                getItemProps={getItemProps}
                {...props}
              /> : null
            }
            {virtualize ?
              <VirtualizedDropdownList
                ref={ref}
                selectedItem={selectedItem}
                highlightedIndex={highlightedIndex}
                displayList={displayList}
                getItemProps={getItemProps}
                searchValue={searchValue}
                {...props}
              /> : null}
          </DropdownListContainer>
        </>
      ), dropdownElement) : null}
    </DropdownContainer>
  )
})

// TODO: create default ItemComponent and set in defaultProps


Dropdown.propTypes = {
  align: PropTypes.string,
  ItemComponent: PropTypes.elementType,
  virtualize: PropTypes.bool,
  itemHeight: PropTypes.number,
  maxHeight: PropTypes.number
}

Dropdown.defaultProps = {
  align: 'left',
  ItemComponent: null,
  virtualize: false,
  itemHeight: 34,
  maxHeight: 300 // do not change, ul in dropdownStyles needs to use css vars
}