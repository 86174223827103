import React, { useEffect, useState, useRef, forwardRef } from 'react';
import styled from 'styled-components/macro'
import countryList from 'country-list'
import { WEBAPP_BACKEND_URL, KOVAAKS_PUBLIC_BUCKET_DOMAIN } from '../../constants';

const ScenarioItemContainer = styled.li`
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: min-content;

  &.selected {
    filter: brightness(1.2);
  }
  .name {
    padding-left: 8px;
  }
`

export const ScenarioItem = forwardRef((props, ref) => {
  const { item, index, ...rest } = props
  if (!item) return null
  return (
    <ScenarioItemContainer
      key={item.scenarioName}
      {...rest}
    >
      <div className="name">{item.name ? item.name : item.scenarioName}</div>
    </ScenarioItemContainer>
  )
})

