import React from 'react';

import { useDebounce, usePaginatedRequest } from '../../utils';
import { Playlist } from '../models';

const PlaylistContext = React.createContext({});

export function PlaylistProvider({ children }) {
	const [isPlaylistLoading, setLoading] = React.useState(false);
	const [playlistApiError, setApiError] = React.useState(null);
	const [allPlaylists, setAllPlaylists] = React.useState(null);
	const [searchResults, setSearchResults] = React.useState([])
	const [allStaffPickPlaylists, setAllStaffPickPlaylists] = React.useState(null);
	const [subscribedPlaylists, setSubscribedPlaylists] = React.useState(null);
	const [metaPlaylists, setMetaPlaylists] = React.useState([]);
	const [creatorPlaylistDetails, setCreatorPlaylistDetails] = React.useState(null);

  const popularPlaylists = usePaginatedRequest(Playlist.getPopularPlaylists, 20)
  const searchPlaylists = usePaginatedRequest(Playlist.getAllPlaylists, 20);
  const trendingPlaylists = usePaginatedRequest(Playlist.getTrendingPlaylists)

	async function createStaffPickPlaylist({
		playlistId,
		playlistName,
		description,
		image
	}) {
		setLoading(true);
		const response = await Playlist.createStaffPickPlaylist({
			playlistId,
			playlistName,
			description,
			image
		});
		setLoading(false);
		const { message } = response;
		setApiError(message);
	}

	const getByName = useDebounce(async (playlistName) => {
		setLoading(true);
		const response = await Playlist.getByName();
		setLoading(false);
		const { status, data, message } = response;
		if (status == 200) {
			setAllPlaylists(data?.data || []);
		} else {
			setApiError(message);
		}
	});

	async function getAllPlaylists(search, subscribed = false) {
		setLoading(true);
		const response = await Playlist.getAllPlaylists(search, subscribed);
		setLoading(false);
		const { status, data, message } = response;
		if (status == 200) {
			setAllPlaylists(data?.data || []);
		} else {
			setApiError(message);
		}
	}

	async function getAllStaffPickPlaylists() {
		setLoading(true);
		const response = await Playlist.getAllStaffPickPlaylists();
		setLoading(false);
		const { status, data, message } = response;
		if (status == 200) {
			setAllStaffPickPlaylists(data?.data || []);
		} else {
			setApiError(message);
		}
	}

	async function getSubscribedPlaylists() {
		setLoading(true);
		const response = await Playlist.getSubscribedPlaylists();
		setLoading(false);
		const { status, data, message } = response;
		if (status == 200) {
			setSubscribedPlaylists(data?.data || []);
		} else {
			setApiError(message);
		}
	}

	async function deleteStaffPickPlaylistById(idx) {
		setLoading(true);
		const response = await Playlist.deleteStaffPickPlaylistById(idx);
		setLoading(false);
		const { status, data, message } = response;
		setApiError(status == 200 ? data : message);
	}

	async function sortStaffPicksPlaylists(ids) {
		setLoading(true);
		const response = await Playlist.sortStaffPicksPlaylists(ids);
		setLoading(false);
		const { status, data, message } = response;
		setApiError(status == 200 ? data : message);
	}

	async function getMetaPlaylists() {
		const response = await Playlist.getMetaPlaylists();
		const { status, data } = response;
		if (status == 200) {
			setMetaPlaylists(data?.data || []);
		}
	}

	async function getMetaPlaylistsWithoutLimit() {
		const response = await Playlist.getMetaPlaylists();
		const { status, data } = response;
		if (status == 200) {
			setMetaPlaylists(data?.data || []);
		}
	}

	async function resetPlaylist() {
		setApiError(null);
	}

	async function getCreatorPlaylistDetails(playlistId) {
		const response = await Playlist.getCreatorScenarioDetails(playlistId);
		const { status, data } = response;
		if (status == 200) {
			setCreatorPlaylistDetails(data);
		}
	}

	const memoedValue = React.useMemo(
		() => ({
			isPlaylistLoading,
			playlistApiError,
			allPlaylists,
			subscribedPlaylists,
			allStaffPickPlaylists,
			metaPlaylists,
			creatorPlaylistDetails,
      trendingPlaylists,
			getAllPlaylists,
			resetPlaylist,
			getSubscribedPlaylists,
			createStaffPickPlaylist,
			getAllStaffPickPlaylists,
			deleteStaffPickPlaylistById,
			sortStaffPicksPlaylists,
			getMetaPlaylists,
			getMetaPlaylistsWithoutLimit,
			getCreatorPlaylistDetails,
			popularPlaylists,
			getByName,
			searchResults,
      searchPlaylists
		}),
		[
			isPlaylistLoading,
			playlistApiError,
			allPlaylists,
			subscribedPlaylists,
			allStaffPickPlaylists,
			metaPlaylists,
			creatorPlaylistDetails,
			popularPlaylists,
      searchPlaylists,
      trendingPlaylists
		]
	);

	return (
		<PlaylistContext.Provider value={memoedValue}>
			{children}
		</PlaylistContext.Provider>
	);
}

export function usePlaylist() {
	return React.useContext(PlaylistContext);
}
