import { isMocked } from '../constants'

export function onSuccess(type, response = undefined) {
	return { type: type, response: response };
}

export function onFailure(type, error) {
	return { type: type, error: error };
}

function checkAuth() {
  if (isMocked) return true
  if (localStorage.jwtToken) {
    return true;
  } else {
    return false;
  }
}

export const handleApiRequest = (method, needsAuth, authRequired = false) => {
  return new Promise((resolve, reject) => {
    const handleResponse = response => {
      let responseObj = response
      // we shouldn't need this but we get back inconsistent structures from back end
      if (response.response) response = response.response
      if (response.status == 200) {
        resolve(response)
      } else {
        if (authRequired && (response.status == 401 || response.status == 403)) {
          console.log(`logging out because of %O`, response)
          reject({ logout: true })
        } else {
          reject(response)
        }
      }
    }

    if (needsAuth && authRequired && !checkAuth()) {
      console.trace(`logging out because of no token`)
      reject({ logout: true })
    }
    method().then(response => {
      if (response.status === undefined && response.response) {
        handleResponse(response.response)
      } else {
        handleResponse(response)
      }
    }).catch(handleResponse)
  })
}