import React, { useState, useEffect } from 'react';
import './styles.scss';

import { Header, Sidebar } from '../../components';

export default function ResourcesScene() {
	const [header, setHeader] = useState(2);

	function handleHeader(selected) {
		setHeader(selected);
	}

	return (
		<>
			<div className="ui-resources">
        <Header
          onHandleHeader={(selected) => handleHeader(selected)}
        />
				<div className="ui-resources-container">
					<Sidebar />
					<div className="ui-resources-container-content">
						Welcome to Resources Page
					</div>
				</div>
			</div>
		</>
	);
}
