import React, { useEffect, useState, useRef, forwardRef } from 'react';
import styled from 'styled-components/macro'
import defaultAvatar from '../../assets/images/defaultAvatarOptimized.png'

const UserItemContainer = styled.li`
  display: flex;
  align-items: center;

  &.selected {
    filter: brightness(1.2);
  }

  .avatar {
    width: 28px;
    height: 28px;
    position: relative;

    img {
      width: 28px;
      height: 28px;
    }
  }

  .name {
    padding-left: 8px;
  }
`

const Avatar = props => {
  const { image } = props
  return (
    <div className="avatar">
      <img src={image} />
    </div>
  )
}

export const UserItem = forwardRef((props, ref) => {
  const { item, ...rest } = props
  if (!item) return null
  return (
    <UserItemContainer
      key={item.value}
      {...rest}
    >
      <Avatar image={item.image ? item.image : defaultAvatar} />
      <div className="name">{item.name}</div>
    </UserItemContainer>
  )
})

