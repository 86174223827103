import React, { useState, useEffect } from 'react';
import './styles.scss';
import { NotificationManager } from 'react-notifications';
import { useScenario } from '../../core';
import { ImagePicker } from '../file-picker'
import { ScenarioItem } from '../list-items/ScenarioItem'
import { Search } from '../Search';

const scenarioToOption = scenario => {
  if (!scenario) return null
  if (scenario && scenario.leaderboardId === '') return null
  return {
    value: scenario.leaderboardId,
    name: scenario.scenarioName
  }
}

export function AdminDayScenario() {
  const {
    scenarioApiError,
    updateFeaturedScenario,
    resetScenario,
    popularScenarios
  } = useScenario();

  const [scenarioImage, setScenarioImage] = useState(null);
  const [scenarioName, setScenarioName] = useState(null);
  const [scenarioDesc, setScenarioDesc] = useState('');
  const [selectedItem, setSelectedItem] = useState()
  const [leaderboardId, setLeaderboardId] = useState(null);
  const [b_submit, setSubmit] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      leaderboardId == null ||
      scenarioImage == null ||
      scenarioName == '' ||
      scenarioDesc == ''
    ) {
      NotificationManager.error(
        'Please enter information',
        'Error',
        1000,
        () => {}
      );
      return;
    }

    updateFeaturedScenario({
      leaderboardId,
      description: scenarioDesc,
      image: scenarioImage
    }).then(() => {
      NotificationManager.success(
        'Successfully saved Featured Scenario!',
        ''
      );
    })
	};

  useEffect(() => {
		if (scenarioApiError != null) {
			NotificationManager.success(scenarioApiError, '');
			resetScenario();
		}
	}, [scenarioApiError]);

	useEffect(() => {
		console.log(leaderboardId, scenarioName, scenarioDesc, scenarioImage);
		if (
			leaderboardId == null ||
			scenarioName == null ||
			scenarioDesc == '' ||
			scenarioImage == null
		) {
			setSubmit(false);
		} else {
			setSubmit(true);
		}
	}, [leaderboardId, scenarioName, scenarioDesc, scenarioImage]);

	const handleOnSelect = (searchScenario) => {
		if (searchScenario) {
      setSelectedItem(searchScenario)
			setLeaderboardId(searchScenario.leaderboardId);
			setScenarioName(searchScenario.scenarioName);
		}
	}

  const [scenarioSearch, setScenarioSearch] = useState()

	return (
		<>
			<div className="admin-day-scenario">
				<p className="admin-day-scenario-title">Featured Scenario</p>
				<br />
				<div className="admin-day-scenario-form">
          <div style={{ display: 'flex', justifyContent: 'space-between', padding: '16px 8px' }}>
					  <div className="hd-label">Scenario name</div>
            <div style={{ color: '#e0e0e0' }}></div>
          </div>
          <Search
            value={scenarioSearch}
            persistent
            dropdown
            ItemComponent={ScenarioItem}
            selectedItem={scenarioToOption(selectedItem)}
            close
            query={scenarioName => {
              return popularScenarios.newQuery(scenarioName)
            }}
            placeholder="Search scenarios..."
            onChange={e => setScenarioSearch(e.value)}
            onCommit={e => {
              console.log(`selected %O`, e)
              handleOnSelect({
                leaderboardId: e.leaderboardId,
                scenarioName: e.scenarioName
              })
              setSubmit(true)
            }}
          />
					<br />
					<br />
					<div className="admin-day-scenario-form-desc">
						<div className="hd-label" style={{ marginBottom: 16 }}>Description</div>
						<textarea
							id="desc"
							className="admin-day-scenario-form-desc-textarea"
							type="text"
							rows={10}
							required
							value={scenarioDesc}
							onChange={(e) => setScenarioDesc(e.target.value)}
						>
							{scenarioDesc}
						</textarea>
					</div>

					<br />
					<br />

					{/* <label>Date Range</label>
					<input
						className="admin-day-scenario-form-input"
						type="text"
					/>
					<br />
					<br /> */}

					<div className="admin-day-scenario-form-image-picker">
						<div className="hd-label">Image</div>
						<ImagePicker
							className="form-item"
							placeholder="No file"
							value={scenarioImage}
							onSelect={(value) => setScenarioImage(value)}
						/>
					</div>

					<br />
					<br />
					<button
						className={
							b_submit
								? 'admin-day-scenario-form-submit'
								: 'admin-day-scenario-form-sumbit_disable'
						}
						disabled={!b_submit}
						onClick={handleSubmit}
					>
						SUBMIT
					</button>
				</div>
			</div>
		</>
	);
}
