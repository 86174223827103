import React from 'react'
import { DropdownDownIcon } from '../icons/DropdownDown'
import { DropdownUpIcon } from '../icons/DropdownUp'

const displaySelected = props => {
  const { selectedItem, ItemComponent, searchValue, placeholder } = props
  if (selectedItem) {
    if (ItemComponent) {
      return <ItemComponent item={selectedItem} />
    } else {
      return selectedItem.name
    }
  } else {
    return searchValue ? searchValue : placeholder
  }
}

export const SearchDropdownButton = props => {
  const {
    anchor, handleFocus, ItemComponent, selectedItem, searchValue, isOpen,
    displayList, highlightedIndex, handleSearchInput, handleBlur,
    getToggleButtonProps, inputRef, setSelectedItem, setHighlightedIndex,
    setSearchValue, onChange, placeholder
  } = props

  return (
    <div className="search-container" ref={anchor}>
      {!isOpen ?
        <div className="input-button" onClick={handleFocus}>
          {displaySelected(props)}
        </div> :
        <input
          ref={inputRef}
          autoComplete="none"
          value={isOpen ? searchValue : selectedItem ? selectedItem.name : searchValue}
          onKeyDown={e => {
            e.stopPropagation()
            if (e.keyCode === 13) {
              e.preventDefault()
              if (displayList.length > 0) {
                setSelectedItem(displayList[highlightedIndex])
                onChange(displayList[highlightedIndex])
              }
              setSearchValue('')
            } else if (e.keyCode === 27) {
              e.preventDefault()
              handleBlur()
            } else if (e.keyCode === 38) {
              e.preventDefault()
              setHighlightedIndex(prev => Math.max(0, prev - 1))
            } else if (e.keyCode === 40) {
              e.preventDefault()
              setHighlightedIndex(prev => prev + 1)
            }
          }}
          onChange={handleSearchInput}
          onFocus={handleFocus}
          onClick={handleFocus}
          onBlur={handleBlur}
        />
      }
      <div
        className='dropdown-icon'
        onMouseDown={handleFocus}
        {...getToggleButtonProps()}
      >
        {isOpen ? <><DropdownUpIcon size={32} /></> : <><DropdownDownIcon onClick={handleFocus} size={32} /></>}
      </div>
    </div>
  )
}