import React, { useState, useEffect } from 'react';
import {
	useLocation,
	useNavigate,
	Link,
	useSearchParams,
	NavLink
} from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import './styles.scss';

import iconLeftArrow from '../../assets/images/icon_double_left_arrow.svg';
import iconTrendUp from '../../assets/images/icon_trend_up.svg';
import iconTrendUpActive from '../../assets/images/icon_trend_up_active.svg';

import iconMainInactive from '../../assets/images/icon_home_inactive.svg';
import iconMainActive from '../../assets/images/icon_home_active.svg';
import iconLeaderboardActive from '../../assets/images/icon_leaderboard_active.svg';
import iconLeaderboardInactive from '../../assets/images/icon_leaderboard_inactive.svg';

import iconProfile from '../../assets/images/icon_profile.svg';
import iconProfileActive from '../../assets/images/icon_profile_active.svg';

import iconScenario from '../../assets/images/icon_scenario.svg';
import iconScenarioActive from '../../assets/images/icon_scenario_active.svg';

import iconPlaylist from '../../assets/images/icon_playlist.svg';
import iconPlaylistActive from '../../assets/images/icon_playlist_active.svg';

import iconCreate from '../../assets/images/icon_create.svg';
import iconCreateActive from '../../assets/images/icon_create_active.svg';


import { TwitterIcon } from '../icons/TwitterIcon'
import { DiscordIcon } from '../icons/DiscordIcon'
import { YoutubeIcon } from '../icons/YoutubeIcon'
import { SteamIcon } from '../icons/SteamIcon'
import { PlaylistIcon } from '../icons/PlaylistIcon';
import { CalculatorIcon } from '../icons/CalculatorIcon';
import { GlobeIcon } from '../icons/GlobeIcon';

import iconTwitter from '../../assets/images/icon_twitter.svg';
import iconDiscord from '../../assets/images/discord.svg';
import iconYoutube from '../../assets/images/youtube_social.svg';
import iconRightArrow from '../../assets/images/right_double_arrow.svg';
import { Storage } from '../../utils';
import { useUser } from '../../core';
import { useRoot } from '../../core/rootStore';
import { useResponsive } from '../../hooks/useResponsive';
import { enablePlaylists, enableGlobalLeaderboard, enableCreator, enableBenchmarkTracker } from '../../features';

const classNames = require('classnames');

export const Sidebar = observer(() => {
  const location = useLocation();
  const navigate = useNavigate();
  const [active, setActive] = useState(1);

  const [scene, setScene] = useState(2);

  const root = useRoot();
  const { i18n } = root

  const breakpoint = useResponsive()

  const { currentUser, getProfileInfo } = useUser();
  const [username, setUsername] = useState(null);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (currentUser?.webapp?.username) {
      setUsername(currentUser?.webapp?.username);
    }
  }, [currentUser]);

  useEffect(() => {
    let path = location.pathname;
    if (path.includes('video-platform')) {
      if (scene !== 3) {
        setScene(3);
      }
    } else {
      if (scene !== 2) {
        setScene(2);
      }
    }
  }, [location]);

  const handleClickItem = (e) => {
    if (currentUser == null) {
      e.preventDefault();
    }
  };

  return (
    <>
      <div
        className="sidebar"
        style={root.sidebarClosed ? { minWidth: breakpoint !== 'sm' ? 84 : 64 } : {}}
      >
        <div
          className="sidebar-topitem"
          onClick={() => root.toggleSidebar()}
        >
          {root.sidebarClosed ? (
            <img src={iconRightArrow} alt="leftarrow" />
          ) : (
            <img src={iconLeftArrow} alt="leftarrow" />
          )}
        </div>
        {scene == 2 && (
          <div className="sidebar-menu">
            <NavLink
              className={classNames('sidebar-item', {
                collapsed: root.sidebarClosed
              })}
              alt="Home"
              aria-label="Home"
              to="/kovaaks/main"
            >
              <div className="icon">
                <img
                  className="inactive"
                  src={iconMainInactive}
                  alt=""
                />
                <img src={iconMainActive} alt="" />
              </div>
              {!root.sidebarClosed && (
                <span className={'sidebar-inactive-text'}>
                  { i18n.getString('common.main') }
                </span>
              )}
            </NavLink>
            <NavLink
              className={classNames('sidebar-item', {
                collapsed: root.sidebarClosed
              })}
              to="/kovaaks/scenarios"
              alt="Scenarios"
              aria-label="Scenarios"
            >
              <div className="icon">
                <img src={iconLeaderboardInactive} alt="" />
                <img src={iconLeaderboardActive} alt="" />
              </div>
              {!root.sidebarClosed && (
                <span
                  className={
                    active == 2
                      ? 'sidebar-active-text'
                      : 'sidebar-inactive-text'
                  }
                >
                  { i18n.getString('common.scenarios') }
                </span>
              )}
            </NavLink>
            {enablePlaylists ?
            <NavLink
              className={classNames('sidebar-item', {
                collapsed: root.sidebarClosed
              })}
              to="/kovaaks/playlists"
              alt="Playlists"
              aria-label="Playlists"
            >
              <div className="icon">
                <PlaylistIcon color="#808191" />
                <PlaylistIcon color="#e0e0e0" />
              </div>
              {!root.sidebarClosed && (
                <span
                  className={
                    active == 2
                      ? 'sidebar-active-text'
                      : 'sidebar-inactive-text'
                  }
                >
                  { i18n.getString('common.playlists') }
                </span>
              )}
            </NavLink> : null}
            <NavLink
              className={classNames('sidebar-item', {
                collapsed: root.sidebarClosed
              })}
              to="/kovaaks/sens-converter"
              alt="Sensitivity Converter"
              aria-label="Sensitivity Converter"
            >
              <div className="icon">
                <CalculatorIcon color="#808191" />
                <CalculatorIcon color="#e0e0e0" />
              </div>
              {!root.sidebarClosed && (
                <span
                  className={
                    active == 2
                      ? 'sidebar-active-text'
                      : 'sidebar-inactive-text'
                  }
                >
                  { i18n.getString('header.sensitivity_converter') }
                </span>
              )}
            </NavLink>
            {enableGlobalLeaderboard &&
              <NavLink
                className={classNames('sidebar-item', {
                  collapsed: root.sidebarClosed
                })}
                to="/kovaaks/leaderboards"
                alt="Leaderboards"
                aria-label="Leaderboards"
              >
                <div className="icon">
                  <GlobeIcon color="#808191" />
                  <GlobeIcon color="#e0e0e0" />
                </div>
                {!root.sidebarClosed && (
                  <span
                    className={
                      active == 2
                        ? 'sidebar-active-text'
                        : 'sidebar-inactive-text'
                    }
                  >
                    { i18n.getString('header.global_leaderboards') }
                  </span>
                )}
              </NavLink>
            }
            {enableBenchmarkTracker &&
              <NavLink
								className={classNames('sidebar-item', {'collapsed': root.sidebarClosed })}
								to="/kovaaks/benchmark-tracker"
                alt="Benchmark Tracker"
                aria-label="Benchmark Tracker"
							>
								<div className="icon">
									<img src={iconTrendUp} alt="" />
									<img src={iconTrendUpActive} alt="" />
								</div>
								{!root.sidebarClosed && (
									<span
										className={active == 3 ? 'sidebar-active-text' : 'sidebar-inactive-text'}
									>
                  { i18n.getString('header.benchmark_tracker') }
									</span>
								)}
							</NavLink>
            }
            {typeof username != 'undefined' && username && (
              <NavLink
                className={classNames('sidebar-item', {
                  collapsed: root.sidebarClosed
                })}
                to={
                  '/kovaaks/profile?username=' +
                  encodeURIComponent(username)
                }
                alt="My Profile"
                aria-label="My Profile"
              >
                <div className="icon">
                  <img src={iconProfile} alt="" />
                  <img src={iconProfileActive} alt="" />
                </div>
                {!root.sidebarClosed && (
                  <span
                    className={
                      active == 3
                        ? 'sidebar-active-text'
                        : 'sidebar-inactive-text'
                    }
                  >
                  { i18n.getString('header.my_profile') }
                  </span>
                )}
              </NavLink>
            )}
						{(typeof username != 'undefined' && username && enableCreator) ? (
							<NavLink
								className={classNames('sidebar-item', {'collapsed': root.sidebarClosed })}
								to="/kovaaks/benchmark-creator"
                alt="Creator Tools"
                aria-label="Creator Tools"
							>
								<div className="icon">
									<img src={iconCreate} alt="" />
									<img src={iconCreateActive} alt="" />
								</div>
								{!root.sidebarClosed && (
									<span
										className={active == 3 ? 'sidebar-active-text' : 'sidebar-inactive-text'}
									>
                  { i18n.getString('creator_tools.creator_tools') }
									</span>
								)}
							</NavLink>
						) : null}
            {/* disabling these pages for now, we'll work on them later */}
            {/* <NavLink
              className={classNames('sidebar-item', {'collapsed': root.sidebarClosed })}
              to="/kovaaks/scenarios"
            >
              <div className="icon">
                <img src={iconScenario} alt="" />
                <img src={iconScenarioActive} alt="" />
              </div>
              {!root.sidebarClosed && (
                <span className={active == 4 ? 'sidebar-active-text' : 'sidebar-inactive-text'}>
                  Scenarios
                </span>
              )}
            </NavLink> */}
          </div>
        )}

        {/* {scene == 3 && (
          <div className="sidebar-menu">
            <NavLink
              onClick={handleClickItem}
              className={classNames('sidebar-item', {
                collapsed: root.sidebarClosed
              })}
              to="/video-platform/main"
            >
              <div className="icon">
                <img src={iconMainInactive} alt="" />
                <img src={iconMainActive} alt="" />
              </div>
              {!root.sidebarClosed && (
                <span
                  className={
                    active == 1
                      ? 'sidebar-active-text'
                      : 'sidebar-inactive-text'
                  }
                >
                  Main
                </span>
              )}
            </NavLink>
            <NavLink
              onClick={handleClickItem}
              className={classNames('sidebar-item', {
                collapsed: root.sidebarClosed
              })}
              to="/video-platform/guides"
            >
              <div className="icon">
                <img src={iconLeaderboardInactive} alt="" />
                <img src={iconLeaderboardActive} alt="" />
              </div>
              {!root.sidebarClosed && (
                <span
                  className={
                    active == 2
                      ? 'sidebar-active-text'
                      : 'sidebar-inactive-text'
                  }
                >
                  Video Guides
                </span>
              )}
            </NavLink>
          </div>
        )} */}

        <div className={`sidebar-social`}>
          <div
            className={`sidebar-social-icon ${
              root.sidebarClosed ? 'collapsed' : ''
            }`}
            style={
              root.sidebarClosed ? { flexDirection: 'column' } : {}
            }
          >
            <a
              href="https://store.steampowered.com/app/824270/KovaaKs/"
              target="_blank"
              alt="Steam"
              title="Steam"
            >
              <SteamIcon
                size={20}
                className="sidebar-social-icon-item"
              />
            </a>
            <a
              href="http://discord.gg/KovaaK"
              target="_blank"
              alt="Discord"
              title="Discord"
            >
              <DiscordIcon
                size={20}
                className="sidebar-social-icon-item"
              />
            </a>
            <a
              href="https://www.youtube.com/c/TheMeta"
              target="_blank"
              alt="Youtube"
              title="Youtube"
            >
              <YoutubeIcon
                size={20}
                className="sidebar-social-icon-item"
              />
            </a>
            <a
              className="sidebar-social-icon-item"
              href="https://twitter.com/KovaaKs"
              alt="Twitter"
              title="Twitter"
            >
              <TwitterIcon
                size={20}
                className="sidebar-social-icon-item"
              />
            </a>
          </div>
          {!root.sidebarClosed && (
            <div className="sidebar-social-copyright">
              ALL RIGHTS RESERVED.
              <br />
              THEMETA INC.
            </div>
          )}
        </div>
      </div>
    </>
  );
  });
