import axios from 'axios';
import { WEBAPP_BACKEND_URL } from '../../constants';

export class Game {

	static async getAllGames() {
		try {
			return await axios.get(`${WEBAPP_BACKEND_URL}/game/all`);
		} catch (error) {
			console.log(error);
			return error;
		}
	}

	static async createGame(game) {
		try {
			const formData = new FormData();
			formData.append('name', game.name);
			formData.append('description', game.description);
			formData.append('image', game.image);
			formData.append('storagePath', 'game');

			return await axios.post(`${WEBAPP_BACKEND_URL}/game`, formData);
		} catch (error) {
			console.log(error);
			return error;
		}
	}

	static async createVideoGuidesGame(game) {
		try {
			const formData = new FormData();
			formData.append('name', game.name);
			formData.append('description', game.description);
			formData.append('image', game.image);

			return await axios.post( `${WEBAPP_BACKEND_URL}/video/game`, formData);
		} catch (error) {
			console.log(error);
			return error;
		}
	}

	static async deleteGameById(gameId) {
		try {
			const params = { gameId };
			return await axios.delete(`${WEBAPP_BACKEND_URL}/game`, { params });
		} catch (error) {
			console.log(error);
			return error;
		}
	}

	static async getAllVideoGuidesGames() {
		try {
			return await axios.get(`${WEBAPP_BACKEND_URL}/video/games`);
		} catch (error) {
			console.log(error);
			return error;
		}
	}

	static async getGamesForVideoGuide(id) {
		try {
			return await axios.get(`${WEBAPP_BACKEND_URL}/video/game`, { params: id });
		} catch (error) {
			console.log(error);
			return error;
		}
	}

	static async deleteVideoGuidesGameById(gameId) {
		try {
			const params = { gameId };
			return await axios.delete(`${WEBAPP_BACKEND_URL}/video/game`, { params });
		} catch (error) {
			console.log(error);
			return error;
		}
	}
}
