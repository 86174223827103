
import styled from 'styled-components/macro'

const CloseIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 200ms linear filter;

  &:hover {
    filter: brightness(1.2);
  }

`

export const CloseIcon = ({ size = 20, color, style, ...rest }) => {
  return (
    <CloseIconContainer style={style} {...rest}>
      <svg fill={color ? color : 'currentColor'} width={`${size}px`} height={`${size}px`} viewBox="200 200 800 800">
        <path d="m246 952.8c16.801 16.801 38.398 25.199 60 25.199s43.199-8.3984 60-25.199l234-234 234 234c16.801 16.801 38.398 25.199 60 25.199s43.199-8.3984 60-25.199c32.398-32.398 32.398-86.398 0-118.8l-235.2-234 235.2-235.2c32.398-32.398 32.398-86.398 0-118.8-32.398-32.398-86.398-32.398-118.8 0l-235.2 235.2-234-234c-32.398-32.398-86.398-32.398-118.8 0-32.398 32.398-32.398 86.398 0 118.8l234 234-235.2 235.2c-32.398 32.402-32.398 85.199 0 117.6z"/>
      </svg>
    </CloseIconContainer>
  )
}





