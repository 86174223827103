import { useEffect, useRef, useState, useCallback } from 'react'

const getScreenBreakpoint = () => {
  let size
  if (window.innerWidth < 900) {
    size = 'xs'
  } else if (window.innerWidth < 1200) {
    size = 'sm'
  } else if (window.innerWidth < 1600) {
    size = 'md'
  } else {
    size = 'lg'
  }

  return size
}

const getScreenOrientation = () => {
  let size = getScreenBreakpoint()

  let orientation = ''
  if (screen.orientation.type.match('portrait') || size !== 'xs') {
    orientation = 'portrait'
  } else if (screen.orientation.type.match('landscape') && size === 'xs') {
    orientation = 'landscape'
  }
  return orientation
}


const useResponsive = () => {
  const [renderResize, setRenderResize] = useState(false)

  const updateSize = useCallback(() => {
    setRenderResize(prev => !prev)
  }, [])

  useEffect(() => {
    window.addEventListener('resize', updateSize)
    return () => {
      window.removeEventListener('resize', updateSize)
    }
  }, [])

  return getScreenBreakpoint()
}

const useOrientation = () => {
  return getScreenOrientation()
}


const breakpoint = (size, styles) => props => {
  if (props.breakpoint === size) {
    return styles
  } else {
    return ''
  }
}

const orientation = (direction, styles) => props => {
  if (props.orientation === direction) {
    return styles
  } else {
    return ''
  }
}


export { useResponsive, breakpoint, useOrientation, orientation }