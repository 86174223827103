
import styled from 'styled-components/macro'

const SteamIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const SteamIcon = ({ size = 20, style, ...props }) => {
  return (
    <SteamIconContainer style={style} {...props}>
      <svg fill="currentColor" width={`${size}px`} height={`${size}px`} viewBox="0 0 31 31">
        <path d="M12.6626 22.3701C13.189 21.1055 12.5942 19.6563 11.3228 19.1299L9.30615 18.2959C10.0854 18.002 10.9673 17.9883 11.7944 18.3301C12.6284 18.6787 13.271 19.3281 13.6196 20.1553C13.9614 20.9893 13.9614 21.9053 13.6128 22.7324C12.895 24.4482 10.9194 25.2617 9.19678 24.5439C8.40381 24.2158 7.80225 23.6143 7.46045 22.8896L9.40869 23.6963C10.6802 24.2295 12.1362 23.6348 12.6626 22.3701ZM27.3462 0.1875H3.28369C1.47217 0.1875 0.00244141 1.65723 0.00244141 3.46875V14.4541L7.97314 17.7422C8.79346 17.1816 9.76416 16.915 10.7554 16.9697L14.5425 11.4873V11.4121C14.5425 8.11719 17.229 5.43066 20.5308 5.43066C23.8325 5.43066 26.519 8.11719 26.519 11.4121C26.519 14.7754 23.7231 17.4756 20.394 17.3936L14.9937 21.2422C15.103 23.874 13.0044 25.9453 10.5024 25.9453C8.32861 25.9453 6.50342 24.3936 6.09326 22.3428L0.00244141 19.8203V27.5312C0.00244141 29.3428 1.47217 30.8125 3.28369 30.8125H27.3462C29.1577 30.8125 30.6274 29.3428 30.6274 27.5312V3.46875C30.6274 1.65723 29.1577 0.1875 27.3462 0.1875ZM20.5308 15.3975C18.3296 15.3975 16.5386 13.6133 16.5386 11.4121C16.5386 9.21094 18.3296 7.42676 20.5308 7.42676C22.7319 7.42676 24.523 9.21777 24.523 11.4121C24.523 13.6064 22.7319 15.3975 20.5308 15.3975ZM20.5376 14.3994C22.1919 14.3994 23.5386 13.0596 23.5386 11.4053C23.5386 9.75098 22.1987 8.41113 20.5376 8.41113C18.8833 8.41113 17.5366 9.75098 17.5366 11.4053C17.5366 13.0596 18.8833 14.3994 20.5376 14.3994Z" />
      </svg>
    </SteamIconContainer>
  )
}
