import React, { useEffect, useState } from 'react';
// import { useFilePicker } from 'use-file-picker';
import { CloseIcon } from '../../icons/CloseIcon'
import { useOnMount } from '../../../hooks/useOnMount'
let useFilePicker = null

import './styles.scss';


const ImagePickerWrapper = props => {
  const [loaded, setLoaded] = useState(false)

  useOnMount(() => {
    if (!useFilePicker) {
      import('use-file-picker').then(res => {
        useFilePicker = res.useFilePicker
        setLoaded(true)
      })
    }
  })

  if (useFilePicker) {
    return (
      <ImagePicker {...props} useFilePicker={useFilePicker} />
    )
  }

  return null
}


const ImagePicker = props => {
  const { className, placeholder, image, onSelect, useFilePicker } = props
  const [name, setName] = React.useState(null);
  const [content, setContent] = React.useState(image);

  const [openFileSelector, { filesContent, plainFiles, loading, errors }] =
  useFilePicker({
    readAs: 'DataURL',
    accept: 'image/*',
    multiple: true,
    limitFilesConfig: { max: 1 },
    maxFileSize: 50 // in megabytes
  });


	useEffect(() => {
		if (plainFiles && plainFiles.length > 0) {
			onSelect(plainFiles[0]);
		}
	}, [plainFiles]);

  useEffect(() => {
		if (filesContent && filesContent.length > 0) {
			setName(filesContent[0].name);
			setContent(filesContent[0].content);
		}
	}, [filesContent]);

	function handleClear(event) {
		setName('');
		setContent('');

		onSelect(null);
	}

	return (
		<div className={`${className} image-picker ${content && 'active'}`}>
			<img className="thumbnail" src={content} alt="" />
      <div className="btn-container">
        <span className="name">{name || placeholder}</span>
        {content && <button className="btn-clear" onClick={handleClear}>
				  <CloseIcon size={12} />
			  </button>}
      </div>
			<button className="btn-select" onClick={() => openFileSelector()}>
				Select...
			</button>
		</div>
	);
}


export { ImagePickerWrapper as ImagePicker }