import { makeAutoObservable } from 'mobx';
import axios from 'axios';
import { WEBAPP_BACKEND_URL } from '../constants';
import { formatValue } from '../utils';

const PI = Math.PI

const sortGames = (a, b) => {
  if (a && b) {
    return a.ScaleName.toLowerCase().localeCompare(b.ScaleName.toLowerCase())
  } else return 0
}

const parseNerdamerSolution = value => {
  console.log(`raw solve value ${value}`)
  let solution1 = parseFloat(value.substring(1, value.length-1).split(',')[0])
  let solution2 = parseFloat(value.substring(1, value.length-1).split(',')[1])
  // console.log(`solution 1: ${solution1}, solution 2: ${solution2}`)
  if (solution1 < 0 && solution2 > 0) {
    return solution2
  } else {
    return solution1
  }
  // return parseFloat(value.substring(1, value.length-1).split(',')[0])
}

const solve = (formula, variable) => {
  const result = nerdamer.solve(formula, variable).text()
  return parseNerdamerSolution(result)
}

const fillFormula = (formula, fillValues) => {
  return nerdamer(formula, fillValues).text()
}

let nerdamer


export class CalculatorStore {
  calculatorLoading = false
  gameList = []
  fromGame = -1
  toGame = -1
  cm360 = null
  in360 = null
  inputCm360 = ''
  inputSens = 1
  outputSens = null
  inputDpi = 0
  lastDpi = 0
  fromFOV = 90
  inputFOV = 90
  fromGameList = []
  toGameList = []
  cm360OutputSens = null

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  setLastDpi = value => {
    this.lastDpi = value
  }

  setCalculatorLoading(loading) {
    this.calculatorLoading = loading
  }

  setFromGame = item => {
    this.fromGame = item
    this.updateGameList(this.fromGame, this.toGame)
  }

  setToGame = item => {
    this.toGame = item
    this.updateGameList(this.fromGame, this.toGame)
  }

  setInputDpi = value => {
    this.inputDpi = value
  }

  setInputCm360 = value => {
    this.inputCm360 = value
  }

  setFromFOV = value => {
    this.fromFOV = value
  }

  setInputFOV = value => {
    this.inputFOV = value
  }

  setCm360 = value => {
    this.cm360 = value
  }

  setIn360 = value => {
    this.in360 = value
  }

  setInputSens = value => {
    this.inputSens = value
  }

  setOutputSens = value => {
    this.outputSens = value
  }

  updateGameList(fromGame, toGame) {
    this.fromGameList = this.gameList.filter(item => {
      if (item.value == toGame) return false
      return true
    })

    this.toGameList = this.gameList.filter(item => {
      if (item.value == fromGame) return false
      return true
    })
  }

  async loadGameList() {
    await axios.get(`${WEBAPP_BACKEND_URL}/game-settings`).then(async result => {
      const gameOptions = result.data.SensitivityAndFov.map((item, index) => {
        if (!item.Sens?.IncrementFormula) return null
        if (item.ScaleName === 'cm/360' || item.ScaleName === 'in/360') return null
        return item
      }).sort(sortGames)
      .filter(item => item ? true : false).map((item, index) => {
        return {
          name: item.ScaleName,
          value: index,
          formula: item.Sens.IncrementFormula,
          Sens: item.Sens
        }
      })
      this.gameList = gameOptions
      this.updateGameList(this.fromGame, this.toGame)
      // lazy load nerdamer after we load the page
      let nerdamerLib = await import('nerdamer')
      nerdamer = nerdamerLib.default
      await import('nerdamer/Algebra')
      await import('nerdamer/Solve')
    })
  }


  // TODO: should cm360 converter be a nested route? (because of nav selection)
  updateCm360Calculation = () => {
    const game2 = this.gameList[this.toGame]
    if (!game2) return

    if (this.inputDpi > 0) {
      let cm360ToSensFormula = `CM360 = 2.54 * 360 / (Increment * DPI)`
      cm360ToSensFormula = nerdamer(cm360ToSensFormula, { CM360: parseFloat(this.inputCm360), DPI: this.inputDpi }).text()
      let gameIncrement = solve(cm360ToSensFormula, 'Increment')

      let game2SensFormula = fillFormula(`${gameIncrement} = ${game2.formula}`, { FOV: this.inputFOV })
      let game2Sens = solve(game2SensFormula, 'Sens')
      this.cm360OutputSens = formatValue(game2Sens, 5)
    }
  }

  hasValidOutput = (isGameConverter) => {
    if (isGameConverter) {
      if (this.outputSens > 0) return true
    } else {
      if (this.cm360OutputSens > 0) return true
    }
    console.log(`cm360OutputSens ${this.cm360OutputSens}`)
    return false
  }

  getSensOutput = (isGameConverter) => {
    if (isGameConverter) {
      return this.outputSens
    } else {
      return this.cm360OutputSens
    }
  }

  getSensOutputMessage = (isGameConverter) => {
    const game1 = this.gameList[this.fromGame]
    const game2 = this.gameList[this.toGame]

    if (isGameConverter) {
      if (!game2) {
        return `Select a game`
      } else if (!game1) {
        return `Select a game`
      } else if (this.outputSens < 0) {
        return this.outputSens
      }
    } else {
      // cm/360 converter mode
      if (!game2) {
        return `Select a game`
      } else if (this.inputDpi <= 0) {
        return `Input DPI`
      } else if (!this.inputCm360) {
        return 'Input cm/360'
      } else if (this.outputSens < 0) {
        return this.outputSens
      }
    }
  }

  getSensOutputMessageKey = (isGameConverter) => {
    const game1 = this.gameList[this.fromGame]
    const game2 = this.gameList[this.toGame]

    if (isGameConverter) {
      if (!game2) {
        return 'placeholders.select_a_game'
      } else if (!game1) {
        return 'placeholders.select_a_game'
      } else if (this.outputSens < 0) {
        return this.outputSens
      }
    } else {
      // cm/360 converter mode
      if (!game2) {
        return 'placeholders.select_a_game'
      } else if (this.inputDpi <= 0) {
        return 'placeholders.input_dpi'
      } else if (!this.inputCm360) {
        return 'placeholders.input_cm360'
      } else if (this.outputSens < 0) {
        return this.outputSens
      }
    }
  }


  convertSens = (sens) => {
    let game1 = this.gameList[this.fromGame]
    if (!game1) return
    const inch360Formula = `360 / (Increment * DPI)`
    const cm360Formula = `2.54 * 360 / (Increment * DPI)`
    const game1Increment = nerdamer(game1.formula, { Sens: parseFloat(sens), FOV: parseFloat(this.fromFOV), _pi: PI }).text()

    console.log(`calculating game1Increment from formula ${game1.formula} with Sens ${sens} = ${game1Increment}`)
    if (this.inputDpi > 0) {
      const inch360 = this.inputDpi > 0 ? nerdamer(inch360Formula, { Increment: game1Increment, DPI: parseFloat(this.inputDpi) }).text() : ''
      const cm360 = this.inputDpi > 0 ? nerdamer(cm360Formula, { Increment: game1Increment, DPI: parseFloat(this.inputDpi) }).text() : ''
      let inchVal = nerdamer(inch360).evaluate().text()
      let cmVal = nerdamer(cm360).evaluate().text()
      // console.log(`inch360 %s cm360 %s`, inch360, cm360)
      // console.log(`inch360 %s cm360 %s`, inchVal, cmVal)
      this.setIn360(formatValue(inchVal, 5))
      this.setCm360(formatValue(cmVal, 5))
    }

    const games = this.getGames()
    if (!games) return
    let game2 = games[1]

    console.log(`game1.formula ${game1.formula} game2.formula ${game2.formula}`)

    let formula = `${game2.formula} = ${game1Increment}`
    console.log(`computing game 2 sens with formula ${formula}, inputFOV: ${this.inputFOV}`)
    const converterFormula = nerdamer(`${game2.formula} = ${game1Increment}`, { FOV: parseFloat(this.inputFOV), _pi: PI }).toString()
    console.log(`solving for Sens ${converterFormula}`)
    const game2Sens = solve(converterFormula, 'Sens')
    console.log(`solved for game2 sens ${game2Sens}`)
    this.setOutputSens(formatValue(game2Sens, 5))
  }

  calculateDPI = () => {
    let game1 = this.gameList[this.fromGame]
    if (!game1) return
    const game1Increment = nerdamer(game1.formula, { Sens: this.inputSens, FOV: this.fromFOV }).text()
    let sensToDPIFormula = `CM360 = 2.54 * 360 / (Increment * DPI)`
    let filledSensToDPIFormula = nerdamer(sensToDPIFormula, { CM360: this.cm360, Increment: game1Increment })
    const dpi = solve(filledSensToDPIFormula, 'DPI')
    this.setInputDpi(dpi)

    const games = this.getGames()
    if (!games) return
    let game2 = games[1]

    const converter1Formula = nerdamer(`${game2.formula} = ${game1Increment}`, { FOV: this.inputFOV }).toString()
    const game2Sens = solve(converter1Formula, 'Sens')
    this.setOutputSens(formatValue(game2Sens, 5))
  }

  convertCm360 = (cm360) => {
    let game1 = this.gameList[this.fromGame]
    if (!game1) return

    this.cm360 = cm360
    this.in360 = cm360 / 2.54
    let cm360ToSensFormula = `CM360 = 2.54 * 360 / (Increment * DPI)`
    cm360ToSensFormula = nerdamer(cm360ToSensFormula, { CM360: parseFloat(cm360), DPI: this.inputDpi }).text()
    let gameIncrement = solve(cm360ToSensFormula, 'Increment')
    let game1SensFormula = fillFormula(`${gameIncrement} = ${game1.formula}`, { FOV: this.fromFOV })
    let game1Sens = solve(game1SensFormula, 'Sens')
    this.setInputSens(formatValue(game1Sens, 5))

    if (!games) return
    const game2 = games[1]
    let game2SensFormula = fillFormula(`${gameIncrement} = ${game2.formula}`, { FOV: this.inputFOV })
    let game2Sens = solve(game2SensFormula, 'Sens')
    this.setOutputSens(formatValue(game2Sens, 5))
  }

  convertIn360 = (in360) => {
    const games = this.getGames()
    if (!games) return
    const [game1, game2] = games

    this.in360 = in360
    this.cm360 = in360 * 2.54
    let in360ToSensFormula = `IN360 = 360 / (Increment * DPI)`
    in360ToSensFormula = nerdamer(in360ToSensFormula, { IN360: parseFloat(in360), DPI: this.inputDpi })
    const gameIncrement = solve(in360ToSensFormula, 'Increment')
    let game1SensFormula = fillFormula(`${gameIncrement} = ${game1.formula}`, { FOV: this.fromFOV })
    let game2SensFormula = fillFormula(`${gameIncrement} = ${game2.formula}`, { FOV: this.inputFOV })
    let game1Sens = solve(game1SensFormula, 'Sens')
    let game2Sens = solve(game2SensFormula, 'Sens')
    this.setInputSens(formatValue(game1Sens, 5))
    this.setOutputSens(formatValue(game2Sens, 5))
  }

  calculateSensFromDPI = () => {
    let game1 = this.gameList[this.fromGame]
    if (!game1) return
    let cm360ToSensFormula = `CM360 = 2.54 * 360 / (Increment * DPI)`
    cm360ToSensFormula = nerdamer(cm360ToSensFormula, { DPI: this.inputDpi, CM360: this.cm360 })
    let game1Increment = solve(cm360ToSensFormula, 'Increment')
    const converter2Formula = nerdamer(`${game1.formula} = ${game1Increment}`, { FOV: this.fromFOV }).toString()
    const game1Sens = solve(converter2Formula, 'Sens')
    this.setInputSens(formatValue(game1Sens, 5))

    const games = this.getGames()
    if (!games) return
    const game2 = games[1]
    const converter1Formula = nerdamer(`${game2.formula} = ${game1Increment}`, { FOV: this.inputFOV }).toString()
    const game2Sens = solve(converter1Formula, 'Sens')
    this.setOutputSens(formatValue(game2Sens, 5))
  }

  getGames = () => {
    const game1 = this.gameList[this.fromGame]
    const game2 = this.gameList[this.toGame]
    if (!game1 || !game2) return null
    return [game1, game2]
  }
}