import React, { useState } from 'react';

import { Scenario } from '../models';
import { useDebounce, usePaginatedRequest } from '../../utils';

const ScenarioContext = React.createContext({});

export function ScenarioProvider({ children }) {
	const [isScenarioLoading, setLoading] = useState(false);
	const [scenarioApiError, setApiError] = useState(null);
	const [allScenarios, setAllScenarioLists] = useState([]);
  const [scenarioDetails, setScenarioDetails] = useState(null)
	const [featuredScenario, setFeaturedScenario] = useState(null);
	const [recentScenarios, setRecentScenarios] = useState([]);
	const [allStaffPickScenarios, setAllStaffPickScenarios] = useState(null);
	const [maxMetaScenarios, setMaxScenarios] = useState([]);
	const [allMetaScenarios, setAllMetaScenarios] = useState([]);
	const [allLeaderboards, setAllLeaderboards] = useState([]);
	const [scenariosAsKeyValue, setScenariosAsKeyValue] = useState([]);
	const [creatorScenarioDetails, setCreatorScenarioDetails] = useState(null);

  const trendingScenarios = usePaginatedRequest(Scenario.getTrendingScenarios)

  const popularScenariosHome = usePaginatedRequest(Scenario.getPopularScenarios, 10)
  const popularScenarios = usePaginatedRequest(Scenario.getPopularScenarios, 20);
	const onTheRiseScenarios = usePaginatedRequest(Scenario.getOnTheRiseScenarios, 50);

	async function getAllScenarios() {
		setLoading(true);
		const response = await Scenario.getAllScenarios();
		setLoading(false);
		const { status, data, message } = response;
		if (status == 200) {
			setAllScenarioLists(data);
		} else {
			setApiError(message || 'Error: Getting All Scenarios');
		}
	}

  async function getScenarioDetails(id) {
    setLoading(true)
    const response = await Scenario.getScenarioDetails(id)
    setLoading(false)
    const { status, data } = response
    if (status == 200) {
      setScenarioDetails(data)
      return data
    } else {
      setApiError('Error: Getting Scenario Details');
    }
  }

	async function getFeaturedScenario() {
		setLoading(true);
		const response = await Scenario.getFeaturedScenario();
		setLoading(false);
		const { status, data, message } = response;
		if (status == 200) {
			setFeaturedScenario(data);
		} else {
			setApiError(message || 'Error: Getting Todays Scenario');
		}
	}

	const getRecentScenarios = useDebounce(async () => {
		setLoading(true);
		const response = await Scenario.getRecentScenarios();
		setLoading(false);
		const { status, data, message } = response;
		if (status == 200) {
			setRecentScenarios(data);
		} else {
			setApiError(message || 'Error: Getting Recent Scenarios');
		}
	}, 2000)

	const getOnTheRiseScenarios = useDebounce(async (page=0, max=20) => {
		setLoading(true);
		const response = await Scenario.getOnTheRiseScenarios(page, max);
		setLoading(false);
		const { status, data, message } = response;
		if (status == 200) {
			setOnTheRiseScenarios([...onTheRiseScenarios, ...data.data]);
		} else {
			setApiError(message || 'Error: Getting On The Rise Scenarios');
		}
	}, 2000);

	async function deleteAStaffPickScenarioById(id) {
		const response = await Scenario.deleteAStaffPickScenarioById(id);
		const { status, message } = response;
		if (status != 200) {
			setApiError(message || 'Error: Deleting Staff Pick Scenario By Id');
		}
	}

	async function updateFeaturedScenario({ leaderboardId, description, image }) {
		setLoading(true);
    console.log(`provider calling updateFeaturedScenario %O`, { leaderboardId, description, image })
		const response = await Scenario.updateFeaturedScenario({ leaderboardId, description, image });
		setLoading(false);
		const { status, message } = response;
		if (status != 200) {
			setApiError(message || 'Error: Updating Today Scenario');
		}
	}

	async function createStaffPickScenario({
		scenarioId,
		name,
		description,
		image
	}) {
		setLoading(true);
		const response = await Scenario.createStaffPickScenario({
			scenarioId,
			name,
			description,
			image
		});
		setLoading(false);
		const { status, message } = response;
		if (status != 200) {
			setApiError(message || 'Error: Creating Staff Scenario');
		}
	}

	async function resetScenario() {
		setApiError(null);
	}

	async function getPopularScenarios(page, max, scenarioName, duration) {
		const response = await Scenario.getPopularScenarios(page, max, scenarioName, duration);
		const { status, data } = response;
		if (status == 200) {
			return { data }
		}
	}

	async function getAllStaffPickScenarios() {
		setLoading(true);
		const response = await Scenario.getAllStaffPickScenarios();
		setLoading(false);
		const { status, data } = response;
		if (status == 200) {
			setAllStaffPickScenarios(data.data);
		}
	}

	async function deleteAStaffPickScenarioById(id) {
		const response = await Scenario.deleteAStaffPickScenarioById(id);
		const { status, data, message } = response;
		setApiError(status == 200 ? data : message);
	}

	async function getMetaScenariosWithMax(max) {
		const response = await Scenario.getMetaScenariosWithMax(max);
		const { status, data } = response;
		if (status == 200) {
			setMaxScenarios(data);
		}
	}

	async function getAllMetaScenarios(page, max, scenarioName) {
		const response = await Scenario.getAllMetaScenarios(page, max, scenarioName);
		const { status, data } = response;
		if (status == 200) {
			setAllMetaScenarios(data);
		}
	}

	async function getScenariosAsKeyValuePairs() {
		const response = await Scenario.getScenariosAsKeyValuePairs();
		const { status, data } = response;
		if (status == 200) {
			setScenariosAsKeyValue(data);
		}
	}

	async function getAllLeaderboardsWithoutLimit() {
		const response = await Scenario.getAllLeaderboards(true);
		const { status, data } = response;
		if (status == 200) {
			setAllLeaderboards(data);
		}
	}

	async function getCreatorScenarioDetails(scenarioName) {
		const response = await Scenario.getCreatorScenarioDetails(scenarioName);
		const { status, data } = response;
		if (status == 200) {
			setCreatorScenarioDetails(data);
      return data
		}
	}

	const memoedValue = React.useMemo(
		() => ({
			isScenarioLoading,
			scenarioApiError,
			allScenarios,
			featuredScenario,
			popularScenarios,
      popularScenariosHome,
      trendingScenarios,
			recentScenarios,
			onTheRiseScenarios,
			allStaffPickScenarios,
			maxMetaScenarios,
			allMetaScenarios,
			allLeaderboards,
			creatorScenarioDetails,
			scenariosAsKeyValue,
			getAllScenarios,
			// getOnTheRiseScenarios,
			getFeaturedScenario,
			getRecentScenarios,
			getPopularScenarios,
			updateFeaturedScenario,
			resetScenario,
			createStaffPickScenario,
			getAllStaffPickScenarios,
			deleteAStaffPickScenarioById,
			getMetaScenariosWithMax,
			getAllMetaScenarios,
			getScenariosAsKeyValuePairs,
			getAllLeaderboardsWithoutLimit,
			getCreatorScenarioDetails,
      scenarioDetails,
      getScenarioDetails
		}),
		[
			isScenarioLoading,
			scenarioApiError,
			popularScenarios,
      popularScenariosHome,
			allScenarios,
			featuredScenario,
			recentScenarios,
			onTheRiseScenarios,
			allStaffPickScenarios,
			maxMetaScenarios,
			allMetaScenarios,
			allLeaderboards,
			creatorScenarioDetails,
      scenarioDetails,
			scenariosAsKeyValue,
      trendingScenarios
		]
	);

	return (
		<ScenarioContext.Provider value={memoedValue}>
			{children}
		</ScenarioContext.Provider>
	);
}

export function useScenario() {
	return React.useContext(ScenarioContext);
}
