import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import ReactDOM from 'react-dom'
import styled from 'styled-components/macro'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { useRoot } from '../core/rootStore'

export const Modal = observer((props) => {
  let modalElement = document.getElementById('popup')

  const [bounds, setBounds] = useState()
  const root = useRoot()
  const { PopupContent, ModalContent, anchor } = root

  const updatePosition = () => {
    if (anchor) setBounds(anchor.getBoundingClientRect())
  }

  useEffect(() => {
    window.addEventListener('resize', updatePosition)
    window.addEventListener('focus', updatePosition)
    return () => {
      window.removeEventListener('resize', updatePosition)
      window.removeEventListener('focus', updatePosition)
    }
  })

  return (
    <>
    {ReactDOM.createPortal((
      <TransitionGroup className="fullscreen">
        <CSSTransition timeout={200} classNames="fade" key={PopupContent ? 'visible' : ''}>
          {() => {
            if (PopupContent) {
              return <PopupContent
                data={root.popupData}
                anchor={anchor}
                bounds={bounds}
              />
            } else if (ModalContent) {
              return <ModalContent
                data={root.modalData}
                anchor={anchor}
                bounds={bounds}
              />
            } else {
              return null
            }
            {/* return PopupContent ?
            <PopupContent
              data={root.popupData}
              anchor={anchor}
              bounds={bounds}
            /> : null */}
          }}
        </CSSTransition>
      </TransitionGroup>
    ), modalElement)}
    </>
  )

})